import React, { useState } from "react";
import { DatePicker, Space, Card } from "antd";
import { Radio, Input, Button } from "antd";

const { RangePicker } = DatePicker;

const FlitersAll = () => {
  const onChange = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
  };

  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const options = [
    {
      label: "Submission",
      value: "Submission",
    },
    {
      label: "Remittance",
      value: "Remittances",
    },
  ];
  const options1 = [
    {
      label: "Download",
      value: "Download",
    },
    {
      label: "Not to Download",
      value: "Not to Downlaod",
    },
    {
      label: "Download All",
      value: "Download All",
    },
  ];

  const [value1, setValue1] = useState("Apple");
  const [value2, setValue2] = useState("Apple");
  const [claimid, setClaimid] = useState("");
  const [transcationid, setTranscationid] = useState("");
  const [reverseid, setReverseid] = useState("");
  const [senderid, setSenderid] = useState("");

  console.log(claimid);
  console.log(transcationid);
  console.log(reverseid);
  console.log(senderid);

  const onChange1 = ({ target: { value } }) => {
    console.log("radio1 checked", value);
    setValue1(value);
  };

  const onChange2 = ({ target: { value } }) => {
    console.log("radio2 checked", value);
    setValue2(value);
  };

  return (
    <Space>
      <div className="row">
        <div className="container-fluid">
          <div className="card my-3">
            <div className="card-body p-2">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="card-header-title mb-lg-2">
                    <i
                      class="fa fa-search bg-primary p-1 text-white rounded-2"
                      aria-hidden="true"
                    ></i>{" "}
                    Search Criteria
                  </h4>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-2">
                    <label className="form-label">From-to Date </label>
                    <Space direction="vertical" size={14}>
                      <RangePicker
                        format="YYYY-MM-DD"
                        onChange={onChange}
                        onOk={onOk}
                      />
                    </Space>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="row border my-2 rounded-2">
                    <div className="col-md-5">
                      <div class="form-group mb-0 ">
                        <label class="form-label">Select Your Option</label>
                        <ul className="list-inline m-0">
                          <li className="list-inline-item">
                            <div class="form-check p-0">
                              <Radio.Group
                                options={options}
                                onChange={onChange1}
                                value={value1}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div class="form-group mb-0">
                        <label class="form-label">Download Select</label>
                        <ul className="list-inline m-0">
                          <li className="list-inline-item">
                            <div class="form-check p-0">
                              <Radio.Group
                                options={options1}
                                onChange={onChange2}
                                value={value2}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group mb-2">
                    <label className="form-label">Claim ID</label>
                    <Input
                      value={claimid}
                      onChange={(e) => {
                        setClaimid(e.target.value);
                      }}
                      placeholder="Claim ID"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group mb-2">
                    <label className="form-label">Transaction ID</label>
                    <Input
                      value={transcationid}
                      onChange={(e) => {
                        setTranscationid(e.target.value);
                      }}
                      placeholder="Transaction ID"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group mb-2">
                    <label className="form-label">Reserve ID</label>
                    <Input
                      value={reverseid}
                      onChange={(e) => {
                        setReverseid(e.target.value);
                      }}
                      placeholder="Reserve ID"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group mb-2">
                    <label className="form-label">Sender ID</label>
                    <Input
                      value={senderid}
                      onChange={(e) => {
                        setSenderid(e.target.value);
                      }}
                      placeholder="Sender ID"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="float-end mt-4">
                    <Button type="primary">Submit</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Space>
  );
};

export default FlitersAll;
