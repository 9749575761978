import React, { useState, useEffect } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import {
  Button,
  message,
  Steps,
  Upload,
  Select,
  Input,
  DatePicker,
  Form,
  InputNumber,
} from "antd";
import axios from "axios";
import API_URL from "../../tools/api";
import { ArrowRightOutlined, UploadOutlined } from "@ant-design/icons";
import Footer from "../../layout/footer";
import NumberFormat from "react-number-format";
import { Link, useHistory } from "react-router-dom";
import NEWSIDEBAR from "../../layout/newSidebar";

const RiyathiEligibilityCheck = () => {
  const [current, setCurrent] = useState(0);
  const [facilityName, setFacilityName] = useState();
  const [facilityAuthorityNameList, setFacilityAuthorityNameList] = useState();

  //Step One Starts here

  const [date, setDate] = useState();
  const [type, setType] = useState();
  const [typeRequest, setTypeRequest] = useState("NEW");
  console.log(typeRequest, "HEllo");
  const uniqueid = Date.now();
  console.log(uniqueid, "Date");
  console.log(type);

  const onChangeTime = (value, dateString) => {
    setDate(dateString);
  };
  console.log("Success Date", date);

  //Step One Ends here

  //Step Two Starts here
  const [onChangeSenderID, setOnChangeSenderID] = useState("");
  const [receiverID, setReceiverID] = useState("");
  const [payerID, setPayerID] = useState("");
  const [memberID, setMemberID] = useState("");
  const [dateDOB, setDateDOB] = useState("");
  const [gender, setGender] = useState("");
  const [name, setName] = useState();
  const [facilityLicence, setfacilityLicence] = useState();
  const [values, setvalues] = useState();
  const [showhide, setShowhide] = useState("");

  // console.log(values);
  // console.log(gender);
  // console.log(name);
  // console.log(onChangeSenderID);
  // console.log(receiverID);
  // console.log(payerID);
  // console.log(memberID);
  const onChangeWeight = (value) => {
    console.log("changed", value);
  };
  const handleshowhide = (value) => {
    const getuser = value;
    setShowhide(getuser);
  };
  const onChangeDOB = (value, dateString) => {
    setDateDOB(dateString);
  };
  console.log("Success Date", dateDOB);
  //Step Two seState ends here

  //Step Three Starts Here
  const [encounterType, setEncounterType] = useState("");
  console.log(encounterType);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const Data = async () => {
    try {
      const Data1 = await axios.post(API_URL + "/claimMgt/userFacility", {
        facilityIdList: JSON.parse(localStorage.getItem("facilities")),
      });
      setFacilityName(Data1.data);
      setFacilityAuthorityNameList(Data1.data);
      setfacilityLicence(Data1.data);
      console.log(Data1.data, "data1");
      console.log(facilityName, "Check");
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };

  useEffect(() => {
    Data();
    Date.now();
  }, []);
  let history = useHistory();
  const onFinish = (values) => {
    console.log("Success:", values);
    history.push("/selectfacility");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Starting Steps For Ant-Design
  const { Step } = Steps;
  const { Option } = Select;

  const steps = [
    {
      title: "Eligibility",
      content: (
        <>
          <div className="row">
            <fieldset className="form-group border mb-0 m-2 pb-4">
              <legend className="w-auto px-2">Eligibility</legend>
              <div className="row">
                <div className="col-md-3">
                  <Form.Item
                    name="Type"
                    label="Type"
                    className="mb-0 gender"
                    rules={[
                      { required: true, message: "Please Select Your Option " },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select"
                      onChange={(value) => {
                        setType(value);
                      }}
                    >
                      <Option value="Eligibility">Eligibility</Option>
                      <Option value="Cancel">Cancel</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-3">
                  <Form.Item label="ID" className="mb-0 gender">
                    <Input disabled value={uniqueid} />
                  </Form.Item>
                </div>
                <div className="col-md-3">
                  <Form.Item
                    name="RequestType"
                    label="RequestType"
                    className="mb-0 gender"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter the Request Type",
                      },
                    ]}
                  >
                    <Input
                      disabled
                      placeholder="New"
                      onChange={(e) => {
                        setOnChangeSenderID(e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col-md-3">
                  <Form.Item
                    name="Order Date"
                    label="Order Date"
                    className="mb-0 gender"
                    rules={[
                      {
                        required: true,
                        message: "Please Select the Date and Time",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                      showTime
                      onChange={onChangeTime}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </Form.Item>
                </div>
              </div>
            </fieldset>
          </div>
        </>
      ),
    },
    {
      title: "Patient Demographics",
      content: (
        <Form.Item>
          <fieldset className="form-group border p-2 pb-4">
            <legend className="w-auto px-2">Patient Demographics</legend>
            <div className="row">
              <div className="col-md-3">
                <Form.Item
                  name="Sender ID"
                  label="Sender ID"
                  className="mb-0 gender"
                  rules={[
                    { required: true, message: "Please Select Your Option " },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      setOnChangeSenderID(value);
                    }}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Sender ID"
                  >
                    {facilityLicence &&
                      facilityLicence.map((el, i) => (
                        <Option key={i} value={el.facilitylicence}>
                          {el.facilitylicence}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-3">
                <Form.Item
                  name="Receiver"
                  label="Receiver"
                  className="mb-0 gender"
                  rules={[
                    { required: true, message: "Please Select Your Option " },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      setReceiverID(value);
                    }}
                    placeholder="Receiver ID"
                  >
                    <Option value="0001">Receiver</Option>
                    <Option value="0002">Receiver</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-3">
                <Form.Item
                  name="Payer"
                  label="Payer"
                  className="mb-0 gender"
                  rules={[
                    { required: true, message: "Please Select Your Option " },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      setPayerID(value);
                    }}
                    placeholder="Payer ID"
                  >
                    <Option value="0001">Payer</Option>
                    <Option value="0002">Payer</Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="col-md-3">
                <Form.Item
                  name="Member ID"
                  label="Member ID"
                  className="mb-0 gender"
                  rules={[
                    { required: true, message: "Please Select Your Option " },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      setMemberID(value);
                    }}
                    placeholder="Member ID"
                  >
                    <Option value="1">001</Option>
                    <Option value="2">002</Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="col-md-3">
                <Form.Item
                  name="Weight"
                  label="Weight"
                  className="mb-0 gender"
                  rules={[
                    { required: true, message: "Please input your Weight" },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                    min={15}
                    max={150}
                    onChange={onChangeWeight}
                  />
                </Form.Item>
              </div>
              <div className="col-md-3">
                <Form.Item
                  name="Emirates ID"
                  label="Emirates ID"
                  className="mb-0 gender"
                  rules={[
                    { required: true, message: "Please input your Option" },
                  ]}
                >
                  <Select
                    onChange={(e) => handleshowhide(e)}
                    onSelect={(value) => setvalues(value)}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Emirates ID"
                  >
                    <Option>Select</Option>
                    <Option
                      value="000-0000-
0000000-0"
                    >
                      A citizen without a card
                    </Option>
                    <Option
                      value="111-1111-
1111111-1"
                    >
                      Expatriate resident without a card
                    </Option>
                    <Option
                      value="222-2222-
2222222-2"
                    >
                      Non-national, non-expat resident without a card
                    </Option>
                    <Option
                      value="999-9999-
9999999-9"
                    >
                      Unknown status, without a card
                    </Option>
                    <Option value="">Citizen</Option>
                  </Select>
                </Form.Item>
              </div>
              {showhide === "" && (
                <div className="col-md-3">
                  <Form.Item
                    name="Enter the Emirates No"
                    label="Enter the Emirates No"
                    className="mb-0 gender"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter the Emirates No!",
                      },
                    ]}
                  >
                    <NumberFormat
                      format="###-####-#######-#"
                      required
                      className="form-control"
                      onChange={(e) => {
                        setvalues(e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
              )}

              <div className="col-md-3">
                <Form.Item
                  name="Date of Birth"
                  label="Date of Birth"
                  className="mb-0 gender"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Name!",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={onChangeDOB}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-3">
                <Form.Item
                  name="gender"
                  label="Gender"
                  className="mb-0 gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select gender!",
                    },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      setGender(value);
                    }}
                    className="col-md-12"
                    placeholder="Select Gender"
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-3">
                <Form.Item
                  name="Name"
                  label="Name"
                  className="mb-0 gender"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Name!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                </Form.Item>
              </div>
              <p>{name}</p>
            </div>
          </fieldset>
        </Form.Item>
      ),
    },
    {
      title: "Encounter",
      content: (
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <div className="row">
            <fieldset className="form-group border mb-0 m-2 pb-4">
              <legend className="w-auto px-2">Encounter</legend>
              <div className="row">
                <div className="col-md-3">
                  <label className="col-form-label">Encounter Type</label>
                  <Form.Item
                    name="Encounter Type"
                    label="Encounter Type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter the Encounter Type",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setEncounterType(e.target.value);
                      }}
                      placeholder="Encounter Type"
                    />
                  </Form.Item>
                </div>
              </div>
            </fieldset>
          </div>
        </Form>
      ),
    },
  ];
  //Ending Steps For Ant-Design

  return (
    <>
      {/* <Sidebar /> */}
      <NEWSIDEBAR />
      <div className="home-section">
        <Header />
        <div class="p-3 mb-2 bg-primary text-white"></div>

        <h2 className="card-title m-15 text-center my-3">
          Zulekha Hospital Sharjah-Riaya
        </h2>
        <section className="all-pages container-fluid new-claim">
          <div className="col-lg-12">
            <div className="chart card border-light mb-4">
              <div className="card-header d-flex justify-content-between p-3">
                <h4>Zulekha Hospital Sharjah-Riaya</h4>
                <div className="float-end">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text bg-primary margin-right-2">
                      <a
                        data-bs-toggle="offcanvas"
                        href="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        className="text-white text-decoration-none"
                      >
                        <i
                          className="fa fa-plus-square-o fa-lg"
                          aria-hidden="true"
                        ></i>{" "}
                        Create New Eligibility Check
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div class="table-responsive">
                  <table class="table border mb-2">
                    <thead>
                      <tr>
                        <th>trans Type</th>
                        <th>sender Id</th>
                        <th>receiver Id</th>
                        <th>payer Id</th>
                        <th>type</th>
                        <th>id</th>
                        <th>request Type</th>
                        <th>member Id</th>
                        <th>patient Emirate Id</th>
                        <th>doo</th>
                        <th>weight</th>
                        <th>date Of Birth</th>
                        <th>request Reference Number</th>
                        <th>gender</th>
                        <th>authority</th>
                        <th>status</th>
                        <th>encounter Type</th>
                        <th>created by</th>
                        <th>created date time</th>
                        <th>updated by</th>
                        <th>updated date time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>trans Type</td>
                        <td>sender Id</td>
                        <td>receiver Id</td>
                        <td>payer Id</td>
                        <td>type</td>
                        <td>id</td>
                        <td>request Type</td>
                        <td>member Id</td>
                        <td>patient Emirate Id</td>
                        <td>doo</td>
                        <td>weight</td>
                        <td>date Of Birth</td>
                        <td>request Reference Number</td>
                        <td>gender</td>
                        <td>authority</td>
                        <td>status</td>
                        <td>encounter Type</td>
                        <td>created by</td>
                        <td>created date time</td>
                        <td>updated by</td>
                        <td>updated date time</td>
                        <td>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <Upload {...props}>
                                <Button
                                  className="border-0"
                                  title="Upload"
                                  icon={<UploadOutlined />}
                                ></Button>
                              </Upload>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="p-1">
                                <i
                                  class="fa  fa-download fa-lg text-warning"
                                  title="Get Eligibility"
                                ></i>
                              </a>
                            </li>

                            <li className="list-inline-item">
                              <a
                                href="#offcanvasRight"
                                className="p-1"
                                data-bs-toggle="offcanvas"
                                aria-controls="offcanvasRight"
                              >
                                <i
                                  class="fa fa-edit fa-lg ml text-info"
                                  title="Edit"
                                ></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#" className="p-1">
                                <i
                                  class="fa fa-trash-o fa-lg text-danger"
                                  title="Delete"
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="col-lg-12">
                    <div class="btn-group float-end">
                      <ul class="pagination pagination-sm">
                        <li class="page-item disabled">
                          <a class="page-link" href="#">
                            &laquo;
                          </a>
                        </li>
                        <li class="page-item active">
                          <a class="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            4
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            5
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            &raquo;
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="offcanvas offcanvas-end form-wizard"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">
                CREATE NEW ELIGIBILITY
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <>
                <Steps current={current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>

                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                  {current < steps.length - 1 && (
                    <Button
                      type="primary"
                      style={{
                        float: "right",
                        marginRight: "20px",
                      }}
                      onClick={() => next()}
                    >
                      Next <ArrowRightOutlined />
                    </Button>
                  )}

                  {current === steps.length - 1 && (
                    <Button
                      style={{
                        float: "right",
                        marginRight: "20px",
                      }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => message.success("Submitted SucessFully")}
                    >
                      Done
                    </Button>
                  )}
                  {current > 0 && (
                    <Button
                      style={{
                        float: "right",
                        marginRight: "10px",
                      }}
                      onClick={() => prev()}
                    >
                      Previous
                    </Button>
                  )}
                </div>
              </>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default RiyathiEligibilityCheck;
