import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import "antd/dist/antd.min.css";

import {
  Button,
  message,
  Space,
  Popover,
  Radio,
  Tooltip,
  DatePicker,
} from "antd";
import { FullscreenOutlined, FilterTwoTone } from "@ant-design/icons";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Sidebar from "../layout/sidebar";
import NumberFormat from "react-number-format";
import NEWSIDEBAR from "../layout/newSidebar";
import API_URL from "../tools/api";
import axios from "axios";
import { getoperationalInsuranceTraffic } from "./RejectionFunction/insuranceTrafficView";
import "react-multiple-select-dropdown-lite/dist/index.css";
import MultiSelect from "react-multiple-select-dropdown-lite";

let resizeFlag = true;

const OperationalOld = () => {
  const [style, setStyle] = useState("style");
  console.log("style", style);

  const changeStyle = () => {
    console.log("Success");
    if (resizeFlag === true) {
      setStyle("styleone");
      resizeFlag = false;
    } else {
      setStyle("style");
      resizeFlag = true;
    }
  };
  const [toggle, setToggle] = useState(true);
  const [toggleone, setToggleone] = useState(true);
  const [toggletwo, setToggletwo] = useState(true);
  const [togglethree, setTogglethree] = useState(true);
  const [togglefour, setTogglefour] = useState(true);
  const [togglefive, setTooglefive] = useState(true);
  //UseState for Charts
  const [submissionCycle, setSubmissionCycle] = useState("");
  const [submissionCycle1, setSubmissionCycle1] = useState("");
  const [operationalCptDrug, setoperationalCptDrug] = useState("");
  const [operationalCPTMixDentel, setOperationalCPTMixDentel] = useState("");
  const [operationalICD, setOperationalICD] = useState("");
  const [operationalClaimCount, setOperationalClaimCount] = useState("");
  const [operationalInsuranceTraffic, setOperationalInsuranceTraffic] =
    useState("");
  const [kpiData, setKpiData] = useState("");
  // Use State for Filter Section starts here // Tamil change ("") to ()
  const [facilitySelect, setFacilitySelect] = useState();
  const [value, setValue] = useState("S");
  const [selectedReceiver, setSelectedReceiver] = useState();
  const [selectedPayer, setSelectedPayer] = useState();
  //useState for DropDown
  const [faciltyGroup, setFacilityGroup] = useState([]);
  const [facilityGroupName, setFacilityGroupName] = useState("");
  const [payer, setPayer] = useState("");
  const [receiver, setReceiver] = useState([]);
  const [date, setDate] = useState("");

  //DropDown Data Change
  const [facilityGroupData, setFacilityGroupData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  console.log(startDate);
  console.log(endDate);
  // Use State for Filter Section Ends here

  //Filter Api starts Here
  const text = <span>Filter</span>;

  const companyName = [];
  const facilitylicence = [];

  //Date Picker

  const onOk = (value1) => {
    console.log("onOk: ", value1);
  };

  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const onChangeStartDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setStartDate(dateString);
  };

  const onChangeEndDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setEndDate(dateString);
  };
  //Date Type Radio Button
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const handleChangeFacility = (e) => {
    setFacilityGroupData(e.target.value);
  };

  console.log(facilityGroupData);

  //Multi Select Receiver Data
  var receiverchange = (e) => {
    let CompanyName = e.map((el) => el.value);
    this.setState([CompanyName]);
  };
  const receiverResult =
    receiver &&
    receiver.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  //console.log("result", receiverResult);
  const receiverList = (isActive) =>
    receiverResult &&
    receiverResult.map(
      (el) =>
        el.isActive == true && {
          value: el.Auth_No,
          label: el.Auth_No.concat("---").concat(el.CompanyName),
        }
    );
  let receiverSelectList = receiverList(true);
  console.log("ReceiverSelectList", selectedReceiver);

  const handleOnchangeReceiver = (val) => {
    setSelectedReceiver(val);
  };

  //console.log("Selected Receiver", selectedReceiver);
  //Multi Select Payer Data
  // var payerchange = (e) => {
  //   let CompanyName = e.map((el) => el.value);
  //   this.setState({ CompanyName });
  // };
  const payerResult =
    payer &&
    payer.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  // console.log("result", payerResult);
  const payerList = (isActive) =>
    payerResult &&
    payerResult.map(
      (el) =>
        el.isActive == true && {
          value: el.Auth_No,
          label: el.Auth_No.concat("---").concat(el.CompanyName),
        }
    );
  let payerSelectList = payerList(true);
  console.log("PayerSelectList", payerSelectList);

  const handleOnchangePayer = (val) => {
    setSelectedPayer(val);
  };

  // var facilitychange = (e) => {
  //   let CompanyName = e.map((el) => el.value);
  //   this.setState({ facilitylicence });
  // };
  const facilityResult =
    faciltyGroup &&
    faciltyGroup.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  // console.log("result", payerResult);
  const facilityList = (isActive) =>
    facilityResult &&
    facilityResult.map(
      (el) =>
        el.isActive == true && {
          value: el.facilitylicence,
          label: el.facilitylicence,
        }
    );
  let facilitySelectList = facilityList(true);
  //console.log("PayerSelectList", payerSelectList);

  const handleOnchangeFacility = (val) => {
    setFacilitySelect(val);
  };
  const FacilityGroups = async () => {
    try {
      const FacilityGroups = await axios.post(
        API_URL + "/filterData/dropDwonFacilityGroups",

        {
          user_id: localStorage.getItem("user_id"),
          //user_id: "1",
          dataType: value,
        }
      );
      console.log("data", FacilityGroups.data.UserFacilityGroups);
      setFacilityGroupName(FacilityGroups.data.UserFacilityGroups);
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };

  const FacilityDropDown = async () => {
    const menu2 = await axios.post(API_URL + "/filterData/dropDwonFacility", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
      facilityGroups: facilityGroupData,
    });

    console.log("Menu2", menu2.data.UserFacility);
    setFacilityGroup(menu2.data.UserFacility);
  };

  const Payer = async () => {
    try {
      const Payer = await axios.post(
        API_URL + "/filterData/dropDwonPayer",

        {
          payerId: "",
          dateType: value,
          healthauth: "meta_dhpo",
        }
      );
      // console.log("Payer", Payer.data.Payer);
      setPayer(Payer.data.Payer);
    } catch (e) {
      console.log(e);
      console.log("Payer Error");
    }
  };
  const Receiver = async () => {
    const Receiver = await axios.post(
      API_URL + "/filterData/dropDwonReceiver",
      {
        receiverId: "",
        dateType: value,
        healthauth: "",
      }
    );
    console.log("Receiver", Receiver);
    setReceiver(Receiver.data.Receiver);
  };

  //Filter Api Ends Here
  const ChartFilter = async () => {
    const OperationalClaimCycle = await axios.post(
      API_URL + "/charts/OperationalClaimCycle",
      {
        startDate: startDate + " " + "00:00",
        endDate: endDate + " " + "00:00",
        dateType: value,
        user_id: localStorage.getItem("user_id"),
        facilityGroups: facilityGroupData,
        facilityIdList: String(facilitySelect).split(","),
        receiverIdList: String(selectedReceiver).split(","),
        payerIdList: String(selectedPayer).split(","),
      }
    );
    console.log("runtime", OperationalClaimCycle.data.category_data);
    setSubmissionCycle(OperationalClaimCycle.data.Series_data);
    setSubmissionCycle1(OperationalClaimCycle.data.category_data);
    setDate(OperationalClaimCycle.data);
  };

  const ChartFilter1 = async () => {
    const OperationalCPTMixDurg = await axios.post(
      API_URL + "/charts/OperationalCPTMixDurg",
      {
        startDate: startDate + " " + "00:00",
        endDate: endDate + " " + "00:00",
        dateType: value,
        user_id: localStorage.getItem("user_id"),
        facilityGroups: facilityGroupData,
        facilityIdList: String(facilitySelect).split(","),
        receiverIdList: String(selectedReceiver).split(","),
        payerIdList: String(selectedPayer).split(","),
      }
    );
    console.log("OperationalCPTMixDurg", OperationalCPTMixDurg.data);
    setoperationalCptDrug(OperationalCPTMixDurg.data);
    setDate(OperationalCPTMixDurg.data);
  };
  const ChartFilter2 = async () => {
    const OperationalCPTMixDentel = await axios.post(
      API_URL + "/charts/OperationalCPTMixDentel",
      {
        startDate: startDate + " " + "00:00",
        endDate: endDate + " " + "00:00",
        dateType: value,
        user_id: localStorage.getItem("user_id"),
        facilityGroups: facilityGroupData,
        facilityIdList: String(facilitySelect).split(","),
        receiverIdList: String(selectedReceiver).split(","),
        payerIdList: String(selectedPayer).split(","),
      }
    );
    console.log("OperationalCPTMixDentel", OperationalCPTMixDentel.data);
    setOperationalCPTMixDentel(OperationalCPTMixDentel.data);
    setDate(OperationalCPTMixDentel.data);
  };

  const ChartFilter3 = async () => {
    const OperationalICD = await axios.post(
      API_URL + "/charts/OperationalICD",
      {
        startDate: startDate + " " + "00:00",
        endDate: endDate + " " + "00:00",
        dateType: value,
        user_id: localStorage.getItem("user_id"),
        facilityGroups: facilityGroupData,
        facilityIdList: String(facilitySelect).split(","),
        receiverIdList: String(selectedReceiver).split(","),
        payerIdList: String(selectedPayer).split(","),
      }
    );
    console.log("OperationalICD", OperationalICD.data);
    setOperationalICD(OperationalICD.data);
    setDate(OperationalICD.data);
  };

  const ChartFilter4 = async () => {
    const OperationalClaimCount = await axios.post(
      API_URL + "/charts/OperationalClaimCount",
      {
        startDate: startDate + " " + "00:00",
        endDate: endDate + " " + "00:00",
        dateType: value,
        user_id: localStorage.getItem("user_id"),
        facilityGroups: facilityGroupData,
        facilityIdList: String(facilitySelect).split(","),
        receiverIdList: String(selectedReceiver).split(","),
        payerIdList: String(selectedPayer).split(","),
      }
    );
    console.log("OperationalClaimCount", OperationalClaimCount.data);
    setOperationalClaimCount(OperationalClaimCount.data);
    setDate(OperationalClaimCount.data);
  };

  const ChartFilter5 = async () => {
    const OperationalInsuranceTraffic = await axios.post(
      API_URL + "/charts/OperationalInsuranceTraffic",
      {
        startDate: startDate + " " + "00:00",
        endDate: endDate + " " + "00:00",
        dateType: value,
        user_id: localStorage.getItem("user_id"),
        facilityGroups: facilityGroupData,
        facilityIdList: String(facilitySelect).split(","),
        receiverIdList: String(selectedReceiver).split(","),
        payerIdList: String(selectedPayer).split(","),
      }
    );
    console.log("OperationalClaimCount", OperationalInsuranceTraffic.data);
    setOperationalInsuranceTraffic(OperationalInsuranceTraffic.data);
    setDate(OperationalInsuranceTraffic.data);
  };

  const denial = getoperationalInsuranceTraffic(operationalInsuranceTraffic);
  console.log(denial, "codes123");

  const OperationalClaimCycle = async () => {
    const OperationalClaimCycle = await axios.post(
      API_URL + "/charts/OperationalClaimCycle",
      {
        user_id: localStorage.getItem("user_id"),
        dateType: "S",
      }
    );
    console.log("runtime", OperationalClaimCycle.data.category_data);
    setSubmissionCycle(OperationalClaimCycle.data.Series_data);
    setSubmissionCycle1(OperationalClaimCycle.data.category_data);
    setDate(OperationalClaimCycle.data);
  };

  const OperationalCPTMixDurg = async () => {
    const OperationalCPTMixDurg = await axios.post(
      API_URL + "/charts/OperationalCPTMixDurg",
      {
        user_id: localStorage.getItem("user_id"),
        dateType: "S",
      }
    );
    console.log("OperationalCPTMixDurg", OperationalCPTMixDurg.data);
    setoperationalCptDrug(OperationalCPTMixDurg.data);
  };
  const OperationalCPTMixDentel = async () => {
    const OperationalCPTMixDentel = await axios.post(
      API_URL + "/charts/OperationalCPTMixDentel",
      {
        user_id: localStorage.getItem("user_id"),
        dateType: "S",
      }
    );
    console.log("OperationalCPTMixDentel", OperationalCPTMixDentel.data);
    setOperationalCPTMixDentel(OperationalCPTMixDentel.data);
  };

  const OperationalICD = async () => {
    const OperationalICD = await axios.post(
      API_URL + "/charts/OperationalICD",
      {
        user_id: localStorage.getItem("user_id"),
        dateType: "S",
      }
    );
    console.log("OperationalICD", OperationalICD.data);
    setOperationalICD(OperationalICD.data);
  };

  const OperationalClaimCount = async () => {
    const OperationalClaimCount = await axios.post(
      API_URL + "/charts/OperationalClaimCount",
      {
        user_id: localStorage.getItem("user_id"),
        dateType: "S",
      }
    );
    console.log("OperationalClaimCount", OperationalClaimCount.data);
    setOperationalClaimCount(OperationalClaimCount.data);
  };

  const OperationalInsuranceTraffic = async () => {
    const OperationalInsuranceTraffic = await axios.post(
      API_URL + "/charts/OperationalInsuranceTraffic",
      {
        user_id: localStorage.getItem("user_id"),
        dateType: "S",
      }
    );
    console.log("OperationalClaimCount", OperationalInsuranceTraffic.data);
    setOperationalInsuranceTraffic(OperationalInsuranceTraffic.data);
  };

  const DashboardKPI = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setKpiData(data.data.DashboardKPI);
  };

  const DashboardKPIFilter = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "00:00",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });

    setKpiData(data.data.DashboardKPI);
  };

  useEffect(() => {
    OperationalClaimCycle();
    OperationalCPTMixDurg();
    OperationalCPTMixDentel();
    OperationalICD();
    OperationalClaimCount();
    OperationalInsuranceTraffic();
    DashboardKPI();
  }, []);

  useEffect(() => {
    FacilityGroups();
    // Payer();
    Receiver();
  }, []);
  // Filter Section Content Starts Here
  const content = (
    <Space direction="vertical">
      <div className="card-header p-0">
        <div className="row">
          <div className="col-md-6">
            <div class="form-group mb-8 ">
              <label class="form-label">Facility Group</label>

              <select
                value={facilityGroupData}
                multiple=""
                class="form-select form-select-sm"
                onChange={handleChangeFacility}
                onClick={FacilityDropDown}
              >
                <option></option>
                {facilityGroupName &&
                  facilityGroupName.map((el, i) => (
                    <option key={i} value={el.facilitygroupsname}>
                      {el.facilitygroupsname}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group mb-2 ">
              <label class="form-label">Facility</label>
              {/* <select
                value={facilitySelect}
                multiple=""
                class="form-select form-select-sm"
                onChange={(e) => setFacilitySelect(e.target.value)}
              >
                <option></option>
                {faciltyGroup &&
                  faciltyGroup.map((el, i) => (
                    <option key={i} value={el.facilitylicence}>
                      {el.facilitylicence}
                    </option>
                  ))}
              </select> */}
              <MultiSelect
                onChange={handleOnchangeFacility}
                options={facilitySelectList}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">Start Date</label>
              <Space direction="vertical">
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={startDate}
                  onChange={onChangeStartDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">End Date</label>
              <Space direction="vertical" size={3}>
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={endDate}
                  onChange={onChangeEndDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label col-lg-12">Date Type</label>

              <Radio.Group onChange={onChangeRadio} value={value}>
                <Radio value={"S"}>Service</Radio>
                <Radio value={"T"}>Transcation</Radio>
              </Radio.Group>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label">Select Payer</label>
              <MultiSelect
                onChange={handleOnchangePayer}
                options={payerSelectList}
                class="form-select form-select-sm"
              />
            </div>
          </div> */}

          <div className="">
            <div class="form-group mb-2">
              <label class="form-label">Select Receiver</label>
              <MultiSelect
                onChange={handleOnchangeReceiver}
                options={receiverSelectList}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label">Traffic Type</label>
              <select multiple="" class="form-select" id="exampleSelect2">
                <option>OP</option>
                <option>IP</option>
                <option>DC</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>
      <div className="col-md-12">
        <div className="float-end">
          <Button
            //onClick={data}
            onClick={() => {
              // data();
              hide();
            }}
            style={{
              marginRight: "10px",
            }}
            type="dashed"
            danger
          >
            Cancel Filter
          </Button>

          <Button
            //onClick={ChartFilter}
            onClick={() => {
              ChartFilter();
              ChartFilter1();
              ChartFilter2();
              ChartFilter3();
              ChartFilter4();
              ChartFilter5();
              DashboardKPIFilter();
              hide();
            }}
            type="primary"
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </Space>
  );

  // Filter Section Content Ends Here
  return (
    <>
      

      <div className="home-section">
        <Header />

        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
            <section className="dashboard">
              <div className="row  kpi  font-size-6">
                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-exclamation-circle"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          <NumberFormat
                            //value={archartData.DashboardKPI[0].Traffic}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Avg/Month "}
                          />
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Traffic}
                          displayType={"text"}
                          prefix={"# "}
                          thousandSeparator={true}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Revenue
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-usd"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Revenue}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Approved
                      <span className="float-end  text-success">
                        <span className="h6 fa fa-check-square-o"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Paid}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Processed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Rejected
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-ban"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Rejection}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Rejected}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Unsettled
                      <span className="float-end  text-warning">
                        <span className="h6 fa fa-balance-scale"></span>
                        <span className=" mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Unprocessed}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Unprocessed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic Type
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-warning"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          DC#:{kpiData && kpiData[0].Traffic_DAYCARE}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        OP# : {kpiData && kpiData[0].Traffic_OP}
                        <span className="padding-left-5">
                          IP# : {kpiData && kpiData[0].Traffic_IP}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Avg Sub TAT
                      <span className="float-end text-success">
                        <span className="h6 fa fa-line-chart"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].AVG_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"# "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Max Sub TAT
                      <span className="float-end  text-primary">
                        <span className="h6 fa fa-tachometer"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].MAX_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"#"}
                        />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* Facility Card Board */}
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card mb-3">
                    <div className="card-body p-0 font-size-6">
                      <div className="status lh-1">
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Start Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b> {date.Start_date}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>End Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b>{date.End_date}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Facility Group:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b>{facilityGroupData}</b>
                            {/* {facilityData &&
                        facilityData.FacilitiesGroup[1].facilitygroupsname} */}
                          </label>
                        </div>
                        <div className="form-check form-check-inline padding-left-5">
                          <a class="btn btn btn-sm btn-white margin-right-10">
                            <span class="blink text-warning">
                              <b>**Includes ReSubmission Variances</b>
                            </span>
                          </a>
                        </div>

                        <div class="form-check form-check-inline padding-left-5 float-end m-0 py-1 px-1">
                          {/* Filter Section Starts Here */}

                          <>
                            <div className="ant-filter">
                              <div
                                style={{
                                  clear: "both",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div className="col-lg-3">
                                  <Tooltip placement="bottom" title="Refresh">
                                    <a
                                      href="/dashboard"
                                      className="btn btn btn-sm btn-white border-0"
                                    >
                                      <i
                                        class="fa fa-refresh fa-spin"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </Tooltip>
                                  <Popover
                                    placement="bottom"
                                    overlayClassName="col-lg-4 shadow-sm"
                                    title={text}
                                    content={content}
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                  >
                                    <Tooltip placement="bottom" title={text}>
                                      <Button
                                        className="border-0"
                                        icon={<FilterTwoTone />}
                                      ></Button>
                                    </Tooltip>
                                  </Popover>
                                </div>
                              </div>
                            </div>
                          </>
                          {/* Filter Section Ends Here */}
                        </div>
                        <div class="form-check form-check-inline padding-left-5 float-end">
                          <label class="col-form-label margin-right-5">
                            <b>Facility:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b> {facilitySelect}</b>
                          </label>
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Facility Card board Ends */}
              <div className="row">
                {toggle && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          Claim Count View
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              {/* <FlitersAll /> */}
                            </li>
                            <Button
                              type="text"
                              icon={<FullscreenOutlined />}
                              onClick={() => {
                                setToggleone(!toggleone);
                                setToggletwo(!toggletwo);
                                setTogglethree(!togglethree);
                                setTogglefour(!togglefour);
                                setTooglefive(!togglefive);

                                changeStyle();
                              }}
                            ></Button>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            darkMode: "auto",

                            legend: {
                              bottom: "0%",
                            },

                            tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                type: "shadow",
                              },
                            },

                            xAxis: {
                              type: "category",
                              data: operationalClaimCount.AreaChart_X_Axis,
                            },
                            yAxis: {
                              type: "value",
                              axisLabel: {
                                formatter: "{value}",
                              },
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: true },
                                saveAsImage: { show: true },
                              },
                            },
                            series: [
                              {
                                name: operationalClaimCount.Name1,
                                data: operationalClaimCount.Data1,

                                type: "bar",
                                lineStyle: {
                                  color: "#000",
                                  type: "dashed",
                                },
                              },
                              {
                                name: operationalClaimCount.Name2,
                                data: operationalClaimCount.Data2,

                                type: "bar",
                                lineStyle: {
                                  color: "#000",
                                  type: "dashed",
                                },
                              },
                              {
                                name: operationalClaimCount.Name3,
                                data: operationalClaimCount.Data3,

                                type: "bar",
                                lineStyle: {
                                  color: "#000",
                                  type: "dashed",
                                },
                              },
                              {
                                name: operationalClaimCount.Name4,
                                data: operationalClaimCount.Data4,

                                type: "bar",
                                lineStyle: {
                                  color: "#000",
                                  type: "dashed",
                                },
                              },
                              {
                                name: operationalClaimCount.Name5,
                                data: operationalClaimCount.Data5,

                                type: "bar",
                                lineStyle: {
                                  color: "#000",
                                  type: "dashed",
                                },
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {toggleone && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          TOP 10 Insurance Traffic View
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              {/* <FlitersAll /> */}
                            </li>
                            <Button
                              type="text"
                              icon={<FullscreenOutlined />}
                              onClick={() => {
                                setToggle(!toggle);
                                setToggletwo(!toggletwo);
                                setTogglethree(!togglethree);
                                setTogglefour(!togglefour);
                                setTooglefive(!togglefive);

                                changeStyle();
                              }}
                            ></Button>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            darkMode: "auto",

                            tooltip: {
                              trigger: "item",
                              formatter: "{a} <br/>{b}: {c} ({d}%)",
                            },
                            legend: {
                              bottom: "0%",
                            },
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "20%",
                              containLabel: true,
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: true },
                                saveAsImage: { show: true },
                              },
                            },
                            xAxis: {
                              type: "value",
                            },
                            yAxis: {
                              type: "category",
                              data: denial.categories,
                            },
                            series: denial.series,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {toggletwo && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          Submission Cycle
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item"></li>
                            <Button
                              type="text"
                              icon={<FullscreenOutlined />}
                              onClick={() => {
                                setToggle(!toggle);
                                setToggleone(!toggleone);
                                setTogglethree(!togglethree);
                                setTogglefour(!togglefour);
                                setTooglefive(!togglefive);
                                changeStyle();
                              }}
                            ></Button>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            angleAxis: {},
                            darkMode: "auto",
                            tooltip: {
                              trigger: "axis",
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: true },
                                saveAsImage: { show: true },
                              },
                            },
                            radiusAxis: {
                              type: "category",
                              show: true,
                              interval: 0,
                              data: submissionCycle1,
                              nameTextStyle: {
                                align: "center",
                              },
                              nameRotate: 1,
                              nameLocation: "start",
                            },
                            polar: {},
                            type: "bar",
                            series: submissionCycle,
                            legend: {
                              show: true,
                              bottom: -5,
                              data: ["Revenue", "Paid", "Denied", "Unsettled"],
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {togglethree && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          TOP 10 Case Mix View ICD
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              {/* <FlitersAll /> */}
                            </li>
                            <Button
                              type="text"
                              icon={<FullscreenOutlined />}
                              onClick={() => {
                                setToggle(!toggle);
                                setToggletwo(!toggletwo);
                                setToggleone(!toggleone);
                                setTogglefour(!togglefour);
                                setTooglefive(!togglefive);

                                changeStyle();
                              }}
                            ></Button>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            title: {
                              text: "",
                            },
                            tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                type: "shadow",
                              },
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar"],
                                },
                                restore: { show: true },
                                saveAsImage: { show: true },
                              },
                            },
                            legend: {},
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            },
                            xAxis: {
                              type: "value",
                              //boundaryGap: [0, 0.01],
                            },
                            yAxis: {
                              type: "category",
                              axisLabel: {
                                fontSize: 10,
                              },
                              data: operationalICD.IDCDesc,
                            },
                            series: [
                              {
                                name: "",
                                type: "bar",
                                barWidth: "40%",
                                data: operationalICD.IDCRevenue,
                                itemStyle: {
                                  color: "#ff7070",
                                },
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {togglefour && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          TOP 10 Case Mix View CPT Dental
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              {/* <FlitersAll /> */}
                            </li>
                            <Button
                              type="text"
                              icon={<FullscreenOutlined />}
                              onClick={() => {
                                setToggle(!toggle);
                                setToggleone(!toggleone);
                                setToggletwo(!toggletwo);
                                setTogglethree(!togglethree);
                                setTooglefive(!togglefive);

                                changeStyle();
                              }}
                            ></Button>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            title: {
                              text: "",
                            },
                            tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                type: "shadow",
                              },
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar"],
                                },
                                restore: { show: true },
                                saveAsImage: { show: true },
                              },
                            },
                            legend: {},
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            },
                            xAxis: {
                              type: "value",
                              //boundaryGap: [0, 0.01],
                            },
                            yAxis: {
                              type: "category",
                              axisLabel: {
                                fontSize: 10,
                              },
                              data: operationalCPTMixDentel.CPTMixDentalDesc,
                            },
                            series: [
                              {
                                name: "",
                                type: "bar",
                                barWidth: "40%",
                                data: operationalCPTMixDentel.CPTMixDentalRevenue,
                                itemStyle: {
                                  color: "#feae34",
                                },
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {togglefive && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          TOP 10 Case Mix View CPT - DURGS Medical
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              {/* <FlitersAll /> */}
                            </li>
                            <Button
                              type="text"
                              icon={<FullscreenOutlined />}
                              onClick={() => {
                                setToggle(!toggle);
                                setToggleone(!toggleone);
                                setToggletwo(!toggletwo);
                                setTogglethree(!togglethree);
                                setTogglefour(!togglefour);

                                changeStyle();
                              }}
                            ></Button>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            title: {
                              text: "",
                            },
                            tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                type: "shadow",
                              },
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar"],
                                },
                                restore: { show: true },
                                saveAsImage: { show: true },
                              },
                            },
                            legend: {},
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            },
                            xAxis: {
                              type: "value",
                              //boundaryGap: [0, 0.01],
                            },
                            yAxis: {
                              type: "category",
                              axisLabel: {
                                fontSize: 10,
                              },
                              data: operationalCptDrug.CPTMixDrugDesc,
                            },
                            series: [
                              {
                                name: "",
                                type: "bar",
                                barWidth: "40%",
                                data: operationalCptDrug.CPTMixDrugRevenue,

                                itemStyle: {
                                  color: "#9fe080",
                                },
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default OperationalOld;
