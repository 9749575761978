import React from "react";
const Footer = () => {
  return (
    <footer class="footer text-center footer mt-auto py-1">
      <div class="container-fluid">
        <div class="copyright">
          <a href="#" class="text-decoration-none text-white font-size-6">
            Powered By Traks Solutions 
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
