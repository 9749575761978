import React from "react";
//import React, { Suspense } from "react";
import "./App.css";
import NextNProgress from "nextjs-progressbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SelectFacility from "./Pages/selectFacilities";
import SelectFacilitiesSubmission from "./Pages/selectFacilitiesSubmission";
import RayathiNewClaim from "./Pages/tmb";
import Primammb from "./Pages/primammb";
import MainDashboard from "./Pages/MainDashboard";
import AuthorityApiMaster from "./MetaData/AuthorityApiMaster";
import AuthorityDatamaster from "./MetaData/AuthorityDataMaster";
import AuthorityMaster from "./MetaData/AuthorityMaster";
import BooleanTable from "./MetaData/Boolean";
import FacilityActiveTypeMaster from "./MetaData/FacilityActiveTypeMaster";
import FacilityCategory from "./MetaData/FacilityCategoryMaster";
import FacilityCountryMaster from "./MetaData/FacilityCountry";
import FacilityCurrency from "./MetaData/FacilityCurrency";
import FacilityMasterMaster from "./MetaData/FacilityMaster";
import FacilityRegionRegion from "./MetaData/FacilityRegion";
import FacilityTypeMasterMaster from "./MetaData/FacilityTypeMaster";
import RoleMaster from "./MetaData/RoleMaster";
import ServiceMasterMaster from "./MetaData/ServiceMaster";
import FinancialOld from "./Bussiness Insights/Finanical";
import OperationalOld from "./Bussiness Insights/operational";
import RejectionsOld from "./Bussiness Insights/Rejections";
import NewLogin from "./auth/login";
import RiyathiEligibilityCheck from "./Pages/riyathi/eligibilityCheck";
import RiyathiNewClaim from "./Pages/riyathi/newClaim";
import Reports from "./Reports";
import FacilityCurrencyDownloader from "./Pages/riyathi/downloader";
import FacilityRevenue from "./Reports/Revenue/facilityRevenue";
import PrivateRoute from "./layout/privateRouter";
import ReportClinicianRevenue from "./Reports/Revenue/reportClinicianRevenue";
import ReportDepartmentRevenue from "./Reports/Revenue/reportDepartmentRevenue";
import ReportClinicianRevenueAvg from "./Reports/Revenue/reportClinicianRevenueAvg";
import DepartmentWiseClaimAvg from "./Reports/Revenue/reportDepartmentClaimAvg";
import ReportClinicianRevenueICD from "./Reports/Revenue/reportClinicianRevenueICD";
import ReportCPTRevenue from "./Reports/Revenue/reportCPTRevenue";
import ReportGrossRevenue from "./Reports/Revenue/reportGrossRevenue";
import ReportNetRevenue from "./Reports/Revenue/reportNetRevenue";
import FullClaimActivityHistory from "./Reports/Submission/reportFullClaimActivityHistory";
import CurrentCliamStatus from "./Reports/Revenue/reportCurrentClaimStatus";
import RejectionByInsurance from "./Reports/InsuranceReview/reportRejectionByInsurance";
import ReportDrwiseInsurance from "./Reports/InsuranceReview/reportClinicianWiseInurance";
import ReportRejectionMnec from "./Reports/InsuranceReview/reportMnec";
import ReportRejectionPrec from "./Reports/InsuranceReview/reportPrec";
import ReportSubmissionClinicianTAT from "./Reports/Submission/reportSubmissionClinilianTAT";
import ReportSubmissionDue from "./Reports/Submission/reportsubmissionDue";
import ReportFacilitySubmissionTAT from "./Reports/Submission/reportFacilitySubmissionTAT";
import ReportDepartmentwiseSubmission from "./Reports/Submission/reportDepartmentwiseSubmission";
import ReportFacilitySubmission from "./Reports/Submission/reportFacilitySubmission";
import ReportFacilityPaymentSummary from "./Reports/Payment/reportFacilityPaymentSummary";
import ReportReceiverPaymentSummary from "./Reports/Payment/reportReceiverPaymentSummary";
import ReportPayerPaymentSummary from "./Reports/Payment/reportPayerPaymentSummary";
import ReportClinicianPaymentSummary from "./Reports/Payment/reportClinicianPaymentSummary";
import ReportDepartmentPayment from "./Reports/Payment/reportDepartmentPayment";
import ResubmissionClaimCycle from "./Reports/Resubmission/reSubmissionClaimCycle";
import FavClinicianRevenue from "./Reports/Revenue/favClinicianRevenue";
import Observation from "./Pages/observation";
import ReportRAActivityLevel from "./Reports/Payment/ReportRAActivityLevel";
import ReportPaymentReferenceSummary from "./Reports/Payment/reportPaymentReferenceSummary";
import ReportReconciliationSummary from "./Reports/Payment/ReportReconciliationSummary";
import ReportClaimForReSubmmision from "./Reports/Resubmission/claimForResubmission";
import ClaimForReSubmissionApproved from "./Reports/Resubmission/ClaimForReSubmissionApproved";
import ClaimForReSubmissionApproval from "./Reports/Resubmission/ClaimForReSubmissionApproval";
import ReportFacilityAging from "./Reports/aging/FacilityAging";
import ReportClinicianAging from "./Reports/aging/ClinicianAging";
import ReportDeparmentAging from "./Reports/aging/DepartmentAging";
import ReportTPAAging from "./Reports/aging/TPAAging";
import ReportRejectionAging from "./Reports/aging/RejectionAging";
import ReportRejectionFinalDetails from "./Reports/Rejection/FinalRejectionDetails";
import ReportRejectionInitialDetails from "./Reports/Rejection/InitialRejectionDetails";
import ReportRejectionClinicianDetails from "./Reports/Rejection/ClinicianRejectionAnalysis";
import ReportRejectionSummary from "./Reports/Rejection/RejectionSummary";
import ReportRejectionDepartmentDetails from "./Reports/Rejection/DepartmentRejectionSummary";
import ReportRejectionICDDetails from "./Reports/Rejection/ICDRejectionSummary";
import ReportRejectionCPTDetails from "./Reports/Rejection/CPTRejectionSummary";
import ReportClaimForReconciliation from "./Reports/Rejection/ClaimsForFinalReconciliation";
import Claim from "./Reports/Remittance/claim";
import Activity from "./Reports/Remittance/activity";
import Radate from "./Reports/Remittance/radate";
import Submissiondate from "./Reports/Remittance/submissiondate";
import Encounterdate from "./Reports/Remittance/encounterdate";
import Settlement from "./Reports/Remittance/remittanceSettlement";
import Receiver from "./Reports/Remittance/remittanceReceiver";
import Payer from "./Reports/Remittance/remittancePayer";
import Duplicate from "./Reports/Remittance/DuplicateRemittance";
import ReportClaimWiseReport from "./Reports/Revenue/reportClaimWiseReport";
import ReportActivityWiseReport from "./Reports/Revenue/reportActivityWise"; 
import ReportPaymentReferenceTracker from "./Reports/Payment/reportPaymentReferenceTracker"; 
// const OperationalOld = React.lazy(() =>
//   import("./Bussiness Insights/operational")
// );
/* <Route exact path='/operational'>
 <Suspense fallback={<div>Loading...</div>}>
  <OperationalOld />
 </Suspense>
</Route>; */


function App() {
  return (
    <>
      <NextNProgress />
      <Router>
        <Switch>
           <Route exact path="/login">
            <NewLogin />
          </Route> 
          {<Route exact path="/">
            <NewLogin />
          </Route>
        }
          {/* **********************Normal Routes Starts Here************************ */}
        
        {/*} <Route exact path="/dhpo">
            <RiyathiNewClaim />
          </Route>
          <Route exact path="/tmb">
            <RayathiNewClaim />
          </Route>
          <Route exact path="/selectfacility">
            {" "}
            <SelectFacility />{" "}
          </Route>
          <Route exact path="/riaya">
            <MainDashboard />
          </Route>
          <Route exact path="/authorityapi">
            <AuthorityApiMaster />
          </Route>
          <Route exact path="/selectFacilitiesSubmission">
            <SelectFacilitiesSubmission />
          </Route>
          <Route exact path="/authoritydata">
            <AuthorityDatamaster />
          </Route>
          <Route exact path="/authoritymaster">
            <AuthorityMaster />
          </Route>
          <Route exact path="/booleantable">
            <BooleanTable />
          </Route>
          <Route exact path="/facilityactivemaster">
            <FacilityActiveTypeMaster />
          </Route>
          <Route exact path="/facilitycategorymaster">
            <FacilityCategory />
          </Route>
          <Route exact path="/facilitycountrymaster">
            <FacilityCountryMaster />
          </Route>
          <Route exact path="/facilitycurrency">
            <FacilityCurrency />
          </Route>
          <Route exact path="/facilitymastermaster">
            <FacilityMasterMaster />
          </Route>
          <Route exact path="/facilityregionregion">
            <FacilityRegionRegion />
          </Route>
          <Route exact path="/facilitytypemastermaster">
            <FacilityTypeMasterMaster />
          </Route>
          <Route exact path="/rolemaster">
            <RoleMaster />
          </Route>
          <Route exact path="/servicemastermaster">
            <ServiceMasterMaster />
          </Route>
          <Route exact path="/financial">
            <FinancialOld />
          </Route>
          <Route exact path="/operational">
            <OperationalOld />
          </Route>
          <Route exact path="/rejections">
            <RejectionsOld />
          </Route>
          <Route exact path="/neweligibility">
            <RiyathiEligibilityCheck />
          </Route>
          <Route exact path="/Riayathi">
            <RiyathiNewClaim />
          </Route>
          <Route exact path="/reports">
            <Reports />
          </Route>
          <Route exact path="/ffc">
            <FacilityCurrencyDownloader />
          </Route>
          <Route exact path="/reports/faciityrevenue">
            <FacilityRevenue />
          </Route>
          <Route exact path="/reports/reportClaimWiseReport">
            <ReportClaimWiseReport />
          </Route>
          <Route exact path="/reports/reportActivityWiseReport">
            <ReportActivityWiseReport />
          </Route>
          <Route exact path="/reports/reportclinicianrevenue">
            <ReportClinicianRevenue />
          </Route>
          <Route exact path="/reports/rejectionbyinsurance">
            <RejectionByInsurance />
          </Route>
          <Route exact path="/reports/currentclaimstatus">
            <CurrentCliamStatus />
          </Route>
          <Route exact path="/reports/reportfullclaimactivityhistory">
            <FullClaimActivityHistory />
          </Route>
          <Route exact path="/reports/reportdrwiseinsurance">
            <ReportDrwiseInsurance />
          </Route>
          <Route exact path="/reports/reportrejectionmnec">
            <ReportRejectionMnec />
          </Route>
          <Route exact path="/reports/reportrejectionprec">
            <ReportRejectionPrec />
          </Route>
         
          <Route exact path="/reports/reportdepartmentrevenue">
            <ReportDepartmentRevenue />
          </Route>
          <Route exact path="/reports/reportclinicianrevenueavg">
            <ReportClinicianRevenueAvg />
          </Route>
          <Route exact path="/reports/reportdepartmentclaimavg">
            <DepartmentWiseClaimAvg />
          </Route>
          <Route exact path="/reports/reportclinicianrevenueICD">
            <ReportClinicianRevenueICD />
          </Route>
          <Route exact path="/reports/reportcptrevenue">
            <ReportCPTRevenue />
          </Route>
          <Route exact path="/reports/reportgrossrevenue">
            <ReportGrossRevenue />
          </Route>
          <Route exact path="/reports/reportnetrevenue">
            <ReportNetRevenue />
          </Route>
          <Route exact path="/reports/reportsubmissionclinicianTAT">
            <ReportSubmissionClinicianTAT />
          </Route>
          <Route exact path="/reports/reportsubmissiondue">
            <ReportSubmissionDue />
          </Route>
          <Route exact path="/reports/reportfacilitysubmissiontat">
            <ReportFacilitySubmissionTAT />
          </Route>
          <Route exact path="/reports/reportdepartmentwisesubmission">
            <ReportDepartmentwiseSubmission />
          </Route>
          <Route exact path="/reports/reportfacilitysubmission">
            <ReportFacilitySubmission />
          </Route>
          <Route exact path="/reports/reportfacilitypaymentsummary">
            <ReportFacilityPaymentSummary />
          </Route>
          <Route exact path="/reports/reportreceiverpaymentsummary">
            <ReportReceiverPaymentSummary />
          </Route>
          <Route exact path="/reports/reportpayerpaymentsummary">
            <ReportPayerPaymentSummary />
          </Route>
          <Route exact path="/reports/reportclinicianpaymentsummary">
            <ReportClinicianPaymentSummary />
          </Route>
          <Route exact path="/reports/reportdepartmentpaymentsummary">
            <ReportDepartmentPayment />
          </Route>
          <Route exact path="/reports/resubmissionclaimcycle">
            <ResubmissionClaimCycle />
          </Route>
          <Route exact path="/fav/reportclinicianrevenue">
            <FavClinicianRevenue />
          </Route>
          <Route exact path="/observation">
            <Observation />
          </Route>
          <Route exact path="/reports/ReportRAActivityLevel">
            <ReportRAActivityLevel />
          </Route>
          <Route exact path="/reports/ReportPaymentReferenceSummary">
            <ReportPaymentReferenceSummary />
          </Route>
          <Route exact path="/reports/ReportReconciliationSummary">
            <ReportReconciliationSummary />
          </Route>
          <Route exact path="/reports/ReportClaimForReSubmmision">
            <ReportClaimForReSubmmision />
          </Route>
          <Route exact path="/reports/ReportClaimForReSubmissionApproved">
            <ClaimForReSubmissionApproved />
          </Route>
          <Route exact path="/reports/ClaimForReSubmissionApproval">
            <ClaimForReSubmissionApproval />
          </Route>
          <Route exact path="/reports/ReportFacilityAging">
            <ReportFacilityAging />
          </Route>
          <Route exact path="/reports/ReportClinicianAging">
            <ReportClinicianAging />
          </Route>
          <Route exact path="/reports/ReportDeparmentAging">
            <ReportDeparmentAging />
          </Route>
          <Route exact path="/reports/ReportTPAAging">
            <ReportTPAAging />
          </Route>
          <Route exact path="/reports/ReportRejectionAging">
            <ReportRejectionAging />
          </Route>
          <Route exact path="/reports/ReportRejectionFinalDetails">
            <ReportRejectionFinalDetails />
          </Route>
          <Route exact path="/reports/ReportRejectionInitialDetails">
            <ReportRejectionInitialDetails />
          </Route>
          <Route exact path="/reports/ReportRejectionClinicianDetails">
            <ReportRejectionClinicianDetails />
          </Route>
          <Route exact path="/reports/ReportRejectionSummary">
            <ReportRejectionSummary />
          </Route>
          <Route exact path="/reports/ReportRejectionDepartmentDetails">
            <ReportRejectionDepartmentDetails />
          </Route>
          <Route exact path="/reports/ReportRejectionICDDetails">
            <ReportRejectionICDDetails />
          </Route>
          <Route exact path="/reports/ReportRejectionCPTDetails">
            <ReportRejectionCPTDetails />
          </Route>
          <Route exact path="/reports/ReportClaimForReconciliation">
            <ReportClaimForReconciliation />
          </Route>
          <Route exact path="/reports/RemittanceReportClaim">
            <Claim />
          </Route>
          <Route exact path="/reports/RemittanceReportActivity">
            <Activity />
          </Route>
          <Route exact path="/reports/RemittanceReportradate">
            <Radate />
          </Route>
          <Route exact path="/reports/RemittanceReportSubmissiondate">
            <Submissiondate />
          </Route>
          <Route exact path="/reports/RemittanceReportEncounterdate">
            <Encounterdate />
          </Route>
          <Route exact path="/reports/RemittanceReportsettlement">
            <Settlement />
          </Route>
          <Route exact path="/reports/RemittanceReportReceiver">
            <Receiver />
          </Route>
          <Route exact path="/reports/RemittanceReportPayer">
            <Payer />
          </Route>
          <Route exact path="/reports/RemittanceReportDuplicate">
            <Duplicate />
          </Route>
          */}
       
          
          {/* **********************Normal Routes Ends Here************************ */}
          {/* **********************Private Routes Starts Here************************ */}
          
          <Route
      exact
      path='/dhpo'
      component={() => <PrivateRoute component={RiyathiNewClaim} />}
     />
	{/*<Route
      exact
      path='/dhpo1'
      component={() => <PrivateRoute component={PrimaCare} />}
     />
        */}
	 <Route
      exact
      path='/selectfacility'
      component={() => <PrivateRoute component={SelectFacility} />}
     />
       <Route
      exact
      path='/riaya'
      component={() => <PrivateRoute component={MainDashboard} />}
     />   
	 <Route
      exact
      path='/authorityapi'
      component={() => <PrivateRoute component={AuthorityApiMaster} />}
     />
     <Route
      exact
      path='/authoritydata'
      component={() => <PrivateRoute component={AuthorityDatamaster} />}
     />   
	 <Route
      exact
      path='/authoritymaster'
      component={() => <PrivateRoute component={AuthorityMaster} />}
     />
	 <Route
      exact
      path='/booleantable'
      component={() => <PrivateRoute component={BooleanTable} />}
     />
     <Route
      exact
      path='/facilityactivemaster'
      component={() => <PrivateRoute component={FacilityActiveTypeMaster} />}
     />
      <Route
      exact
      path='/facilitycategorymaster'
      component={() => <PrivateRoute component={FacilityCategory} />}
     />
      <Route
      exact
      path='/facilitycountrymaster'
      component={() => <PrivateRoute component={FacilityCountryMaster} />}
     />    
      <Route
      exact
      path='/facilitycurrency'
      component={() => <PrivateRoute component={FacilityCurrency} />}
     />    
      <Route
      exact
      path='/facilitymastermaster'
      component={() => <PrivateRoute component={FacilityMasterMaster} />}
     />   
      <Route
      exact
      path='/facilityregionregion'
      component={() => <PrivateRoute component={FacilityRegionRegion} />}
     />
     <Route
      exact
      path='/facilitytypemastermaster'
      component={() => <PrivateRoute component={FacilityTypeMasterMaster} />}
     />
         <Route
      exact
      path='/rolemaster'
      component={() => <PrivateRoute component={RoleMaster} />}
     />
    <Route
      exact
      path='/servicemastermaster'
      component={() => <PrivateRoute component={ServiceMasterMaster} />}
     />    
     <Route
      exact
      path='/financial'
      component={() => <PrivateRoute component={FinancialOld} />}
     />    
     <Route
      exact
      path='/operational'
      component={() => <PrivateRoute component={OperationalOld} />}
     />
    <Route
      exact
      path='/rejections'
      component={() => <PrivateRoute component={RejectionsOld} />}
     />     
     <Route
      exact
      path='/neweligibility'
      component={() => <PrivateRoute component={RiyathiEligibilityCheck} />}
     />     
      <Route
      exact
      path='/Riayathi'
      component={() => <PrivateRoute component={RiyathiNewClaim} />}
     />   
          <Route
      exact
      path='/reports'
      component={() => <PrivateRoute component={Reports} />}
     />
         
		<Route
      exact
      path='/ffc'
      component={() => <PrivateRoute component={FacilityCurrencyDownloader} />}
     /> 
		 
	<Route
      exact
      path='/reports/faciityrevenue'
      component={() => <PrivateRoute component={FacilityRevenue} />}
     />
         <Route
      exact
      path='/reports/reportClaimWiseReport'
      component={() => <PrivateRoute component={ReportClaimWiseReport} />}
     />
	 
	  <Route
      exact
      path='/reports/reportActivityWiseReport'
      component={() => <PrivateRoute component={ReportActivityWiseReport} />}
     />
       <Route
      exact
      path='/reports/reportclinicianrevenue'
      component={() => <PrivateRoute component={ReportClinicianRevenue} />}
     />
      <Route
      exact
      path='/reports/rejectionbyinsurance'
      component={() => <PrivateRoute component={RejectionByInsurance} />}
     />
          <Route
      exact
      path='/reports/currentclaimstatus'
      component={() => <PrivateRoute component={CurrentCliamStatus} />}
     />
       <Route
      exact
      path='/reports/reportfullclaimactivityhistory'
      component={() => <PrivateRoute component={FullClaimActivityHistory} />}
     />    
         
   <Route
      exact
      path='/reports/reportdrwiseinsurance'
      component={() => <PrivateRoute component={ReportDrwiseInsurance} />}
     />
       <Route
      exact
      path='/reports/reportrejectionmnec'
      component={() => <PrivateRoute component={ReportRejectionMnec} />}
     />
           <Route
      exact
      path='/reports/reportrejectionprec'
      component={() => <PrivateRoute component={ReportRejectionPrec} />}
     />
       <Route
      exact
      path='/reports/reportdepartmentrevenue'
      component={() => <PrivateRoute component={ReportDepartmentRevenue} />}
     />   
    <Route
      exact
      path='/reports/reportdepartmentclaimavg'
      component={() => <PrivateRoute component={DepartmentWiseClaimAvg} />}
     />     
        <Route
      exact
      path='/reports/reportclinicianrevenueICD'
      component={() => <PrivateRoute component={ReportClinicianRevenueICD} />}
     />   
     <Route
      exact
      path='/reports/reportcptrevenue'
      component={() => <PrivateRoute component={ReportCPTRevenue} />}
     />     
      <Route
      exact
      path='/reports/reportgrossrevenue'
      component={() => <PrivateRoute component={ReportGrossRevenue} />}
     />    
      <Route
      exact
      path='/reports/reportnetrevenue'
      component={() => <PrivateRoute component={ReportNetRevenue} />}
     />   
      <Route
      exact
      path='/reports/reportsubmissionclinicianTAT'
      component={() => (
       <PrivateRoute component={ReportSubmissionClinicianTAT} />
      )}
     />    
      <Route
      exact
      path='/reports/reportsubmissiondue'
      component={() => <PrivateRoute component={ReportSubmissionDue} />}
     />   
      <Route
      exact
      path='/reports/reportfacilitysubmissiontat'
      component={() => <PrivateRoute component={ReportFacilitySubmissionTAT} />}
     />     
      <Route
      exact
      path='/reports/reportdepartmentwisesubmission'
      component={() => (
       <PrivateRoute component={ReportDepartmentwiseSubmission} />
      )}
     />    
      <Route
      exact
      path='/reports/reportfacilitysubmission'
      component={() => <PrivateRoute component={ReportFacilitySubmission} />}
     />    
      <Route
      exact
      path='/reports/reportfacilitypaymentsummary'
      component={() => (
       <PrivateRoute component={ReportFacilityPaymentSummary} />
      )}
     />    
      <Route
      exact
      path='/reports/reportreceiverpaymentsummary'
      component={() => (
       <PrivateRoute component={ReportReceiverPaymentSummary} />
      )}
     />    
          <Route
      exact
      path='/reports/reportpayerpaymentsummary'
      component={() => <PrivateRoute component={ReportPayerPaymentSummary} />}
     />
     <Route
      exact
      path='/reports/reportclinicianpaymentsummary'
      component={() => (
       <PrivateRoute component={ReportClinicianPaymentSummary} />
      )}
     />     
      <Route
      exact
      path='/reports/reportdepartmentpaymentsummary'
      component={() => <PrivateRoute component={ReportDepartmentPayment} />}
     />     
       <Route
      exact
      path='/reports/resubmissionclaimcycle'
      component={() => <PrivateRoute component={ResubmissionClaimCycle} />}
     />   
	 <Route
      exact
      path='/reports/reportclinicianrevenueavg'
      component={() => <PrivateRoute component={ReportClinicianRevenueAvg} />}
     /> 
        <Route
      exact
      path='/fav/reportclinicianrevenue'
      component={() => <PrivateRoute component={FavClinicianRevenue} />}
     /> 
          <Route
      exact
      path='/observation'
      component={() => <PrivateRoute component={Observation} />}
     /> 
         <Route
      exact
      path='/reports/ReportRAActivityLevel'
      component={() => <PrivateRoute component={ReportRAActivityLevel} />}
     /> 
          <Route
      exact
      path='/reports/ReportPaymentReferenceSummary'
      component={() => <PrivateRoute component={ReportPaymentReferenceSummary} />}
     /> 
     <Route
      exact
      path='/reports/ReportPaymentReferenceTracker'
      component={() => <PrivateRoute component={ReportPaymentReferenceTracker} />}
     /> 
          <Route
      exact
      path='/reports/ReportReconciliationSummary'
      component={() => <PrivateRoute component={ReportReconciliationSummary} />}
     /> 
	 <Route
          exact
      path='/reports/ReportClaimForReSubmmision'
      component={() => <PrivateRoute component={ReportClaimForReSubmmision} />}
     />
<Route	 
           exact
      path='/reports/ReportClaimForReSubmissionApproved'
      component={() => <PrivateRoute component={ClaimForReSubmissionApproved} />}
     /> 
	 <Route
         exact
      path='/reports/ClaimForReSubmissionApproval'
      component={() => <PrivateRoute component={ClaimForReSubmissionApproval} />}
     /> 
         <Route
         exact
      path='/reports/ReportFacilityAging'
      component={() => <PrivateRoute component={ReportFacilityAging} />}
     /> 
     <Route
         exact
      path='/reports/ReportClinicianAging'
      component={() => <PrivateRoute component={ReportClinicianAging} />}
     />      
         <Route
         exact
      path='/reports/ReportDeparmentAging'
      component={() => <PrivateRoute component={ReportDeparmentAging} />}
     />  
<Route
         exact
      path='/reports/ReportTPAAging'
      component={() => <PrivateRoute component={ReportTPAAging} />}
     />     	 
         
         <Route
         exact
      path='/reports/ReportRejectionAging'
      component={() => <PrivateRoute component={ReportRejectionAging} />}
     />  
          <Route
         exact
      path='/reports/ReportRejectionFinalDetails'
      component={() => <PrivateRoute component={ReportRejectionFinalDetails} />}
     />  
          <Route
         exact
      path='/reports/ReportRejectionInitialDetails'
      component={() => <PrivateRoute component={ReportRejectionInitialDetails} />}
     />  
	  <Route
         exact
      path='/reports/ReportRejectionClinicianDetails'
      component={() => <PrivateRoute component={ReportRejectionClinicianDetails} />}
     />  
         <Route
         exact
      path='/reports/ReportRejectionSummary'
      component={() => <PrivateRoute component={ReportRejectionSummary} />}
     />  
          <Route
         exact
      path='/reports/ReportRejectionDepartmentDetails'
      component={() => <PrivateRoute component={ReportRejectionDepartmentDetails} />}
     />  
          <Route
         exact
      path='/reports/ReportRejectionICDDetails'
      component={() => <PrivateRoute component={ReportRejectionICDDetails} />}
     />  
         <Route
         exact
      path='/reports/ReportRejectionCPTDetails'
      component={() => <PrivateRoute component={ReportRejectionCPTDetails} />}
     />  
           <Route
         exact
      path='/reports/ReportClaimForReconciliation'
      component={() => <PrivateRoute component={ReportClaimForReconciliation} />}
     />  
          <Route
         exact
      path='/reports/RemittanceReportClaim'
      component={() => <PrivateRoute component={Claim} />}
     />  
          <Route
         exact
      path='/reports/RemittanceReportActivity'
      component={() => <PrivateRoute component={Activity} />}
     />  
     <Route
         exact
      path='/reports/RemittanceReportradate'
      component={() => <PrivateRoute component={Radate} />}
     />       
     <Route
         exact
      path='/reports/RemittanceReportSubmissiondat'
      component={() => <PrivateRoute component={Submissiondate} />}
     />      
       <Route
         exact
      path='/reports/RemittanceReportEncounterdate'
      component={() => <PrivateRoute component={Encounterdate} />}
     />     
        <Route
         exact
      path='/reports/RemittanceReportsettlement'
      component={() => <PrivateRoute component={Settlement} />}
     />    
       <Route
         exact
      path='/reports/RemittanceReportReceiver'
      component={() => <PrivateRoute component={Receiver} />}
     />     
         
     <Route
         exact
      path='/reports/RemittanceReportPayer'
      component={() => <PrivateRoute component={Payer} />}
     />       
          <Route
         exact
      path='/reports/RemittanceReportDuplicate'
      component={() => <PrivateRoute component={Duplicate} />}
     />    
             
           {/*
           <Route
      exact
      path='/dhpo'
      component={() => <PrivateRoute component={RiyathiNewClaim} />}
     />
     <Route
      exact
      path='/dhpo1'
      component={() => <PrivateRoute component={PrimaCare} />}
     />
     <Route
      exact
      path='/selectfacility'
      component={() => <PrivateRoute component={SelectFacility} />}
     />
     <Route
      exact
      path='/riaya'
      component={() => <PrivateRoute component={MainDashboard} />}
     />
     <Route
      exact
      path='/authorityapi'
      component={() => <PrivateRoute component={AuthorityApiMaster} />}
     />
     <Route
      exact
      path='/authoritydata'
      component={() => <PrivateRoute component={AuthorityDatamaster} />}
     />
     <Route
      exact
      path='/authoritymaster'
      component={() => <PrivateRoute component={AuthorityMaster} />}
     />
     <Route
      exact
      path='/booleantable'
      component={() => <PrivateRoute component={BooleanTable} />}
     />
     <Route
      exact
      path='/facilityactivemaster'
      component={() => <PrivateRoute component={FacilityActiveTypeMaster} />}
     />
     <Route
      exact
      path='/facilitycategorymaster'
      component={() => <PrivateRoute component={FacilityCategory} />}
     />
     <Route
      exact
      path='/facilitycountrymaster'
      component={() => <PrivateRoute component={FacilityCountryMaster} />}
     />
     <Route
      exact
      path='/facilitycurrency'
      component={() => <PrivateRoute component={FacilityCurrency} />}
     />
     <Route
      exact
      path='/facilitymastermaster'
      component={() => <PrivateRoute component={FacilityMasterMaster} />}
     />
     <Route
      exact
      path='/facilityregionregion'
      component={() => <PrivateRoute component={FacilityRegionRegion} />}
     />
     <Route
      exact
      path='/facilitytypemastermaster'
      component={() => <PrivateRoute component={FacilityTypeMasterMaster} />}
     />
     <Route
      exact
      path='/rolemaster'
      component={() => <PrivateRoute component={RoleMaster} />}
     />
     <Route
      exact
      path='/servicemastermaster'
      component={() => <PrivateRoute component={ServiceMasterMaster} />}
     />
     <Route
      exact
      path='/financial'
      component={() => <PrivateRoute component={FinancialOld} />}
     />
     <Route
      exact
      path='/operational'
      component={() => <PrivateRoute component={OperationalOld} />}
     />
     <Route
      exact
      path='/rejections'
      component={() => <PrivateRoute component={RejectionsOld} />}
     />
     <Route
      exact
      path='/neweligibility'
      component={() => <PrivateRoute component={RiyathiEligibilityCheck} />}
     />
     <Route
      exact
      path='/Riayathi'
      component={() => <PrivateRoute component={RiyathiNewClaim} />}
     />
     <Route
      exact
      path='/reports'
      component={() => <PrivateRoute component={Reports} />}
     />
     <Route
      exact
      path='/ffc'
      component={() => <PrivateRoute component={FacilityCurrencyDownloader} />}
     />
     <Route
      exact
      path='/reports/faciityrevenue'
      component={() => <PrivateRoute component={FacilityRevenue} />}
     />
     <Route
      exact
      path='/reports/reportClaimWiseReport'
      component={() => <PrivateRoute component={ReportClaimWiseReport} />}
     />
      <Route
      exact
      path='/reports/reportActivityWiseReport'
      component={() => <PrivateRoute component={ReportActivityWiseReport} />}
     />
     <Route
      exact
      path='/reports/reportclinicianrevenue'
      component={() => <PrivateRoute component={ReportClinicianRevenue} />}
     />
     <Route
      exact
      path='/reports/rejectionbyinsurance'
      component={() => <PrivateRoute component={RejectionByInsurance} />}
     />
     <Route
      exact
      path='/reports/currentclaimstatus'
      component={() => <PrivateRoute component={CurrentCliamStatus} />}
     />
     <Route
      exact
      path='/reports/reportfullclaimactivityhistory'
      component={() => <PrivateRoute component={FullClaimActivityHistory} />}
     />
     <Route
      exact
      path='/reports/reportdrwiseinsurance'
      component={() => <PrivateRoute component={ReportDrwiseInsurance} />}
     />
     <Route
      exact
      path='/reports/reportrejectionmnec'
      component={() => <PrivateRoute component={ReportRejectionMnec} />}
     />
     <Route
      exact
      path='/reports/reportrejectionprec'
      component={() => <PrivateRoute component={ReportRejectionPrec} />}
     />
     
     <Route
      exact
      path='/reports/reportdepartmentrevenue'
      component={() => <PrivateRoute component={ReportDepartmentRevenue} />}
     />
     
     <Route
      exact
      path='/reports/reportdepartmentclaimavg'
      component={() => <PrivateRoute component={DepartmentWiseClaimAvg} />}
     />
     <Route
      exact
      path='/reports/reportclinicianrevenueICD'
      component={() => <PrivateRoute component={ReportClinicianRevenueICD} />}
     />
     <Route
      exact
      path='/reports/reportcptrevenue'
      component={() => <PrivateRoute component={ReportCPTRevenue} />}
     />
     <Route
      exact
      path='/reports/reportgrossrevenue'
      component={() => <PrivateRoute component={ReportGrossRevenue} />}
     />
     <Route
      exact
      path='/reports/reportnetrevenue'
      component={() => <PrivateRoute component={ReportNetRevenue} />}
     />
     <Route
      exact
      path='/reports/reportsubmissionclinicianTAT'
      component={() => (
       <PrivateRoute component={ReportSubmissionClinicianTAT} />
      )}
     />
     <Route
      exact
      path='/reports/reportsubmissiondue'
      component={() => <PrivateRoute component={ReportSubmissionDue} />}
     />
     <Route
      exact
      path='/reports/reportfacilitysubmissiontat'
      component={() => <PrivateRoute component={ReportFacilitySubmissionTAT} />}
     />
     <Route
      exact
      path='/reports/reportdepartmentwisesubmission'
      component={() => (
       <PrivateRoute component={ReportDepartmentwiseSubmission} />
      )}
     />
     <Route
      exact
      path='/reports/reportfacilitysubmission'
      component={() => <PrivateRoute component={ReportFacilitySubmission} />}
     />
     <Route
      exact
      path='/reports/reportfacilitypaymentsummary'
      component={() => (
       <PrivateRoute component={ReportFacilityPaymentSummary} />
      )}
     />
     <Route
      exact
      path='/reports/reportreceiverpaymentsummary'
      component={() => (
       <PrivateRoute component={ReportReceiverPaymentSummary} />
      )}
     />
     <Route
      exact
      path='/reports/reportpayerpaymentsummary'
      component={() => <PrivateRoute component={ReportPayerPaymentSummary} />}
     />
     <Route
      exact
      path='/reports/reportclinicianpaymentsummary'
      component={() => (
       <PrivateRoute component={ReportClinicianPaymentSummary} />
      )}
     />
     <Route
      exact
      path='/reports/reportdepartmentpaymentsummary'
      component={() => <PrivateRoute component={ReportDepartmentPayment} />}
     />
     <Route
      exact
      path='/reports/resubmissionclaimcycle'
      component={() => <PrivateRoute component={ResubmissionClaimCycle} />}
     />
     <Route
      exact
      path='/fav/reportclinicianrevenue'
      component={() => <PrivateRoute component={FavClinicianRevenue} />}
     /> 
          {/* **********************Private Routes Ends Here************************ */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
