import React, { useMemo, useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Box, Stack } from "@mui/material";
import MaterialReactTable from "material-react-table";
import NumberFormat from "react-number-format";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import axios from "axios";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import NEWSIDEBAR from "../../layout/newSidebar";
import API_URL from "../../tools/api";

// import Kpis from "../../KPI";
import "react-multiple-select-dropdown-lite/dist/index.css";
import MultiSelect from "react-multiple-select-dropdown-lite";
import {
  message,
  Button,
  Space,
  Popover,
  Radio,
  Tooltip,
  DatePicker,
} from "antd";
import { FilterTwoTone } from "@ant-design/icons";
import { ContactSupportOutlined } from "@material-ui/icons";

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

export const data = [
  {
    Sub_FileName: "DHA-F-FMC_0047577_01042022_30042022.xml",
    SettlementDate: "2022-07-05 00:00:00",
    SubmissionType: "Submission",
    ReSubRejected: 0.0,
    ClaimID: "MMBI-I026393",
    IDPayer: "22059188131",
    ServiceYear: 2022,
    ReceiverID: "TPA010",
    ClinicianName: "Shabeer Abdul Mohammed",
    TransactionDate: "2022-05-10 15:11:00",
    ReceiverDesc: "FMC NETWORK UAE MANAGEMENT CONSULTANCY",
    PayerID: "INS012",
    Approved: 43.0,
    Authority: "dhpo",
    TreatmentDate: "2022-04-23 09:40:00",
    ResubmissionType: "0",
    NetSubmission: 43.0,
    SubRejected: 0.0,
    FacilityID: "DHA-F-0047577",
    PatientID: "258907",
    Unsettled: 0.0,
    ProviderDesc: "PRIMACARE SPECIALITY CLINICS MMB",
    NetReSubmission: 0.0,
    ClinicianID: "2022-07-05 03:13:00",
    FacilityGroups: "PRIMA",
    Recovery: 0.0,
    RA_FileName: "0_867921.xml",
    PaymentStatus: "Sub-Fully Paid",
    ServiceMonth: 4,
    MemberID: "22048938196",
    PayerDesc: "OMAN INSURANCE COMPANY",
  },
  {
    Sub_FileName: "DHA-F-FMC_0047577_01042022_30042022.xml",
    SettlementDate: "2022-08-05 00:00:00",
    SubmissionType: "Submission",
    ReSubRejected: 0.0,
    ClaimID: "MMBI-I026408",
    IDPayer: "22059188132",
    ServiceYear: 2022,
    ReceiverID: "TPA010",
    ClinicianName: "Marites Pulma",
    TransactionDate: "2022-05-10 15:11:00",
    ReceiverDesc: "FMC NETWORK UAE MANAGEMENT CONSULTANCY",
    PayerID: "INS022",
    Approved: 12.0,
    Authority: "dhpo",
    TreatmentDate: "2022-04-23 11:30:00",
    ResubmissionType: "0",
    NetSubmission: 12.0,
    SubRejected: 0.0,
    FacilityID: "DHA-F-0047577",
    PatientID: "54220",
    Unsettled: 0.0,
    ProviderDesc: "PRIMACARE SPECIALITY CLINICS MMB",
    NetReSubmission: 0.0,
    ClinicianID: "2022-08-05 00:20:00",
    FacilityGroups: "PRIMA",
    Recovery: 0.0,
    RA_FileName: "0_587445.xml",
    PaymentStatus: "Sub-Fully Paid",
    ServiceMonth: 4,
    MemberID: "22048938743",
    PayerDesc: "TAKAFUL EMARAT - INSURANCE",
  },
  {
    Sub_FileName: "DHA-F-FMC_0047577_01042022_30042022.xml",
    SettlementDate: "2022-05-26 00:00:00",
    SubmissionType: "Submission",
    ReSubRejected: 0.0,
    subRows: [
      {
        Sub_FileName: "DHA-F-0047577_TPA010__Jun02_2022_130238_RESUB.xml",
        SettlementDate: "2022-06-26 00:00:00",
        SubmissionType: "1st Resubmission",
        ReSubRejected: 28.0,
        ClaimID: "MMBI-I026417",
        IDPayer: "22059188133",
        ServiceYear: 2022,
        ReceiverID: "TPA010",
        ClinicianName: "Shefna Abdul Aziz",
        TransactionDate: "2022-06-02 13:02:00",
        ReceiverDesc: "FMC NETWORK UAE MANAGEMENT CONSULTANCY",
        PayerID: "INS022",
        Approved: 0.0,
        Authority: "dhpo",
        TreatmentDate: "2022-04-23 12:05:00",
        ResubmissionType: "internal complaint",
        NetSubmission: 0.0,
        SubRejected: 0.0,
        FacilityID: "DHA-F-0047577",
        PatientID: "2649019",
        Unsettled: 0.0,
        ProviderDesc: "PRIMACARE SPECIALITY CLINICS MMB",
        NetReSubmission: 28.0,
        ClinicianID: "2022-06-26 00:07:00",
        FacilityGroups: "PRIMA",
        Recovery: 0.0,
        RA_FileName: "0_200261.xml",
        PaymentStatus: "ReSub-Fully Rejected",
        ServiceMonth: 4,
        MemberID: "22048939019",
        PayerDesc: "TAKAFUL EMARAT - INSURANCE",
      },
    ],
    ClaimID: "MMBI-I026417",
    IDPayer: "22059188133",
    ServiceYear: 2022,
    ReceiverID: "TPA010",
    ClinicianName: "Shefna Abdul Aziz",
    TransactionDate: "2022-05-10 15:11:00",
    ReceiverDesc: "FMC NETWORK UAE MANAGEMENT CONSULTANCY",
    PayerID: "INS022",
    Approved: 0.0,
    Authority: "dhpo",
    TreatmentDate: "2022-04-23 12:05:00",
    ResubmissionType: "0",
    NetSubmission: 28.0,
    SubRejected: 28.0,
    FacilityID: "DHA-F-0047577",
    PatientID: "2649019",
    Unsettled: 0.0,
    ProviderDesc: "PRIMACARE SPECIALITY CLINICS MMB",
    NetReSubmission: 0.0,
    ClinicianID: "2022-05-26 02:50:00",
    FacilityGroups: "PRIMA",
    Recovery: 0.0,
    RA_FileName: "0_539034.xml",
    PaymentStatus: "Sub-Fully Rejected",
    ServiceMonth: 4,
    MemberID: "22048939019",
    PayerDesc: "TAKAFUL EMARAT - INSURANCE",
  },
];
const data1 = [
  {
    Sub_FileName: "TAKAFUL EMARAT - INSURANCE_01012022_31012022.xml",
    SettlementDate: "2022-03-07 00:00:00",
    SubmissionType: "Submission",
    ReSubRejected: "0.0",
    ClaimID: "MMBI-I016017",
    IDPayer: "22028940364",
    ServiceYear: "2022",
    ReceiverID: "TPA010",
    ClinicianName: "Arravind Pillai",
    TransactionDate: "2022-02-10 13:35:00",
    ReceiverDesc: "FMC NETWORK UAE MANAGEMENT CONSULTANCY",
    PayerID: "INS022",
    Approved: "0.0",
    Authority: "dhpo",
    TreatmentDate: "2022-01-06 10:30:00",
    ResubmissionType: "0",
    NetSubmission: "27.0",
    SubRejected: "27.0",
    FacilityID: "DHA-F-0047577",
    PatientID: "10620",
    Unsettled: "0.0",
    ProviderDesc: "PRIMACARE SPECIALITY CLINICS MMB",
    NetReSubmission: "0.0",
    ClinicianID: "2022-03-07 03:50:00",
    FacilityGroups: "PRIMA",
    Recovery: "0.0",
    RA_FileName: "0_947801.xml",
    PaymentStatus: "Sub-Fully Rejected",
    ServiceMonth: "1",
    MemberID: "0",
    PayerDesc: "TAKAFUL EMARAT - INSURANCE",
    subRows: [
      {
        Sub_FileName: "TAKAFUL EMARAT - INSURANCE_01012022_31012022.xml",
        SettlementDate: "2022-03-07 00:00:00",
        SubmissionType: "Submission",
        ReSubRejected: "0.0",
        ClaimID: "MMBI-I016017",
        IDPayer: "22028940364",
        ServiceYear: "2022",
        ReceiverID: "TPA010",
        ClinicianName: "Arravind Pillai",
        TransactionDate: "2022-02-10 13:35:00",
        ReceiverDesc: "FMC NETWORK UAE MANAGEMENT CONSULTANCY",
        PayerID: "INS022",
        Approved: "0.0",
        Authority: "dhpo",
        TreatmentDate: "2022-01-06 10:30:00",
        ResubmissionType: "0",
        NetSubmission: "27.0",
        SubRejected: "27.0",
        FacilityID: "DHA-F-0047577",
        PatientID: "10620",
        Unsettled: "0.0",
        ProviderDesc: "PRIMACARE SPECIALITY CLINICS MMB",
        NetReSubmission: "0.0",
        ClinicianID: "2022-03-07 03:50:00",
        FacilityGroups: "PRIMA",
        Recovery: "0.0",
        RA_FileName: "0_947801.xml",
        PaymentStatus: "Sub-Fully Rejected",
        ServiceMonth: "1",
        MemberID: "0",
        PayerDesc: "TAKAFUL EMARAT - INSURANCE",
      },
    ],
  },
];

const FullClaimActivityHistory = () => {
  const columns = useMemo(
    //column definitions...
    // Sub_FileName=TAKAFUL EMARAT - INSURANCE_01012022_31012022.xml, SettlementDate=2022-04-23 00:00:00, SubmissionType=Submission, ReSubRejected=0.0, ClaimID=MMBI-I015950, IDPayer=22028940355, ServiceYear=2022, ReceiverID=TPA010, ClinicianName=Marites Pulma, TransactionDate=2022-02-10 13:35:00, ReceiverDesc=FMC NETWORK UAE MANAGEMENT CONSULTANCY, PayerID=INS022, Approved=28.0, Authority=dhpo, TreatmentDate=2022-01-06 14:35:00, ResubmissionType=0, NetSubmission=28.0, SubRejected=0.0, FacilityID=DHA-F-0047577, PatientID=55104, Unsettled=0.0, ProviderDesc=PRIMACARE SPECIALITY CLINICS MMB, NetReSubmission=0.0, ClinicianID=2022-04-23 01:46:00, FacilityGroups=PRIMA, Recovery=0.0, RA_FileName=0_738504.xml, PaymentStatus=Sub-Fully Paid, ServiceMonth=1, MemberID=0, PayerDesc=TAKAFUL EMARAT - INSURANCE

    () => [
      {
        accessorKey: "SubmissionType",
        header: "SubmissionType",
      },
      {
        accessorKey: "PaymentStatus",
        header: "PaymentStatus",
      },
      {
        accessorKey: "ClaimID",
        header: "ClaimID",
      },
      {
        accessorKey: "FacilityGroups",
        header: "FacilityGroups",
      },
      {
        accessorKey: "Authority",
        header: "Authority",
      },

      {
        accessorKey: "FacilityID",
        header: "FacilityID",
      },
      {
        accessorKey: "ReceiverID",
        header: "ReceiverID",
      },

      {
        accessorKey: "PayerID",
        enableColumnOrdering: false,
        header: "PayerID",
      },
      {
        accessorKey: "PatientID",
        header: "PatientID",
      },
      {
        accessorKey: "MemberID",
        header: "MemberID",
      },
      {
        accessorKey: "ClinicianID",
        header: "ClinicianID",
      },
      {
        accessorKey: "TreatmentDate",
        header: "TreatmentDate",
      },
      {
        accessorKey: "TransactionDate",
        header: "TransactionDate",
      },
      {
        accessorKey: "NetSubmission",
        header: "NetSubmission",
      },
      {
        accessorKey: "SubRejected",
        header: "SubRejected",
      },
      {
        accessorKey: "NetReSubmission",
        header: "NetReSubmission",
      },
      {
        accessorKey: "ReSubRejected",
        header: "ReSubRejected",
      },
      {
        accessorKey: "Approved",
        header: "Approved",
      },
      {
        accessorKey: "Recovery",
        header: "Recovery",
      },
      {
        accessorKey: "Unsettled",
        header: "Unsettled",
      },
      {
        accessorKey: "IDPayer",
        header: "IDPayer",
      },
      {
        accessorKey: "SettlementDate",
        header: "SettlementDate",
      },
      {
        accessorKey: "RA_Date",
        header: "RA_Date",
      },

      {
        accessorKey: "ReceiverDesc",
        header: "ReceiverDesc",
      },

      {
        accessorKey: "PayerDesc",
        header: "PayerDesc",
      },
      {
        accessorKey: "ClinicianName",
        header: "ClinicianName",
      },
      {
        accessorKey: "ProviderDesc",
        header: "ProviderDesc",
      },
      {
        accessorKey: "ServiceYear",
        header: "ServiceYear",
      },
      {
        accessorKey: "ServiceMonth",
        header: "ServiceMonth",
      },
      {
        accessorKey: "Sub_FileName",
        header: "Sub_FileName",
      },
      {
        accessorKey: "RA_FileName",
        header: "RA_FileName",
      },
    ],
    []
    //end
  );
  const [first, setFirst] = useState();
  const [kpiData, setKpiData] = useState("");
  const [facilityStartDate, setFacilityStartDate] = useState();
  const [facilityEndDate, setFacilityEndDate] = useState();
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  const data1 = async () => {
    const data1 = await axios.post(API_URL + "/report/ReportFullClaimHistory", {
      user_id: localStorage.getItem("user_id"),
      dateType: "S",
    });
    console.log("data12333444", data1.data.data);
    setFirst(data1.data.data);
    setFacilityStartDate(data1.data.Start_date);
    setFacilityEndDate(data1.data.End_date);
  };

  const DashboardKPI = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setKpiData(data.data.DashboardKPI);
  };

  //Fliter Api Starts Here
  const DashboardKPIFilter = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "00:00",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });

    setKpiData(data.data.DashboardKPI);
  };

  useEffect(() => {
    data1();
    DashboardKPI();
  }, []);
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const averageSalary = useMemo(
    () => data.reduce((acc, curr) => acc + curr.revenue, 0),
    []
  );

  const maxAge = useMemo(
    () => data.reduce((acc, curr) => Math.max(acc, curr.age), 0),
    []
  );

  function subtotal(data) {
    return data.map(({ revenue }) => revenue).reduce((sum, i) => sum + i, 0);
  }

  //console.log("total", subtotal(localStorage.getItem("user_id")));

  // Use State for Filter Section starts here // Tamil change ("") to ()
  const [facilitySelect, setFacilitySelect] = useState();
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState("S");
  const [selectedReceiver, setSelectedReceiver] = useState();
  const [selectedPayer, setSelectedPayer] = useState();
  //useState for DropDown
  const [faciltyGroup, setFacilityGroup] = useState([]);
  const [facilityGroupName, setFacilityGroupName] = useState("");
  const [payer, setPayer] = useState("");
  const [receiver, setReceiver] = useState([]);
  //DropDown Data Change
  const [facilityGroupData, setFacilityGroupData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);

  console.log(startDate);
  console.log(endDate);
  // Use State for Filter Section Ends here

  //Filter Api starts Here
  const text = <span>Filter</span>;

  const companyName = [];
  const facilitylicence = [];

  //Date Picker

  const onOk = (value1) => {
    console.log("onOk: ", value1);
  };

  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const onChangeStartDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setStartDate(dateString);
  };

  const onChangeEndDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setEndDate(dateString);
  };
  //Date Type Radio Button
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const handleChangeFacility = (e) => {
    setFacilityGroupData(e.target.value);
  };

  console.log(facilityGroupData);

  //Multi Select Receiver Data
  var receiverchange = (e) => {
    let CompanyName = e.map((el) => el.value);
    this.setState([CompanyName]);
  };
  const receiverResult =
    receiver &&
    receiver.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  //console.log("result", receiverResult);
  const receiverList = (isActive) =>
    receiverResult &&
    receiverResult.map(
      (el) =>
        el.isActive == true && {
          value: el.Auth_No,
          label: el.Auth_No.concat("---").concat(el.CompanyName),
        }
    );
  let receiverSelectList = receiverList(true);
  console.log("ReceiverSelectList", selectedReceiver);

  const handleOnchangeReceiver = (val) => {
    setSelectedReceiver(val);
  };

  // const payerResult =
  //   payer &&
  //   payer.map(function (el) {
  //     var o = Object.assign({}, el);
  //     o.isActive = true;
  //     return o;
  //   });
  // // console.log("result", payerResult);
  // const payerList = (isActive) =>
  //   payerResult &&
  //   payerResult.map(
  //     (el) =>
  //       el.isActive == true && {
  //         value: el.Auth_No,
  //         label: el.Auth_No.concat("---").concat(el.CompanyName),
  //       }
  //   );
  // let payerSelectList = payerList(true);
  // console.log("PayerSelectList", payerSelectList);

  // const handleOnchangePayer = (val) => {
  //   setSelectedPayer(val);
  // };

  // var facilitychange = (e) => {
  //   let CompanyName = e.map((el) => el.value);
  //   this.setState({ facilitylicence });
  // };
  const facilityResult =
    faciltyGroup &&
    faciltyGroup.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  // console.log("result", payerResult);
  const facilityList = (isActive) =>
    facilityResult &&
    facilityResult.map(
      (el) =>
        el.isActive == true && {
          value: el.facilitylicence,
          label: el.facilitylicence,
        }
    );
  let facilitySelectList = facilityList(true);

  const handleOnchangeFacility = (val) => {
    setFacilitySelect(val);
  };
  const FacilityGroups = async () => {
    try {
      const FacilityGroups = await axios.post(
        API_URL + "/filterData/dropDwonFacilityGroups",

        {
          user_id: localStorage.getItem("user_id"),
          //user_id: "1",
          dataType: value,
        }
      );
      console.log("data", FacilityGroups.data.UserFacilityGroups);
      setFacilityGroupName(FacilityGroups.data.UserFacilityGroups);
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = Math.random() * 20;
        return Math.min(oldProgress + newProgress, 100);
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const FacilityDropDown = async () => {
    const menu2 = await axios.post(API_URL + "/filterData/dropDwonFacility", {
      //user_id: "1",
      user_id: localStorage.getItem("user_id"),
      dateType: value,
      facilityGroups: facilityGroupData,
    });

    console.log("Menu2", menu2.data.UserFacility);
    setFacilityGroup(menu2.data.UserFacility);
  };

  // const Payer = async () => {
  //   try {
  //     const Payer = await axios.post(
  //       API_URL + "/filterData/dropDwonPayer",

  //       {
  //         payerId: "",
  //         dateType: value,
  //         healthauth: "meta_dhpo",
  //       }
  //     );
  //     // console.log("Payer", Payer.data.Payer);
  //     setPayer(Payer.data.Payer);
  //   } catch (e) {
  //     console.log(e);
  //     console.log("Payer Error");
  //   }
  // };
  const Receiver = async () => {
    const Receiver = await axios.post(
      API_URL + "/filterData/dropDwonReceiver",
      {
        receiverId: "",
        dateType: value,
        healthauth: "",
      }
    );
    console.log("Receiver", Receiver);
    setReceiver(Receiver.data.Receiver);
  };

  //Filter Api Ends Here

  const TableFilter = async () => {
    try {
      const data = await axios.post(
        API_URL + "/report/ReportFullClaimHistory",
        {
          startDate: startDate + " " + "00:00",
          endDate: endDate + " " + "00:00",
          //startDate: "01/01/2020 00:00",
          //endDate: "29/05/2022 00:00",
          dateType: value,
          //user_id: "1",

          user_id: localStorage.getItem("user_id"),
          facilityGroups: facilityGroupData,
          facilityIdList: String(facilitySelect).split(","),
          receiverIdList: String(selectedReceiver).split(","),
          payerIdList: String(selectedPayer).split(","),
        }
      );
      console.log("Table Filter", data.data);
      message.success("Filter Applied Successfully");
      setFirst(data.data.data);
      setFacilityStartDate(data.data.Start_date);
      setFacilityEndDate(data.data.End_date);
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };

  // Dashboard Api Ends Here

  useEffect(() => {
    FacilityGroups();
    //Payer();
    Receiver();
  }, []);

  // Filter Section Content Starts Here
  const content = (
    <Space direction="vertical">
      <div className="card-header p-0">
        <div className="row">
          <div className="col-md-6">
            <div class="form-group mb-8 ">
              <label class="form-label">Facility Group</label>

              <select
                value={facilityGroupData}
                multiple=""
                class="form-select form-select-sm"
                onChange={handleChangeFacility}
                onClick={FacilityDropDown}
              >
                <option></option>
                {facilityGroupName &&
                  facilityGroupName.map((el, i) => (
                    <option key={i} value={el.facilitygroupsname}>
                      {el.facilitygroupsname}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group mb-2 ">
              <label class="form-label">Facility</label>
              {/* <select
                value={facilitySelect}
                multiple=""
                class="form-select form-select-sm"
                onChange={(e) => setFacilitySelect(e.target.value)}
              >
                <option></option>
                {faciltyGroup &&
                  faciltyGroup.map((el, i) => (
                    <option key={i} value={el.facilitylicence}>
                      {el.facilitylicence}
                    </option>
                  ))}
              </select> */}
              <MultiSelect
                onChange={handleOnchangeFacility}
                options={facilitySelectList}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">Start Date</label>
              <Space direction="vertical">
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={startDate}
                  onChange={onChangeStartDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">End Date</label>
              <Space direction="vertical" size={3}>
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={endDate}
                  onChange={onChangeEndDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label col-lg-12">Date Type</label>

              <Radio.Group onChange={onChangeRadio} value={value}>
                <Radio value={"S"}>Service</Radio>
                <Radio value={"T"}>Transcation</Radio>
                <Radio value={"R"}>RA</Radio>
                <Radio value={"P"}>Settlement</Radio>
              </Radio.Group>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label">Select Payer</label>
              <MultiSelect
                onChange={handleOnchangePayer}
                options={payerSelectList}
                class="form-select form-select-sm"
              />
            </div>
          </div> */}

          <div className="">
            <div class="form-group mb-2">
              <label class="form-label">Select Receiver</label>
              <MultiSelect
                onChange={handleOnchangeReceiver}
                options={receiverSelectList}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label">Traffic Type</label>
              <select multiple="" class="form-select" id="exampleSelect2">
                <option>OP</option>
                <option>IP</option>
                <option>DC</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>
      <div className="col-md-12">
        <div className="float-end">
          <Button
            //onClick={data}
            onClick={() => {
              data();
              hide();
            }}
            style={{
              marginRight: "10px",
            }}
            type="dashed"
            danger
          >
            Cancel Filter
          </Button>

          <Button
            //onClick={ChartFilter}
            onClick={() => {
              TableFilter();
              DashboardKPIFilter();
              hide();
            }}
            type="primary"
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </Space>
  );

  return (
    <>
   
      <div className="home-section">
        <Header />
        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
            <section className="dashboard">
              {/* Kpis Sections Starts */}
              <div className="row  kpi  font-size-6">
                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-exclamation-circle"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          <NumberFormat
                            //value={archartData.DashboardKPI[0].Traffic}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Avg/Month "}
                          />
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Traffic}
                          displayType={"text"}
                          prefix={"# "}
                          thousandSeparator={true}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Revenue
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-usd"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Revenue}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Approved
                      <span className="float-end  text-success">
                        <span className="h6 fa fa-check-square-o"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Paid}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Processed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Rejected
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-ban"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Rejection}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Rejected}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Unsettled
                      <span className="float-end  text-warning">
                        <span className="h6 fa fa-balance-scale"></span>
                        <span className=" mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Unprocessed}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Unprocessed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic Type
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-warning"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          DC#:{kpiData && kpiData[0].Traffic_DAYCARE}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        OP# : {kpiData && kpiData[0].Traffic_OP}
                        <span className="padding-left-5">
                          IP# : {kpiData && kpiData[0].Traffic_IP}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Avg Sub TAT
                      <span className="float-end text-success">
                        <span className="h6 fa fa-line-chart"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].AVG_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"# "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Max Sub TAT
                      <span className="float-end  text-primary">
                        <span className="h6 fa fa-tachometer"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].MAX_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"#"}
                        />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* Kpis Sections Ends */}
              {/* Facility Card Board */}
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card mb-3">
                    <div className="card-body p-0 font-size-6">
                      <div className="status lh-1">
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Start Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b> {facilityStartDate}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>End Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b>{facilityEndDate}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Facility Group:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b>{facilityGroupData}</b>
                            {/* {facilityData &&
                        facilityData.FacilitiesGroup[1].facilitygroupsname} */}
                          </label>
                        </div>
                        <div className="form-check form-check-inline padding-left-5">
                          <a class="btn btn btn-sm btn-white margin-right-10">
                            <span class="blink text-warning">
                              <b>Reports: **Excludes ReSubmission Variances</b>
                            </span>
                          </a>
                        </div>

                        <div class="form-check form-check-inline padding-left-5 float-end m-0 py-1 px-1">
                          {/* Filter Section Starts Here */}

                          <>
                            <div className="ant-filter">
                              <div
                                style={{
                                  clear: "both",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div className="col-lg-3">
                                  <Tooltip placement="bottom" title="Refresh">
                                    <a
                                      href="/dashboard"
                                      className="btn btn btn-sm btn-white border-0"
                                    >
                                      <i
                                        class="fa fa-refresh fa-spin"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </Tooltip>
                                  <Popover
                                    placement="bottom"
                                    overlayClassName="col-lg-4 shadow-sm"
                                    title={text}
                                    content={content}
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                  >
                                    <Tooltip placement="bottom" title={text}>
                                      <Button
                                        className="border-0"
                                        icon={<FilterTwoTone />}
                                      ></Button>
                                    </Tooltip>
                                  </Popover>
                                </div>
                              </div>
                            </div>
                          </>
                          {/* Filter Section Ends Here */}
                        </div>
                        <div class="form-check form-check-inline padding-left-5 float-end">
                          <label class="col-form-label margin-right-5">
                            <b>Facility:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b> {facilitySelect}</b>
                          </label>
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Facility Card board Ends */}
              <div className="col-lg-12">
                <section className="dashboard AuthorityApimaster">
                  <div className="card border-light mb-4">
                    <div className="card-header d-flex justify-content-between p-2">
                      <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                        Full Claim Activity History
                      </h4>
                      <div class="float-end"></div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <MaterialReactTable
                          columns={columns}
                          data={first ?? []}
                          state={{ showProgressBars: true }}
                          enableExpanding
                          enableExpandAll
                          enableGrouping
                          enableStickyHeader
                          enableStickyFooter
                          enableRowSelection
                          enablePinning
                          positionToolbarAlertBanner="bottom"
                          paginateExpandedRows={true} //default
                          renderTopToolbarCustomActions={({ table }) => (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "1rem",
                                p: "0.5rem",
                                flexWrap: "wrap",
                              }}
                            >
                              <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export All Data
                              </Button>
                              <Button
                                disabled={
                                  table.getPrePaginationRowModel().rows
                                    .length === 0
                                }
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleExportRows(
                                    table.getPrePaginationRowModel().rows
                                  )
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export All Rows
                              </Button>
                              <Button
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() =>
                                  handleExportRows(table.getRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Page Rows
                              </Button>
                              <Button
                                disabled={
                                  !table.getIsSomeRowsSelected() &&
                                  !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() =>
                                  handleExportRows(
                                    table.getSelectedRowModel().rows
                                  )
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Selected Rows
                              </Button>
                            </Box>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default FullClaimActivityHistory;
