import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import Header from "../layout/header";
import NEWSIDEBAR from "../layout/newSidebar";

const Reports = () => {
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  
  return (
    <>
      
      <div className="home-section">
        <Header />
        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
            <section className="dashboard">
              <div className="bg-white mb-3">
                <div className="mega-content">
                  <div className="p-2">
                    <div className="row">
                      <div className="col-12 col-sm-4 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Revenue</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/faciityrevenue"
                              className="nav-link p-0"
                            >
                              Facility Revenue
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportClaimWiseReport"
                              title="Claim Wise Report"
                              className="nav-link p-0"
                            >
                              Claim Wise Report
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportActivityWiseReport"
                              title="Claim Activity Wise Report"
                              className="nav-link p-0"
                            >
                              Activity Wise Report
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportclinicianrevenue"
                              title="Clinician Revenue"
                              className="nav-link p-0"
                            >
                              Clinician Revenue
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportDepartmentRevenue"
                              title="Department Revenue"
                              className="nav-link p-0"
                            >
                              Department Revenue
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportclinicianrevenueavg"
                              title="Clinician Claim Avg"
                              className="nav-link p-0"
                            >
                              Clinician Wise Average Report
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportdepartmentclaimavg"
                              title="Department Wise Claim Average"
                              className="nav-link p-0"
                            >
                              Department Wise Claim Average
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportclinicianrevenueICD"
                              title="Clinician Revenue ICDs"
                              className="nav-link p-0"
                            >
                              Clinician Revenue ICDs Report
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportcptrevenue"
                              title=" CPT Revenue Report"
                              className="nav-link p-0"
                            >
                              CPT Revenue Report
                            </Link>
                          </li>

                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportgrossrevenue"
                              title="Gross Revenue"
                              className="nav-link p-0"
                            >
                              Gross Revenue
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportnetrevenue"
                              title="Net Revenue"
                              className="nav-link p-0"
                            >
                              Net Revenue
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/currentclaimstatus"
                              title="Current Claim Status"
                              className="nav-link p-0"
                            >
                              Current Claim Status
                            </Link>
                          </li>
                         {/*  <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportfullclaimactivityhistory"
                              title="Full Claim Activity History"
                              className="nav-link p-0"
                            >
                              Full Claim Activity History
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                      <div className="col-12 col-sm-4 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Submission</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportsubmissionclinicianTAT"
                              title="Clinician Submission TAT"
                              className="nav-link p-0"
                            >
                              Clinician Submission TAT
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportsubmissiondue"
                              title="Submission Due"
                              className="nav-link p-0"
                            >
                              Submission Due
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportfacilitysubmissiontat"
                              title="Facility Submission TAT"
                              className="nav-link p-0"
                            >
                              Facility Submission TAT
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/#"
                              title="Claim submission External Data"
                              className="nav-link p-0"
                            >
                              Claim submission External Data
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportdepartmentwisesubmission"
                              title="Department wise Submission"
                              className="nav-link p-0"
                            >
                              Department wise Submission
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportfacilitysubmission"
                              title="Facility Submission"
                              className="nav-link p-0"
                            >
                              Facility Submission
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-sm-4 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Payment</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportfacilitypaymentsummary"
                              title="Facility Payment Summary "
                              className="nav-link p-0"
                            >
                              Facility Payment Summary{" "}
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportreceiverpaymentsummary"
                              title="Receiver Payment Summary"
                              className="nav-link p-0"
                            >
                              Receiver Payment Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportpayerpaymentsummary"
                              title="Payer Payment Summary"
                              className="nav-link p-0"
                            >
                              Payer Payment Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportclinicianpaymentsummary"
                              title="Clinician Payment Summary"
                              className="nav-link p-0"
                            >
                              Clinician Payment Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportdepartmentpaymentsummary"
                              title="Department Payment Summary"
                              className="nav-link p-0"
                            >
                              Department Payment Summary
                            </Link>
                          </li>
                          {/* Linked Clinician Revenue for now on 31-10-2022     */}
                      {/*     <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRAActivityLevel"
                              title="RA report (activity level)"
                              className="nav-link p-0"
                            >
                              RA report (activity level)
                            </Link>
                          </li> */}
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportPaymentReferenceSummary"
                              title="Payment Reference Summary"
                              className="nav-link p-0"
                            >
                              Payment Reference Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportPaymentReferenceTracker"
                              title="Payment Reference Summary"
                              className="nav-link p-0"
                            >
                              Payment Reference Tracker
                            </Link>
                          </li>
                           <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportReconciliationSummary"
                              title="Reconciliation Summary"
                              className="nav-link p-0"
                            >
                              Reconciliation Summary
                            </Link>
                          </li> 

                        </ul>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Resubmission</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/resubmissionclaimcycle"
                              title="Resubmission claim cycle"
                              className="nav-link p-0"
                            >
                              Resubmission claim cycle
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportClaimForReSubmmision"
                              title="Claims For Resubmission"
                              className="nav-link p-0"
                            >
                              Claims For Resubmission
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportClaimForReSubmissionApproved"
                              title="Not for resubmission for approval"
                              className="nav-link p-0"
                            >
                              Not for resubmission for approval
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ClaimForReSubmissionApproval"
                              title="Not for resubmission approved"
                              className="nav-link p-0"
                            >
                              Not for resubmission approved
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Aging</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportFacilityAging"
                              title="Facility Aging"
                              className="nav-link p-0"
                            >
                              Facility Aging
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportClinicianAging"
                              title="Clinician Aging"
                              className="nav-link p-0"
                            >
                              Clinician Aging
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportDeparmentAging"
                              title="Department Aging"
                              className="nav-link p-0"
                            >
                              Department Aging
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportTPAAging"
                              title="Rejection Aging TPA Insurance"
                              className="nav-link p-0"
                            >
                              Rejection Aging TPA Insurance
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionAging"
                              title="Rejection Aging Clinician"
                              className="nav-link p-0"
                            >
                              Rejection Aging Clinician
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Rejection</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionSummary"
                              title="Rejection Summary"
                              className="nav-link p-0"
                            >
                              Rejection Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionInitialDetails"
                              title="Initial Rejection Details"
                              className="nav-link p-0"
                            >
                              Initial Rejection Details
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionFinalDetails"
                              title="Final Rejection Details"
                              className="nav-link p-0"
                            >
                              Final Rejection Details
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionClinicianDetails"
                              title="Clinician Rejection Analysis"
                              className="nav-link p-0"
                            >
                              Clinician Rejection Analysis
                            </Link>
                          </li>

                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionDepartmentDetails"
                              title="Department Rejection Summary"
                              className="nav-link p-0"
                            >
                              Department Rejection Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionICDDetails"
                              className="nav-link p-0"
                            >
                              Top 5 ICD Rejection Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportRejectionCPTDetails"
                              title="Top 5 CPT Rejection Summary"
                              className="nav-link p-0"
                            >
                              Top 5 CPT Rejection Summary
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/ReportClaimForReconciliation"
                              title="Claims for final reconciliation"
                              className="nav-link p-0"
                            >
                              Claims for final reconciliation
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Remittance</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/RemittanceReportDuplicate"
                              title="Duplicate Remittance"
                              className="nav-link p-0"
                            >
                              Duplicate Remittance
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            {" "}
                            Remittance Report by :
                            <div className="mx-2">
                              <Link
                                to="/reports/RemittanceReportClaim"
                                title="claim"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                claim
                              </Link>
                              <Link
                                to="/reports/RemittanceReportActivity"
                                title="activity"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                activity
                              </Link>
                              <Link
                                to="/reports/RemittanceReportradate"
                                title="RA date"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                RA date
                              </Link>
                              <Link
                                to="/reports/RemittanceReportSubmissiondate"
                                title="Submission date"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Submission date
                              </Link>
                              <Link
                                to="/reports/RemittanceReportEncounterdate"
                                title="Encounter date"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Encounter date
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/RemittanceReportsettlement"
                              title="Remittance Monthly by Settlement Date"
                              className="nav-link p-0"
                            >
                              Remittance Monthly by Settlement Date
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/RemittanceReportReceiver"
                              title="Remittance Monthly by Receiver"
                              className="nav-link p-0"
                            >
                              Remittance Monthly by Receiver
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/RemittanceReportPayer"
                              title="Remittance Monthly by Payer"
                              className="nav-link p-0"
                            >
                              Remittance Monthly by Payer
                            </Link>
                          </li>
                          <li className="nav-item mb-2">
                            <Link
                              to="/reports/reportclinicianrevenue"
                              title="Remittance Vs Facility Bank Reconciliation"
                              className="nav-link p-0"
                            >
                              Remittance Vs Facility Bank Reconciliation
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2 py-2">
                        <h5 className="text-dark">Management Reports</h5>
                        <div className="dropdown-divider"></div>
                        <ul className="nav flex-column mt-3">
                          <li className="nav-item mb-2">
                            {" "}
                            Insurance Review
                            <div className="mx-2">
                              <Link
                                to="/reports/rejectionbyinsurance"
                                title="claim"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Rejection By Insurance
                              </Link>
                              <Link
                                to="/reports/reportdrwiseinsurance"
                                title="claim"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Doctor Wise Insurance
                              </Link>
                              <Link
                                to="/reports/reportrejectionmnec"
                                title="activity"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                MNEC
                              </Link>
                              <Link
                                to="/reports/reportrejectionprec"
                                title="RA date"
                                className="nav-link p-0"
                              >
                                <i
                                  className="fa fa-caret-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                PRCE
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Reports;
