import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import "antd/dist/antd.min.css";
import {
  Button,
  message,
  Space,
  Popover,
  Radio,
  Tooltip,
  DatePicker,
  Alert,
} from "antd";
import {
  FullscreenOutlined,
  FilterTwoTone,
  FilterFilled,
} from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import axios from "axios";
import API_URL from "../../tools/api";
import "react-multiple-select-dropdown-lite/dist/index.css";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Sidebar from "../../layout/sidebar";
import NEWSIDEBAR from "../../layout/newSidebar";

let resizeFlag = true;

const RiyathiNewClaim = () => {
  const [style, setStyle] = useState("style");

  const changeStyle = () => {
    console.log("Success");
    if (resizeFlag === true) {
      setStyle("styleone");
      resizeFlag = false;
    } else {
      setStyle("style");
      resizeFlag = true;
    }
  };

  const [toggle, setToggle] = useState(true);
  const [toggleone, setToggleone] = useState(true);
  const [toggletwo, setToggletwo] = useState(true);
  const [togglethree, setTogglethree] = useState(true);
  const [togglefour, setTogglefour] = useState(true);
  const [archartData, setArChartData] = useState("");
  const [kpiData, setKpiData] = useState("");
  const [facilityData, setFacilityData] = useState("");
  const [size, setSize] = useState("small");
  const [piechartData, setPiechartData] = useState("");
  const [agingAnalysis, setAgingAnalysis] = useState("");
  const [areaChart, setAreaChart] = useState("");
  const [barChart, setBarChat] = useState("");
  // Use State for Filter Section starts here
  const [facilitySelect, setFacilitySelect] = useState();
  const [value, setValue] = useState("S");
  const [selectedReceiver, setSelectedReceiver] = useState();
  const [selectedPayer, setSelectedPayer] = useState();
  //useState for DropDown
  const [faciltyGroup, setFacilityGroup] = useState([]);
  const [facilityGroupName, setFacilityGroupName] = useState("");
  const [payer, setPayer] = useState("");
  const [receiver, setReceiver] = useState([]);
  //DropDown Data Change
  const [facilityGroupData, setFacilityGroupData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);

  console.log(startDate);
  console.log(endDate);
  // Use State for Filter Section Ends here

  //Filter Api starts Here
  const text = <span>Filter</span>;

  const companyName = [];
  const facilitylicence = [];

  //Date Picker

  const onOk = (value1) => {
    console.log("onOk: ", value1);
  };

  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const onChangeStartDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setStartDate(dateString);
  };

  const onChangeEndDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setEndDate(dateString);
  };
  //Date Type Radio Button
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const handleChangeFacility = (e) => {
    setFacilityGroupData(e.target.value);
  };

  console.log(facilityGroupData);

  //Multi Select Receiver Data
  var receiverchange = (e) => {
    let CompanyName = e.map((el) => el.value);
    this.setState([CompanyName]);
  };
  const receiverResult =
    receiver &&
    receiver.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  //console.log("result", receiverResult);
  const receiverList = (isActive) =>
    receiverResult &&
    receiverResult.map(
      (el) =>
        el.isActive == true && {
          value: el.Auth_No,
          label: el.Auth_No.concat("---").concat(el.CompanyName),
        }
    );
  let receiverSelectList = receiverList(true);
  console.log("ReceiverSelectList", selectedReceiver);

  const handleOnchangeReceiver = (val) => {
    setSelectedReceiver(val);
  };

  //console.log("Selected Receiver", selectedReceiver);
  //Multi Select Payer Data
  // var payerchange = (e) => {
  //   let CompanyName = e.map((el) => el.value);
  //   this.setState({ CompanyName });
  // };

  // console.log("result", payerResult);

  // var facilitychange = (e) => {
  //   let CompanyName = e.map((el) => el.value);
  //   this.setState({ facilitylicence });
  // };
  const facilityResult =
    faciltyGroup &&
    faciltyGroup.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  // console.log("result", payerResult);
  const facilityList = (isActive) =>
    facilityResult &&
    facilityResult.map(
      (el) =>
        el.isActive == true && {
          value: el.facilitylicence,
          label: el.facilitylicence,
        }
    );
  let facilitySelectList = facilityList(true);
  //console.log("PayerSelectList", payerSelectList);

  const handleOnchangeFacility = (val) => {
    setFacilitySelect(val);
  };
  const FacilityGroups = async () => {
    try {
      const FacilityGroups = await axios.post(
        API_URL + "/filterData/dropDwonFacilityGroups",

        {
          user_id: localStorage.getItem("user_id"),
          //user_id: "1",
          dataType: value,
        }
      );
      console.log("data", FacilityGroups.data.UserFacilityGroups);
      setFacilityGroupName(FacilityGroups.data.UserFacilityGroups);
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };

  const FacilityDropDown = async () => {
    const menu2 = await axios.post(API_URL + "/filterData/dropDwonFacility", {
      //user_id: "1",
      user_id: localStorage.getItem("user_id"),
      dateType: value,
      facilityGroups: facilityGroupData,
    });

    console.log("Menu2", menu2.data.UserFacility);
    setFacilityGroup(menu2.data.UserFacility);
  };

  const Payer = async () => {
    try {
      const Payer = await axios.post(
        API_URL + "/filterData/dropDwonPayer",

        {
          payerId: "",
          dateType: value,
          healthauth: "meta_dhpo",
        }
      );
      // console.log("Payer", Payer.data.Payer);
      setPayer(Payer.data.Payer);
    } catch (e) {
      console.log(e);
      console.log("Payer Error");
    }
  };
  const Receiver = async () => {
    const Receiver = await axios.post(
      API_URL + "/filterData/dropDwonReceiver",
      {
        receiverId: "",
        dateType: value,
        healthauth: "",
      }
    );
    console.log("Receiver", Receiver);
    setReceiver(Receiver.data.Receiver);
  };

  //Filter Api Ends Here

  //Dashboard Api Starts Here

  //Dashboard APi Starts Here
  const DashboardKPI = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setKpiData(data.data.DashboardKPI);
  };
  const ARChart = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardBar", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setArChartData(data.data);
    setFacilityData(data.data);
  };

  const TrendAnalysis = async () => {
    const data = await axios.post(
      API_URL + "/charts/dashboardDenielTrendAnalysis",
      {
        user_id: localStorage.getItem("user_id"),
        dateType: value,
      }
    );

    setAreaChart(data.data);
  };

  const PaymentAnalysis = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardPaymentBar", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setBarChat(data.data);
  };

  const DenailAnalysis = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardDenielBar", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setPiechartData(data.data.PieChartData);
  };

  const AgingAnalysis = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardAginAnalysis", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setAgingAnalysis(data.data);
  };

  // Dashboard Api Ends Here
  //Fliter Api Starts Here
  const DashboardKPIFilter = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "00:00",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });

    setKpiData(data.data.DashboardKPI);
  };
  const ARChartFilter = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardBar", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "00:00",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });
    message.success("Filter Applied Successfully");
    setArChartData(data.data);
    setFacilityData(data.data);
  };

  const TrendAnalysisFilter = async () => {
    const data = await axios.post(
      API_URL + "/charts/dashboardDenielTrendAnalysis",
      {
        startDate: startDate + " " + "00:00",
        endDate: endDate + " " + "00:00",
        dateType: value,
        user_id: localStorage.getItem("user_id"),
        facilityGroups: facilityGroupData,
        facilityIdList: String(facilitySelect).split(","),
        receiverIdList: String(selectedReceiver).split(","),
      }
    );

    setAreaChart(data.data);
  };

  const PaymentAnalysisFilter = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardPaymentBar", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "00:00",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });

    setBarChat(data.data);
  };
  const DenailAnalysisFilter = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardDenielBar", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "00:00",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });

    setPiechartData(data.data.PieChartData);
  };
  const AgingAnalysisFilter = async () => {
    const data = await axios.post(API_URL + "/charts/dashboardAginAnalysis", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "00:00",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });

    setAgingAnalysis(data.data);
  };
  ///######  Browser Inspecct 
  /*useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);
  */

  useEffect(() => {
    FacilityGroups();
    Receiver();
    DashboardKPI();
    ARChart();
    TrendAnalysis();
    PaymentAnalysis();
    AgingAnalysis();
    DenailAnalysis();
  }, []);

  // Filter Section Content Starts Here
  const content = (
    <Space direction="vertical">
      <div className="card-header p-0">
        <div className="row">
          <div className="col-md-6">
            <div class="form-group mb-8 ">
              <label class="form-label">Facility Group</label>

              <select
                value={facilityGroupData}
                multiple=""
                class="form-select form-select-sm"
                onChange={handleChangeFacility}
                onClick={FacilityDropDown}
              >
                <option></option>
                {facilityGroupName &&
                  facilityGroupName.map((el, i) => (
                    <option key={i} value={el.facilitygroupsname}>
                      {el.facilitygroupsname}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group mb-2 ">
              <label class="form-label">Facility</label>
              {/* <select
                value={facilitySelect}
                multiple=""
                class="form-select form-select-sm"
                onChange={(e) => setFacilitySelect(e.target.value)}
              >
                <option></option>
                {faciltyGroup &&
                  faciltyGroup.map((el, i) => (
                    <option key={i} value={el.facilitylicence}>
                      {el.facilitylicence}
                    </option>
                  ))}
              </select> */}
              <MultiSelect
                onChange={handleOnchangeFacility}
                options={facilitySelectList}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">Start Date</label>
              <Space direction="vertical">
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={startDate}
                  onChange={onChangeStartDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">End Date</label>
              <Space direction="vertical" size={3}>
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={endDate}
                  onChange={onChangeEndDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label col-lg-12">Date Type</label>

              <Radio.Group onChange={onChangeRadio} value={value}>
                <Radio value={"S"}>Service</Radio>
                <Radio value={"T"}>Transcation</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="">
            <div class="form-group mb-0">
              <label class="form-label">Select Receiver</label>
              <MultiSelect
                onChange={handleOnchangeReceiver}
                options={receiverSelectList}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div class="form-group mb-3">
              <label class="form-label mt-2">Traffic Type</label>
              <select multiple="" class="form-select" id="exampleSelect2">
                <option>OP</option>
                <option>IP</option>
                <option>DC</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="col-md-12 mb-5">
        <div className="float-end">
          <Button
            //onClick={data}
            onClick={() => {
              hide();
            }}
            style={{
              marginRight: "10px",
            }}
            type="dashed"
            danger
          >
            Cancel Filter
          </Button>

          <Button
            //onClick={ChartFilter}
            onClick={() => {
              hide();
              DashboardKPIFilter();
              ARChartFilter();
              TrendAnalysisFilter();
              PaymentAnalysisFilter();
              AgingAnalysisFilter();
              DenailAnalysisFilter();
            }}
            type="primary"
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </Space>
  );

  // Filter Section Content Ends Here

  return (
    <>
      {/* <NEWSIDEBAR /> */}
      {/* <Sidebar /> */}

      <div className="home-section">
        <Header />

        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
            <section className="dashboard">
              {/* Kpis Sections Starts */}
              <div className="row  kpi  font-size-6">
                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-exclamation-circle"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          <NumberFormat
                            //value={archartData.DashboardKPI[0].Traffic}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Avg/Month "}
                          />
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Traffic}
                          displayType={"text"}
                          prefix={"# "}
                          thousandSeparator={true}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Revenue
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-usd"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Revenue}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Approved
                      <span className="float-end  text-success">
                        <span className="h6 fa fa-check-square-o"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Paid}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Processed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Rejected
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-ban"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Rejection}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Rejected}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Unsettled
                      <span className="float-end  text-warning">
                        <span className="h6 fa fa-balance-scale"></span>
                        <span className=" mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Unprocessed}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Unprocessed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic Type
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-warning"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          DC#:{kpiData && kpiData[0].Traffic_DAYCARE}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        OP# : {kpiData && kpiData[0].Traffic_OP}
                        <span className="padding-left-5">
                          IP# : {kpiData && kpiData[0].Traffic_IP}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Avg Sub TAT
                      <span className="float-end text-success">
                        <span className="h6 fa fa-line-chart"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].AVG_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"# "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Max Sub TAT
                      <span className="float-end  text-primary">
                        <span className="h6 fa fa-tachometer"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].MAX_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"#"}
                        />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* Kpis Sections Ends */}
              {/* Facility Card Board */}
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card mb-3">
                    <div className="card-body p-0 font-size-6">
                      <div className="status lh-1">
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Start Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b> {facilityData.Start_date}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>End Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b>{facilityData.End_date}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Facility Group:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b>{facilityGroupData}</b>
                            {/* {facilityData &&
                        facilityData.FacilitiesGroup[1].facilitygroupsname} */}
                          </label>
                        </div>
                        <div className="form-check form-check-inline padding-left-5">
                          <a class="btn btn btn-sm btn-white margin-right-10">
                            <span class="blink text-warning">
                              <b>**Includes ReSubmission Variances</b>
                            </span>
                          </a>
                        </div>

                        <div class="form-check form-check-inline padding-left-5 float-end m-0 py-1 px-1">
                          {/* Filter Section Starts Here */}

                          <>
                            <div className="ant-filter">
                              <div
                                style={{
                                  clear: "both",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div className="col-lg-3">
                                  <Tooltip placement="bottom" title="Refresh">
                                    <a
                                      href="/Riayathi"
                                      className="btn btn btn-sm btn-white border-0"
                                    >
                                      <i
                                        class="fa fa-refresh fa-spin"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </Tooltip>
                                  <Popover
                                    placement="bottom"
                                    overlayClassName="col-lg-4 shadow-sm"
                                    title={text}
                                    content={content}
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                    // overlayStyle={{
                                    //   height: "400px",
                                    //   overflow: "auto",
                                    // }}
                                  >
                                    <Tooltip placement="bottom" title={text}>
                                      <Button
                                        className="border-0"
                                        icon={<FilterFilled />}
                                      ></Button>
                                    </Tooltip>
                                  </Popover>
                                </div>
                              </div>
                            </div>
                          </>
                          {/* Filter Section Ends Here */}
                        </div>
                        <div class="form-check form-check-inline padding-left-5 float-end">
                          <label class="col-form-label margin-right-5">
                            <b>Facility:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b> {facilitySelect}</b>
                          </label>
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Facility Card board Ends */}

              {/* Dashboard Chart Starts Here */}
              {/* First Chart */}
              <div className="row">
                {toggle && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          A/R Perfomance
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              <Button
                                type="text"
                                icon={<FullscreenOutlined />}
                                onClick={() => {
                                  setToggleone(!toggleone);
                                  setToggletwo(!toggletwo);
                                  setTogglethree(!togglethree);
                                  setTogglefour(!togglefour);

                                  changeStyle();
                                }}
                              ></Button>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            darkMode: "auto",

                            legend: {
                              bottom: "0%",
                            },

                            tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                type: "shadow",
                              },
                            },

                            xAxis: {
                              type: "category",
                              data: archartData.AR_Month,
                            },
                            yAxis: {
                              type: "value",
                              axisLabel: {
                                formatter: "{value}",
                              },
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: false },
                                saveAsImage: { show: true },
                              },
                            },
                            series: [
                              {
                                name: "Revenue",
                                data: archartData.AR_Revenue,
                                type: "bar",
                              },
                              {
                                name: "Approved",
                                data: archartData.AR_Approved,
                                type: "bar",
                              },
                              {
                                name: "Denied",
                                data: archartData.AR_Rejection,
                                type: "bar",
                              },

                              {
                                name: "UnSetteled",
                                data: archartData.AR_Unsetelled,
                                type: "bar",
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Second Donut Chart */}

                {toggleone && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          Denial Analysis
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              <div className="">
                                <Button
                                  type="text"
                                  icon={<FullscreenOutlined />}
                                  onClick={() => {
                                    setToggle(!toggle);
                                    setToggletwo(!toggletwo);
                                    setTogglethree(!togglethree);
                                    setTogglefour(!togglefour);

                                    changeStyle();
                                  }}
                                ></Button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            tooltip: {
                              trigger: "item",
                            },
                            legend: {
                              bottom: "0%",
                              type: "scroll",
                              orient: "vertical",
                              left: 0,
                              top: 20,
                              bottom: 20,
                            },
                            toolbox: {
                              show: true,

                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },

                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: false },
                                saveAsImage: { show: true },
                              },
                            },
                            series: [
                              {
                                name: "Access From",
                                type: "pie",
                                radius: ["40%", "70%"],
                                avoidLabelOverlap: false,
                                itemStyle: {
                                  borderRadius: 10,
                                  borderColor: "#fff",
                                  borderWidth: 2,
                                },
                                label: {
                                  show: false,
                                  position: "center",
                                },
                                emphasis: {
                                  label: {
                                    show: true,
                                    fontSize: "20",
                                    fontWeight: "bold",
                                  },
                                },
                                labelLine: {
                                  show: false,
                                },
                                data: piechartData,
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Third Chart */}

                {toggletwo && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          Trend Analysis
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item">
                              <Button
                                type="text"
                                icon={<FullscreenOutlined />}
                                onClick={() => {
                                  setToggle(!toggle);
                                  setToggleone(!toggleone);
                                  setTogglethree(!togglethree);
                                  setTogglefour(!togglefour);
                                  changeStyle();
                                }}
                              ></Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            darkMode: "auto",

                            tooltip: {
                              trigger: "axis",
                            },
                            legend: {
                              data: areaChart.AreaChart_legend,
                              bottom: "0%",
                            },
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            },
                            toolbox: {
                              feature: {
                                saveAsImage: {},
                              },
                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },
                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: false },
                                saveAsImage: { show: true },
                              },
                            },
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "10%",
                              containLabel: true,
                            },
                            xAxis: {
                              type: "category",
                              intervel: 0,
                              boundaryGap: false,
                              data: areaChart.AreaChart_X_Axis,
                            },
                            yAxis: {
                              type: "value",
                            },

                            series: [
                              // {
                              //   name: "2020",
                              //   type: "line",
                              //   stack: "Total",
                              //   areaStyle: {},
                              //   data: areaChart.Data1,
                              // },
                              {
                                name: areaChart.Name1,
                                type: "line",
                                stack: "Total",
                                areaStyle: {},
                                data: areaChart.Data1,
                              },
                              {
                                name: areaChart.Name2,
                                type: "line",
                                stack: "Total",
                                areaStyle: {},
                                data: areaChart.Data2,
                              },
                              {
                                name: areaChart.Name3,
                                type: "line",
                                stack: "Total",
                                areaStyle: {},
                                data: areaChart.Data3,
                              },
                              {
                                name: areaChart.Name4,
                                type: "line",
                                stack: "Total",
                                areaStyle: {},
                                data: areaChart.Data4,
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {togglethree && (
                  <div className={style}>
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          Payment Analysis
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <li class="list-inline-item"></li>
                            <li class="list-inline-item">
                              <Button
                                type="text"
                                icon={<FullscreenOutlined />}
                                onClick={() => {
                                  setToggle(!toggle);
                                  setToggleone(!toggleone);
                                  setToggletwo(!toggletwo);
                                  setTogglefour(!togglefour);
                                  changeStyle();
                                }}
                              ></Button>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            darkMode: "auto",
                            tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                // Use axis to trigger tooltip
                                type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                              },
                            },
                            toolbox: {
                              feature: {
                                saveAsImage: {},
                              },
                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },
                              top: "0%",
                              right: 15,
                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: false },
                                saveAsImage: { show: true },
                              },
                            },
                            legend: {
                              bottom: "0%",
                            },
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "10%",
                              containLabel: true,
                            },
                            xAxis: {
                              type: "category",
                              data: barChart.BarCharPaymentMonth,
                            },
                            yAxis: {
                              type: "value",
                            },
                            series: [
                              {
                                name: "Paid",
                                data: barChart.BarChartPaymentPaid,
                                type: "bar",
                              },
                              {
                                name: "TakeBack",
                                data: barChart.BarChartPaymentTakeBack,
                                type: "bar",
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {togglefour && (
                  <div className="col-lg-12">
                    <div className="chart card border-light mb-3">
                      <div className="card-header d-flex justify-content-between p-0">
                        <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                          Aging Analysis
                        </h4>
                        <div className="float-end">
                          <ul className="list-inline m-2">
                            <Button
                              type="text"
                              icon={<FullscreenOutlined />}
                              onClick={() => {
                                setToggle(!toggle);
                                setToggleone(!toggleone);
                                setToggletwo(!toggletwo);
                                setTogglethree(!togglethree);
                              }}
                            ></Button>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body text-center">
                        <ReactECharts
                          className="echarts-for-react-size"
                          option={{
                            darkMode: "auto",

                            tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                // Use axis to trigger tooltip
                                type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                              },
                            },
                            toolbox: {
                              feature: {
                                saveAsImage: {},
                              },
                              iconStyle: {
                                borderColor: "rgba(1, 161, 201, 1)",
                              },
                              top: "0%",
                              right: 15,

                              feature: {
                                dataZoom: {
                                  title: "Zoom",
                                },
                                mark: { show: true },
                                dataView: { show: true, readOnly: false },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar", "stack"],
                                },
                                restore: { show: false },
                                saveAsImage: { show: true },
                              },
                            },
                            legend: {
                              bottom: "0%",
                              type: "scroll",
                              orient: "vertical",
                              left: 0,
                              top: 20,
                              bottom: 20,
                            },
                            grid: {
                              left: "3%",
                              right: "0%",
                              bottom: "10%",
                              height: "100%",
                              containLabel: true,
                            },

                            xAxis: {
                              type: "value",
                            },
                            yAxis: {
                              type: "category",
                              data: agingAnalysis.claimAgingStagBar_ReceiverName,
                            },

                            series: [
                              {
                                name: "121-More Days",
                                type: "bar",
                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar121_more,
                              },
                              {
                                name: "91-120 Days",
                                type: "bar",
                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar91_120,
                              },
                              {
                                name: "76-90 Days",
                                type: "bar",
                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar76_90,
                              },
                              {
                                name: "61-75 Days",
                                type: "bar",

                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar61_75,
                              },
                              {
                                name: "46-60 Days",
                                type: "bar",
                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar46_60,
                              },
                              {
                                name: "31-45 Days",
                                type: "bar",
                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar31_45,
                              },
                              {
                                name: "16-30 Days",
                                type: "bar",
                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar16_30,
                              },
                              {
                                name: "0-15 Days",
                                type: "bar",
                                stack: "total",

                                emphasis: {
                                  focus: "series",
                                },
                                data: agingAnalysis.claimAgingStagBar0_15,
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </div>

      <section className="dashboard"></section>
    </>
  );
};

export default RiyathiNewClaim;
