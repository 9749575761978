import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import { isInit, isLoading } from "../../utils/asyncStatusHelper";
import { getSession } from "../../actions/sessionAction";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sessionAsyncStatus, loggedIn } = useSelector(
    ({ sessionReducer }) => ({
      loggedIn: sessionReducer.loggedIn,
      sessionAsyncStatus: sessionReducer.sessionAsyncStatus,
    })
  );

  useEffect(() => {
    if (isInit(sessionAsyncStatus)) {
      //dispatch(getSession());
      history.push(getSession());
    }
  }, [dispatch, sessionAsyncStatus]);

  if (isInit(sessionAsyncStatus) || isLoading(sessionAsyncStatus)) {
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
