import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import axios from "axios";
import API_URL from "../tools/api";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { message, Button, Tooltip, Popconfirm } from "antd";
import { EditTwoTone, DeleteOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import NEWSIDEBAR from "../layout/newSidebar";

const FacilityMasterMaster = () => {
  //useState for Input Fields Start
  const [facilityname, setfacilityname] = useState("");
  const [facilitylicence, setfacilitylicence] = useState("");
  const [facilitygroupsname, setfacilitygroupsname] = useState("");
  const [facilityrcmorgname, setfacilityrcmorgname] = useState("");
  const [facilityauthorityrestapibaseurl, setfacilityauthorityrestapibaseurl] =
    useState("");
  const [
    facilityauthoritysoapendpointurl,
    setfacilityauthoritysoapendpointurl,
  ] = useState("");
  const [facilityauthoritysoapaction, setfacilityauthoritysoapaction] =
    useState("");
  const [facilitypouserid, setfacilitypouserid] = useState("");
  const [facilitypassuserpwd, setfacilitypassuserpwd] = useState("");
  const [facilityphone, setfacilityphone] = useState("");
  const [facilitypointcontact, setfacilitypointcontact] = useState("");
  const [facilityaddress1, setfacilityaddress1] = useState("");
  const [facilityaddress2, setfacilityaddress2] = useState("");
  const [facilityaddress3, setfacilityaddress3] = useState("");
  const [facilitylocation, setfacilitylocation] = useState("");
  const [facilityactivestart, setfacilityactivestart] = useState(null);
  const [facilityactiveend, setfacilityactiveend] = useState(null);

  //Dropdown input field Select
  const [serviceprovideddata, Setserviceprovideddata] = useState(null);
  const [facilitytypedata, Setfacilitytypedata] = useState(null);
  const [facilitycatogerydata, Setfacilitycatogerydata] = useState(null);
  const [facilityactivetypedata, Setfacilityactivetypedata] = useState(null);
  const [facilityauthrotritynamedata, Setfacilityauthrotritynamedata] =
    useState(null);
  const [facilityauthorityapitypedata, Setfacilityauthorityapitypedata] =
    useState(null);
  const [facilityauthoritydatatypedata, Setfacilityauthoritydatatypedata] =
    useState(null);
  const [facilitycountrydata, Setfacilitycountrydata] = useState(null);
  const [facilityregiondata, Setfacilityregiondata] = useState(null);
  const [facilitycurrencydata, Setfacilitycurrencydata] = useState(null);
  const [facilityisrcmorgdata, Setfacilityisrcmorgdata] = useState(null);

  //useState for Input Fields End

  const [facilityMaster, setFacilityMaster] = useState([]);
  const [search, setSearch] = useState("");

  //useState for DropDown Menu
  const [serviceprovided, setserviceprovided] = useState([]);
  const [facilitytype, setfacilitytype] = useState([]);
  const [facilitycatogery, setfacilitycatogery] = useState([]);
  const [facilityactivetype, setfacilityactivetype] = useState([]);
  const [facilityisrcmorg, setfacilityisrcmorg] = useState([]);
  const [facilityauthrotrityname, setfacilityauthrotrityname] = useState([]);
  const [facilityauthorityapitype, setfacilityauthorityapitype] = useState([]);
  const [facilityauthoritydatatype, setfacilityauthoritydatatype] = useState(
    []
  );
  const [facilitycountry, setfacilitycountry] = useState([]);
  const [facilityregion, setfacilityregion] = useState([]);
  const [facilitycurrency, setfacilitycurrency] = useState([]);

  //useState for Table Pagination Start

  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  //useState for Table Pagination End

  const text = "Are you sure to delete this item?";

  console.log(
    // facilityname,
    // facilitylicence,
    // facilitygroupsname,
    // facilityrcmorgname,
    // facilityauthorityrestapibaseurl,
    // facilityauthoritysoapendpointurl,
    // facilityauthoritysoapaction,
    // facilitypouserid,
    // facilitypassuserpwd,
    // facilityphone,
    // facilitypointcontact,
    // facilityaddress1,
    // facilityaddress2,
    // facilityaddress3,
    // facilitylocation,
    // facilityactivestart,
    // facilityactiveend,
    //serviceprovideddata,
    facilitytypedata,
    facilityactivetypedata,
    facilitycatogerydata
  );

  //Read API

  const data = async () => {
    try {
      const data = await axios.post(API_URL + "/seedData/CurdFacility", {
        curdoperation: "R",
      });
      console.log("data", data.data.listOfFacility);
      setFacilityMaster(data.data.listOfFacility);
      setTotal(data.data.listOfFacility.length);
    } catch (e) {
      console.log(e);
      console.log("data1");
    }
  };

  useEffect(() => {
    data();
  }, []);

  //Create API

  const sendDataToApi = (el) => {
    axios
      .post(API_URL + "/seedData/CurdFacility", {
        curdoperation: "C",
        id: "0",
        facilityname,
        facilitylicence,
        facilitygroupsname,
        facilityrcmorgname,
        facilityauthorityrestapibaseurl,
        facilityauthoritysoapendpointurl,
        facilityauthoritysoapaction,
        facilitypouserid,
        facilitypassuserpwd,
        facilityphone,
        facilitypointcontact,
        facilityactivestart,
        facilityactiveend,
        facilityaddress2,
        facilityaddress3,
        createdby: localStorage.getItem("user_name"),
        createddatetime: "",
        //DropDown Start
        serviceprovided: serviceprovideddata,
        facilitytype: facilitytypedata,
        facilityactivetype: facilityactivetypedata,
        facilitycatogery: facilitycatogerydata,
        facilityisrcmorg: facilityisrcmorgdata,
        facilityauthrotrityname: facilityauthrotritynamedata,
        facilityauthorityapitype: facilityauthorityapitypedata,
        facilityauthoritydatatype: facilityauthoritydatatypedata,
        facilitycountry: facilitycountrydata,
        facilityregion: facilityregiondata,
        facilitycurrency: facilitycurrencydata,
        //DropDown End
        updatedby: "",
        updateddatetime: "",
      })
      .then((res) => {
        data();
        console.log("resppppp", res.data.error);
        if (res.data.error) {
          message.error(res.data.error);
        } else {
          message.success("Item Added Successfully");
        }
      });
    console.log(facilityname, "api");
  };

  //Set ID to Local

  const setID = (id, facilityname) => {
    console.log(id, facilityname, "api ID");
    localStorage.setItem("ID", id);
    localStorage.setItem("BooleanName", facilityname);
  };

  //Update API

  function updateUser(id) {
    let item = { facilityname };
    console.warn("item", item);
    axios
      .post(API_URL + "/seedData/CurdFacility", {
        curdoperation: "U",
        id,
        updatedby: localStorage.getItem("user_name"),
      })
      .then(() => {
        data();
        message.info("Item Updated Succesfully");
      });
  }

  //Delete API

  const onDelete = (id) => {
    axios
      .post(API_URL + "/seedData/CurdFacility", { curdoperation: "D", id })
      .then(() => {
        data();
        message.warn("Item Deleted Succesfully");
        console.log("Clicked ");
      });
  };

  //Table Pagination

  const indexOfLastPage = page + dataPerPage;
  const indexOfFirstPage = indexOfLastPage - dataPerPage;
  const currentPost = facilityMaster.slice(indexOfFirstPage, indexOfLastPage);
  const onShowSizeChange = (current, pageSize) => {
    setDataPerPage(pageSize);
  };

  //Drop Menu For Table

  const menuCurdServices = async () => {
    try {
      const menu1 = await axios.post(API_URL + "/seedData/CurdServices", {
        curdoperation: "R",
      });
      console.log("Menu1", menu1.data.listOfServices);
      setserviceprovided(menu1.data.listOfServices);
    } catch (e) {
      console.log(e);
      console.log("Error Side Menu");
    }
  };

  const menuFacilityType = async () => {
    const menu2 = await axios.post(API_URL + "/seedData/CurdFacilityType", {
      curdoperation: "R",
    });
    console.log("Menu2", menu2.data.listOfFacilityType);
    setfacilitytype(menu2.data.listOfFacilityType);
  };

  const menuCurdFacilityCategory = async () => {
    const menu3 = await axios.post(API_URL + "/seedData/CurdFacilityCategory", {
      curdoperation: "R",
    });
    console.log("Menu3", menu3.data.listOfFacilityCategory);
    setfacilitycatogery(menu3.data.listOfFacilityCategory);
  };

  const menuCurdFacilityActiveType = async () => {
    const menu4 = await axios.post(API_URL + "/seedData/CurdFacilityCategory", {
      curdoperation: "R",
    });
    console.log("Menu4", menu4.data.listOfFacilityCategory);
    setfacilityactivetype(menu4.data.listOfFacilityCategory);
  };
  const menuCurdBoolen = async () => {
    const menu5 = await axios.post(API_URL + "/seedData/CurdBoolen", {
      curdoperation: "R",
    });
    console.log("Menu5", menu5.data.listOfBoolen);
    setfacilityisrcmorg(menu5.data.listOfBoolen);
  };
  const menuCurdAuthority = async () => {
    const menu6 = await axios.post(API_URL + "/seedData/CurdAuthority", {
      curdoperation: "R",
    });
    console.log("Menu6", menu6.data.listOfAuthority);
    setfacilityauthrotrityname(menu6.data.listOfAuthority);
  };
  const menuCurdAuthorityApiType = async () => {
    const menu7 = await axios.post(API_URL + "/seedData/CurdAuthorityApiType", {
      curdoperation: "R",
    });
    console.log("Menu7", menu7.data.listOfAuthorityApiType);
    setfacilityauthorityapitype(menu7.data.listOfAuthorityApiType);
  };

  const menuCurdAuthorityDataType = async () => {
    const menu8 = await axios.post(
      API_URL + "/seedData/CurdAuthorityDataType",
      {
        curdoperation: "R",
      }
    );
    console.log("Menu8", menu8.data.listOfAuthorityDataType);
    setfacilityauthoritydatatype(menu8.data.listOfAuthorityDataType);
  };

  const menuCurdCountry = async () => {
    const menu9 = await axios.post(API_URL + "/seedData/CurdCountry", {
      curdoperation: "R",
    });
    console.log("Menu9", menu9.data.listOfCountry);
    setfacilitycountry(menu9.data.listOfCountry);
  };

  const menuCurdRegion = async () => {
    const menu10 = await axios.post(API_URL + "/seedData/CurdRegion", {
      curdoperation: "R",
    });
    console.log("Menu10", menu10.data.listOfRegion);
    setfacilityregion(menu10.data.listOfRegion);
  };
  const menuCurdCurrency = async () => {
    const menu11 = await axios.post(API_URL + "/seedData/CurdCurrency", {
      curdoperation: "R",
    });
    console.log("Menu11", menu11.data.listOfCurrency);
    setfacilitycurrency(menu11.data.listOfCurrency);
  };

  //  const setcurdServices = (e) => {
  //    this.setState({
  //      name: e.target.value,
  //    });
  //  };

  useEffect(() => {
    menuCurdServices();
    menuFacilityType();
    menuCurdFacilityCategory();
    menuCurdFacilityActiveType();
    menuCurdBoolen();
    menuCurdAuthority();
    menuCurdAuthorityApiType();
    menuCurdAuthorityDataType();
    menuCurdCountry();
    menuCurdRegion();
    menuCurdCurrency();
  }, []);

  return (
    <>
    
    <div className="home-section">
        <Header />

        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
          <section className="facilityMaster">
          <Col lg={12}>
            <div className=" card border-light mb-4">
              <div className="card-header d-flex justify-content-between p-2">
                <h4 className="card-title mt-1">Facility Master</h4>
                <div class="float-end">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      class="form-control"
                      aria-label="Amount (to the nearest dollar)"
                    />
                    <span class="input-group-text bg-primary margin-right-2">
                      <a href="#" class="text-white text-decoration-none">
                        <i class="fa fa-search"></i>
                      </a>
                    </span>

                    <button
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#facilityMaster"
                    >
                      {" "}
                      <i
                        class="fa fa-plus-square-o fa-lg"
                        aria-hidden="true"
                      ></i>{" "}
                      Add New{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Unique Identifier</th>
                        <th>Service Provided</th>
                        <th>Facility Licence</th>
                        <th>Facility Name</th>
                        <th>Facility Type</th>
                        <th>Facility Category</th>
                        <th>Facility Group Name</th>
                        <th>Facility Active Type</th>
                        <th>Facility Active Start</th>
                        <th>Facility Active End</th>
                        <th>Facility Is RCM Orgn</th>
                        <th>Facility RCM Orgn Name</th>
                        <th>Facility Authority Name</th>
                        <th>Facility Authority API Type</th>
                        <th>Facility Authority Data Type</th>
                        <th>Facility Authority Rest API Baseurl</th>
                        <th>Facility Authority Soap End Point URL</th>
                        <th>Facility Authority Soap Action</th>
                        <th>Facility Authority UserID</th>
                        <th>Facility Authority PWD</th>
                        <th>Facility Country</th>
                        <th>Facility Region</th>
                        <th>Facility Address1</th>
                        <th>Facility Address2</th>
                        <th>Facility Address3</th>
                        <th>Facility Location</th>
                        <th>Facility Currency</th>
                        <th>Facility Phone</th>
                        <th>Facility Point of Contact</th>
                        <th>Created By</th>
                        <th>Created DateTime</th>
                        <th>Updated By</th>
                        <th>Updated DateTime</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPost
                        .filter((item) => {
                          if (search == "") {
                            return item;
                          } else if (
                            item.facilityname
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item) => {
                          return (
                            <tr>
                              <td>{item.id}</td>
                              <td>{item.serviceprovided}</td>
                              <td>{item.facilitylicence}</td>
                              <td>{item.facilityname}</td>
                              <td>{item.facilitytype}</td>
                              <td>{item.facilitycatogery}</td>
                              <td>{item.facilitygroupsname}</td>
                              <td>{item.facilityactivetype}</td>
                              <td>{item.facilityactivestart}</td>
                              <td>{item.facilityactiveend}</td>
                              <td>{item.facilityisrcmorg}</td>
                              <td>{item.facilityrcmorgname}</td>
                              <td>{item.facilityauthrotrityname}</td>
                              <td>{item.facilityauthorityapitype}</td>
                              <td>{item.facilityauthoritydatatype}</td>
                              <td>{item.facilityauthorityrestapibaseurl}</td>
                              <td>{item.facilityauthoritysoapendpointurl}</td>
                              <td>{item.facilityauthoritysoapaction}</td>
                              <td>{item.facilitypouserid}</td>
                              <td>{item.facilitypassuserpwd}</td>
                              <td>{item.facilitycountry}</td>
                              <td>{item.facilityregion}</td>
                              <td>{item.facilityaddress1}</td>
                              <td>{item.facilityaddress2}</td>
                              <td>{item.facilityaddress3}</td>
                              <td>{item.facilitylocation}</td>
                              <td>{item.facilitycurrency}</td>
                              <td>{item.facilityphone}</td>
                              <td>{item.facilitypointcontact}</td>
                              <td>{item.createdby}</td>
                              <td>{item.createddatetime}</td>
                              <td>{item.updatedby}</td>
                              <td>{item.updateddatetime}</td>
                              <td>
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#facilityMasterUpdate"
                                  aria-controls="offcanvasRight"
                                >
                                  <Tooltip
                                    placement="top"
                                    title="Edit"
                                    color="blue"
                                  >
                                    <Button
                                      type="text"
                                      onClick={() => setID(item.id, item.name)}
                                      icon={<EditTwoTone />}
                                    ></Button>
                                  </Tooltip>
                                </a>{" "}
                                <a href="#">
                                  <Popconfirm
                                    placement="top"
                                    title={text}
                                    onConfirm={() => onDelete(item.id)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      type="text"
                                      danger
                                      ghost
                                      icon={
                                        <DeleteOutlined twoToneColor="#FF0000" />
                                      }
                                    ></Button>
                                  </Popconfirm>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Pagination
              onChange={(page) => setPage(page)}
              pageSize={dataPerPage}
              total={total}
              current={page}
              showSizeChanger
              showQuickJumper
              onShowSizeChange={onShowSizeChange}
            />
          </Col>
        </section>

        <div id="facilityMaster" class="modal">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Facility Master</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form className="user-profile-login">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Unique Identifier
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Service Provided
                        </label>
                        <select
                          //value={serviceprovideddata}
                          onChange={(e) =>
                            Setserviceprovideddata(e.target.value)
                          }
                          className="form-select  form-select-sm"
                        >
                          <option>Service Provided</option>

                          {serviceprovided &&
                            serviceprovided.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Licence
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setfacilitylicence(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">Facility Name</label>
                        <input
                          type="text"
                          onChange={(e) => setfacilityname(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">Facility Type</label>
                        <select
                          className="form-select  form-select-sm"
                          //value={facilitytypedata}
                          onChange={(e) => Setfacilitytypedata(e.target.value)}
                        >
                          <option value="-1" disabled>
                            Facility Type
                          </option>
                          {facilitytype &&
                            facilitytype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Category
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilitycatogerydata}
                          onChange={(e) =>
                            Setfacilitycatogerydata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Category
                          </option>
                          {facilitycatogery &&
                            facilitycatogery.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Group Name
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setfacilitygroupsname(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Active Type
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          //value={facilityactivetypedata}
                          onChange={(e) =>
                            Setfacilityactivetypedata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Active Type
                          </option>
                          {facilityactivetype &&
                            facilityactivetype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Active Start
                        </label>
                        <input
                          type="datetime-local"
                          //value={facilityactivestart}
                          className="form-control form-control-sm"
                          onChange={(e) =>
                            setfacilityactivestart(e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Active End
                        </label>
                        <input
                          type="datetime-local"
                          //value={facilityactiveend}
                          className="form-control form-control-sm"
                          onChange={(e) => setfacilityactiveend(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Is RCM Orgn
                        </label>

                        <select
                          className="form-select  form-select-sm"
                          // value={facilityisrcmorgdata}
                          onChange={(e) =>
                            Setfacilityisrcmorgdata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Is RCM Orgn
                          </option>
                          {facilityisrcmorg &&
                            facilityisrcmorg.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility RCM Orgn Name
                        </label>
                        <input
                          type="text"
                          //value={facilityrcmorgname}
                          onChange={(e) =>
                            setfacilityrcmorgname(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Name
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          //value={facilityauthrotritynamedata}
                          onChange={(e) =>
                            Setfacilityauthrotritynamedata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Authority Name
                          </option>
                          {facilityauthrotrityname &&
                            facilityauthrotrityname.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority API Type
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          //value={facilityauthorityapitypedata}
                          onChange={(e) =>
                            Setfacilityauthorityapitypedata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Authority API Type
                          </option>
                          {facilityauthorityapitype &&
                            facilityauthorityapitype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Data Type
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          //value={facilityauthoritydatatypedata}
                          onChange={(e) =>
                            Setfacilityauthoritydatatypedata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Authority Data Type
                          </option>
                          {facilityauthoritydatatype &&
                            facilityauthoritydatatype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Rest API Baseurl
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setfacilityauthorityrestapibaseurl(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Soap End Point URL
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setfacilityauthoritysoapendpointurl(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Soap Action
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setfacilityauthoritysoapaction(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority UserID
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setfacilitypouserid(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority PWD
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setfacilitypassuserpwd(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Country
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilitycountrydata}
                          onChange={(e) =>
                            Setfacilitycountrydata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Country
                          </option>
                          {facilitycountry &&
                            facilitycountry.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Region
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          // value={facilityregiondata}
                          onChange={(e) =>
                            Setfacilityregiondata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Region
                          </option>
                          {facilityregion &&
                            facilityregion.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Address1
                        </label>
                        <textarea
                          onChange={(e) => setfacilityaddress1(e.target.value)}
                          class="form-control mb-2"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Address2
                        </label>
                        <textarea
                          onChange={(e) => setfacilityaddress2(e.target.value)}
                          class="form-control mb-2"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Address3
                        </label>
                        <textarea
                          onChange={(e) => setfacilityaddress3(e.target.value)}
                          class="form-control mb-2"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Location
                        </label>
                        <textarea
                          onChange={(e) => setfacilitylocation(e.target.value)}
                          class="form-control mb-2"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Currency
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilitycurrencydata}
                          onChange={(e) =>
                            Setfacilitycurrencydata(e.target.value)
                          }
                        >
                          <option value="-1" disabled>
                            Facility Currency
                          </option>
                          {facilitycurrency &&
                            facilitycurrency.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">Facility Phone</label>
                        <input
                          type="number"
                          onChange={(e) => setfacilityphone(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Point of Contact
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setfacilitypointcontact(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-12 mt-2">
                      <div className="float-end">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm margin-right-5"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={sendDataToApi}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Facility Master Update Data */}
        <div id="facilityMasterUpdate" class="modal">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Facility Master Update</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form className="user-profile-login">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Unique Identifier
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Service Provided
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={serviceprovideddata}
                          onChange={(e) =>
                            Setserviceprovideddata(e.target.value)
                          }
                        >
                          <option>Service Provided</option>
                          {serviceprovided &&
                            serviceprovided.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Licence
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setfacilitylicence(e.target.value)}
                          value={facilitylicence}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">Facility Name</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={facilityname}
                          onChange={(e) => setfacilityname(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">Facility Type</label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilitytypedata}
                          onChange={(e) => Setfacilitytypedata(e.target.value)}
                        >
                          <option>Facility Type</option>
                          {facilitytype &&
                            facilitytype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Category
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilitycatogerydata}
                          onChange={(e) =>
                            Setfacilitycatogerydata(e.target.value)
                          }
                        >
                          <option>Facility Category</option>
                          {facilitycatogery &&
                            facilitycatogery.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Group Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={facilitygroupsname}
                          onChange={(e) =>
                            setfacilitygroupsname(e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Active Type
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilityactivetypedata}
                          onChange={(e) =>
                            Setfacilityactivetypedata(e.target.value)
                          }
                        >
                          <option>Facility Active Type</option>
                          {facilityactivetype &&
                            facilityactivetype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Active Start
                        </label>
                        <input
                          type="datetime-local"
                          value={facilityactivestart}
                          onChange={(e) =>
                            setfacilityactivestart(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Active End
                        </label>
                        <input
                          type="datetime-local"
                          value={facilityactiveend}
                          onChange={(e) => setfacilityactiveend(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Is RCM Orgn
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilityisrcmorgdata}
                          onChange={(e) =>
                            Setfacilityisrcmorgdata(e.target.value)
                          }
                        >
                          <option>Facility Is RCM Orgn</option>
                          {facilityisrcmorg &&
                            facilityisrcmorg.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility RCM Orgn Name
                        </label>
                        <input
                          type="text"
                          value={facilityrcmorgname}
                          onChange={(e) =>
                            setfacilityrcmorgname(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Name
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilityauthrotritynamedata}
                          onChange={(e) =>
                            Setfacilityauthrotritynamedata(e.target.value)
                          }
                        >
                          <option>Facility Authority Name</option>
                          {facilityauthrotrityname &&
                            facilityauthrotrityname.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority API Type
                        </label>
                        <select className="form-select  form-select-sm">
                          <option>Facility Authority API Type</option>
                          {facilityauthorityapitype &&
                            facilityauthorityapitype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Data Type
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilityauthoritydatatypedata}
                          onChange={(e) =>
                            Setfacilityauthoritydatatypedata(e.target.value)
                          }
                        >
                          <option>Facility Authority Data Type</option>
                          {facilityauthoritydatatype &&
                            facilityauthoritydatatype.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Rest API Baseurl
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={facilityauthorityrestapibaseurl}
                          onChange={(e) =>
                            setfacilityauthorityrestapibaseurl(e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Soap End Point URL
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={facilityauthoritysoapendpointurl}
                          onChange={(e) =>
                            setfacilityauthoritysoapendpointurl(e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority Soap Action
                        </label>
                        <input
                          type="text"
                          value={facilityauthoritysoapaction}
                          onChange={(e) =>
                            setfacilityauthoritysoapaction(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority UserID
                        </label>
                        <input
                          type="text"
                          value={facilitypouserid}
                          onChange={(e) => setfacilitypouserid(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Authority PWD
                        </label>
                        <input
                          type="text"
                          value={facilitypassuserpwd}
                          onChange={(e) =>
                            setfacilitypassuserpwd(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Country
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilitycountrydata}
                          onChange={(e) =>
                            Setfacilitycountrydata(e.target.value)
                          }
                        >
                          <option>Facility Country</option>

                          {facilitycountry &&
                            facilitycountry.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Region
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilityregiondata}
                          onChange={(e) =>
                            Setfacilityregiondata(e.target.value)
                          }
                        >
                          <option>Facility Region</option>
                          {facilityregion &&
                            facilityregion.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Address1
                        </label>
                        <textarea
                          class="form-control mb-2"
                          value={facilityaddress1}
                          onChange={(e) => setfacilityaddress1(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Address2
                        </label>
                        <textarea
                          class="form-control mb-2"
                          value={facilityaddress2}
                          onChange={(e) => setfacilityaddress2(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Address3
                        </label>
                        <textarea
                          class="form-control mb-2"
                          value={facilityaddress3}
                          onChange={(e) => setfacilityaddress3(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Location
                        </label>
                        <textarea
                          class="form-control mb-2"
                          value={facilitylocation}
                          onChange={(e) => setfacilitylocation(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Currency
                        </label>
                        <select
                          className="form-select  form-select-sm"
                          value={facilitycurrencydata}
                          onChange={(e) =>
                            Setfacilitycurrencydata(e.target.value)
                          }
                        >
                          <option>Facility Currency</option>
                          {facilitycurrency &&
                            facilitycurrency.map((el, i) => (
                              <option key={i} value={el.name}>
                                {el.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">Facility Phone</label>
                        <input
                          type="number"
                          value={facilityphone}
                          onChange={(e) => setfacilityphone(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Facility Point of Contact
                        </label>
                        <input
                          type="text"
                          value={facilitypointcontact}
                          onChange={(e) =>
                            setfacilitypointcontact(e.target.value)
                          }
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12 mt-2">
                      <div className="float-end">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm margin-right-5"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => updateUser(localStorage.getItem("ID"))}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
          </div>
        </section>
      </div>
     
      <Footer />
    </>
  );
};

export default FacilityMasterMaster;
