import React from "react";

const Header1 = () => {
  return (
   <>
    <nav className='navbar navbar-expand-lg navbar-light bg-white shadow-sm container-fluid fix-sticky'>
     <div className='container-fluid'>
      <a className='navbar-brand' href='/blank'>
       <img src='assets/img/logo.svg' className='img-fluid' />
      </a>
      <button
       className='navbar-toggler collapsed'
       type='button'
       data-bs-toggle='collapse'
       data-bs-target='#navbar-content'
      >
       <div className='menu-toggle'>
        <i className='fa fa-bars' aria-hidden='true'></i>
       </div>
      </button>
      <div
       className='collapse navbar-collapse justify-content-center'
       id='navbar-content'
      >
       <ul className='navbar-nav  mb-2 mb-lg-0 ml-2 '>
        <li class='nav-item ml-2'>
         <a class='nav-link' href='/NewEligibility'>
          Eligibility Check
         </a>
        </li>
        <li class='nav-item'>
         <a class='nav-link' href='#'>
          Prior Authorization Submission
         </a>
        </li>
        <li class='nav-item'>
         <a class='nav-link' href='#'>
          Post Authorization Submission
         </a>
        </li>
        <li class='nav-item'>
         <a class='nav-link' href='/dhpo1'>
          Claim Submission
         </a>
        </li>
        <li class='nav-item'>
         <a class='nav-link' href='#'>
          eRX Submission
         </a>
        </li>
       </ul>
      </div>
     </div>
     <span class='border-bottom-3'></span>
    </nav>
   </>
  );
};

export default Header1;
