import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Popover } from "antd";

const content = (
  <>
    <a href="/authorityapi">Authority Api Master</a>
    <br />
    <a href="/authoritydata">Authority Data</a>
    <br />
    <a href="/authoritymaster">Authority Master</a>
    <br />
    <a href="/booleantable">Boolean</a>
    <br />
    <a href="/facilityactivemaster">Facility Active Type Master</a>
    <br />
    <a href="/facilitycategorymaster">Facility Category</a>
    <br />
    <a href="/facilitycountrymaster">Facility Country</a>
    <br />
    <a href="/facilitycurrency">Facility Currency</a>
    <br />
    <a href="/facilitymastermaster">Facility Master</a>
    <br />
    <a href="/facilityregionregion">Facility Region</a>
    <br />
    <a href="/facilitytypemastermaster">Facility Type Master</a>
    <br />
    <a href="/rolemaster">Role Master</a>
    <br />
    <a href="/servicemastermaster">Service Master</a>
  </>
);
const BusinessInsightsContent = (
  <>
    <a href="/financial">Financial</a>
    <br />
    <a href="/operational">Operational</a>
    <br />
    <a href="/rejections">Rejections</a>
    <br />
  </>
);
const Sidebar = () => {
  useEffect(() => {
    const el = document.getElementById("open");
    console.log(el);
  }, []);

  return (
    <div className="sidebar">
      <div className="logo-details">
        <div className="logo_name">User Name</div>

        <i className="bx bx-menu-alt-right" id="btn"></i>
      </div>
      <ul className="nav-list">
        <li>
          <i className="bx bx-search"></i>
          <input type="text" placeholder="Search..." />
          <span className="tooltip">Search</span>
        </li>
        <li>
          <a href="/riyathi">
            <i className="bx bx-grid-alt"></i>
            <span className="links_name">New Claim</span>
          </a>
          <span className="tooltip">Submission</span>
        </li>
        <li>
          <a href="/cv">
            <i className="bx bx-check-double"></i>
            <span className="links_name">Validation</span>
          </a>
          <span className="tooltip">Validation</span>
        </li>
        <li>
          <a href="/searcht">
            <i className="bx bx-transfer"></i>
            <span className="links_name">Search Transaction</span>
          </a>
          <span className="tooltip">Search Transaction</span>
        </li>
        <li>
          <a href="/ig">
            <i className="bx bx-file"></i>
          </a>
          <span className="tooltip">Invoice</span>
        </li>
        <li>
          <a href="/ftm">
            <i className="fa fa-cloud-upload" aria-hidden="true"></i>
            <span className="links_name">Production Upload</span>
          </a>
          <span className="tooltip">Production Upload</span>
        </li>
        <li>
          <span className="tooltip">Business Insights</span>
        </li>
        <li>
          <Popover
            content={BusinessInsightsContent}
            title="BusinessInsights"
            placement="right"
          >
            <i className="fa fa-briefcase"></i>
            <span className="links_name"></span>
          </Popover>
        </li>
        <li>
          <a href="/fcm">
            <i className="fa fa-area-chart" aria-hidden="true"></i>
            <span className="links_name">Analysis</span>
          </a>
          <span className="tooltip">Analysis</span>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-upload"></i>
            <span className="links_name">Downloader</span>
          </a>
          <span className="tooltip">Downloader</span>
        </li>
        <li>
          <Popover content={content} title="Meta Data" placement="right">
            <i className="bx bx-upload"></i>
            <span className="links_name"></span>
          </Popover>
        </li>
        <li className="profile">
          <div className="profile-details">
            <div className="name_job">
              <div className="job">Web designer</div>
            </div>
          </div>
          <i className="bx bx-log-out" id="log_out"></i>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
