/**
 * Respond with highchart data required input properties.
 * @param {*} denialByClinicianData
 */
export function getDenialByClinicianCodeChart(receiverDenialCodeChart) {
  let series = [],
    categories = [];
  console.log("Tamil", receiverDenialCodeChart);
  if (
    receiverDenialCodeChart &&
    receiverDenialCodeChart["Rejection_Clinician_Code_Stack"] &&
    receiverDenialCodeChart["Clinician_Details_Stack"]
  ) {
    let rejectionCodes =
        receiverDenialCodeChart["Rejection_Clinician_Code_Stack"],
      rejectionDetails = receiverDenialCodeChart["Clinician_Details_Stack"];

    categories = rejectionDetails.map((detail) => detail["Clinician_Name"]);

    for (
      let codeItr = 0, len = rejectionCodes.length;
      codeItr < len;
      ++codeItr
    ) {
      let rejectionData = [];
      for (
        let detailItr = 0, detailLen = rejectionDetails.length;
        detailItr < detailLen;
        ++detailItr
      ) {
        if (rejectionCodes[codeItr] in rejectionDetails[detailItr]) {
          rejectionData.push(
            rejectionDetails[detailItr][rejectionCodes[codeItr]]
          );
        } else {
          rejectionData.push(0);
        }
      }
      series.push({
        name: rejectionCodes[codeItr],
        data: rejectionData,
        type: "bar",
        stack: "total",
      });
    }
    console.log("Series", series);
  }

  return {
    series,
    categories,
  };
}
