import React, { useMemo, useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Box, Stack } from "@mui/material";
import MaterialReactTable from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import axios from "axios";
import NumberFormat from "react-number-format";
import "react-multiple-select-dropdown-lite/dist/index.css";
import MultiSelect from "react-multiple-select-dropdown-lite";
import {
  message,
  Button,
  Space,
  Popover,
  Radio,
  Tooltip,
  DatePicker,
} from "antd";
import { FilterTwoTone } from "@ant-design/icons";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import NEWSIDEBAR from "../../layout/newSidebar";
import API_URL from "../../tools/api";

import { ContactSupportOutlined } from "@material-ui/icons";
import { secondsInDay } from "date-fns";

// import Kpis from "../../KPI";

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

const info = () => {
  message.info("Item Added to my Favourites");
};

const ReportClinicianRevenue = () => {
  const [first, setFirst] = useState([]);
  const [kpiData, setKpiData] = useState("");
  const [startFacilityDate, setStartFacilityDate] = useState();
  const [endFacilityDate, setEndFacilityDate] = useState();
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
useEffect(() => {
  const interval = setInterval(() => {
    setProgress((oldProgress) => {
      const newProgress = Math.random() * 20;
      return Math.min(oldProgress + newProgress, 100);
    });
  }, 1000);
  return () => clearInterval(interval);
}, []);

const revenue = useMemo(
  () => first.reduce((acc, curr) => acc + curr.revenue, 0),
  [first]
)
  const columns = [
    {
      header: "facilityGroups",
      accessorKey: "facilityGroups",
      enableGrouping: true, //don't let this column be grouped
    },
    {
      header: "facilityID",
      accessorKey: "facilityID",
    },
    {
      header: "receiverID",
      accessorKey: "receiverID",
      // aggregationFn: 'max', //show the max age in the group (lots of pre-built aggregationFns to choose from)
      //required to render an aggregated cell
      /*  AggregatedCell: ({ cell, table }) => (
        <>
          Oldest by{' '}
          {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
          <Box
            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
          >
            {cell.getValue()}
          </Box>
        </>
      ), */
      /* Footer: () => (
        <Stack>
          Max Age:
          <Box color="warning.main">{Math.round(maxAge)}</Box>
        </Stack>
      ), */
    },
    {
      header: "authority",
      accessorKey: "authority",
      //optionally, customize the cell render when this column is grouped. Make the text blue and pluralize the word
      GroupedCell: ({ cell, row }) => (
        <Box sx={{ color: "primary.main" }}>
          <strong>{cell.getValue()}s </strong> ({row.subRows?.length})
        </Box>
      ),
    },
    {
      header: "treatmentDate",
      accessorKey: "treatmentDate",
    },
    {
      header: "transactionDate",
      accessorKey: "transactionDate",
    },
    {
      header: "activityDesc",
      accessorKey: "activityDesc",
    },
    {
      header: "clinicanName",
      accessorKey: "clinicanName",
    },
    {
      header: "clinicianID",
      accessorKey: "clinicianID",
    },
    {
      header: "receiverDesc",
      accessorKey: "receiverDesc",
    },
    {
      header: "typeDesc",
      accessorKey: "typeDesc",
    },
    {
      header: "serviceYear",
      accessorKey: "serviceYear",
    },
    {
      header: "serviceMonth",
      accessorKey: "serviceMonth",
    },
    {
      header: "Provider",
      accessorKey: "providerDesc",
    },
    {
      header: "revenue",
      accessorKey: "revenue",
      aggregationFn: "sum",
      //required to render an aggregated cell, show the average salary in the group
      AggregatedCell: ({ cell, table }) => (
        <>
          Total{" "}
          {table.getColumn(cell.row.groupingColumnId ?? "").columnDef.header}:{" "}
          <Box sx={{ color: "success.main", fontWeight: "bold" }}>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "AED",
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            })}
          </Box>
        </>
      ),
    
      
      
       Footer: () => (
         <Stack>
           Grand Total:
           <Box color="warning.main">
             {revenue?.toLocaleString?.("en-US", {
               style: "currency",
               currency: "AED",
               minimumFractionDigits: 2,
               maximumFractionDigits: 3,
             })}
           </Box>
         </Stack>
       ),
    },
  ];
  const data1 = async () => {
    const data1 = await axios.post(API_URL + "/report/ReportClinicianRevenue", {
      user_id: localStorage.getItem("user_id"),
      dateType: "S",
    });
    console.log("ReportClinicianRevenue", data1.data.data);
    setFirst(data1.data.data);
    localStorage.setItem("clinicianrevenue", JSON.stringify(data1.data.data));
    setStartFacilityDate(data1.data.Start_date);
    setEndFacilityDate(data1.data.End_date);
  };

  useEffect(() => {
    const first = JSON.parse(localStorage.getItem("clinicianrevenue"));
    if (first) {
      setFirst(first);
    }
  }, []);
  const second = JSON.parse(localStorage.getItem("clinicianrevenue"));

  const DashboardKPI = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      user_id: localStorage.getItem("user_id"),
      dateType: value,
    });

    setKpiData(data.data.DashboardKPI);
  };

  const DashboardKPIFilter = async () => {
    const data = await axios.post(API_URL + "/charts/kpi", {
      startDate: startDate + " " + "00:00",
      endDate: endDate + " " + "23:59",
      dateType: value,
      user_id: localStorage.getItem("user_id"),
      facilityGroups: facilityGroupData,
      facilityIdList: String(facilitySelect).split(","),
      receiverIdList: String(selectedReceiver).split(","),
    });

    setKpiData(data.data.DashboardKPI);
  };
  useEffect(() => {
    data1();
    DashboardKPI();
  }, []);
  var datetime = new Date();
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
   
    filename: ("ClinicianReveneReport".concat(' ', datetime.toISOString().slice(0,10))),
    title: "ClinicianReveneReport",
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(first);
  };

  const averageSalary = useMemo(
    () => first.reduce((acc, curr) => acc + curr.revenue, 0),
    []
  );

  const maxAge = useMemo(
    () => first.reduce((acc, curr) => Math.max(acc, curr.age), 0),
    []
  );

  function subtotal(first) {
    return first.map(({ revenue }) => revenue).reduce((sum, i) => sum + i, 0);
  }

  // console.log("Toal", subtotal(data));

  // Use State for Filter Section starts here // Tamil change ("") to ()
  const [facilitySelect, setFacilitySelect] = useState();
  const [value, setValue] = useState("S");
  const [selectedReceiver, setSelectedReceiver] = useState();
  const [selectedPayer, setSelectedPayer] = useState();
  //useState for DropDown
  const [faciltyGroup, setFacilityGroup] = useState([]);
  const [facilityGroupName, setFacilityGroupName] = useState("");
  const [payer, setPayer] = useState("");
  const [receiver, setReceiver] = useState([]);
  //DropDown Data Change
  const [facilityGroupData, setFacilityGroupData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);

  console.log(startDate);
  console.log(endDate);
  // Use State for Filter Section Ends here

  //Filter Api starts Here
  const text = <span>Filter</span>;

  const companyName = [];
  const facilitylicence = [];

  //Date Picker

  const onOk = (value1) => {
    console.log("onOk: ", value1);
  };

  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const onChangeStartDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setStartDate(dateString);
  };

  const onChangeEndDate = (value, dateString) => {
    //console.log('Selected Time: ', value);
    console.log("Formatted Selected Date: ", dateString);
    setEndDate(dateString);
  };
  //Date Type Radio Button
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const handleChangeFacility = (e) => {
    setFacilityGroupData(e.target.value);
  };

  console.log(facilityGroupData);

  //Multi Select Receiver Data
  var receiverchange = (e) => {
    let CompanyName = e.map((el) => el.value);
    this.setState([CompanyName]);
  };
  const receiverResult =
    receiver &&
    receiver.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  //console.log("result", receiverResult);
  const receiverList = (isActive) =>
    receiverResult &&
    receiverResult.map(
      (el) =>
        el.isActive == true && {
          value: el.Auth_No,
          label: el.Auth_No.concat("---").concat(el.CompanyName),
        }
    );
  let receiverSelectList = receiverList(true);
  console.log("ReceiverSelectList", selectedReceiver);

  const handleOnchangeReceiver = (val) => {
    setSelectedReceiver(val);
  };

  // const payerResult =
  //   payer &&
  //   payer.map(function (el) {
  //     var o = Object.assign({}, el);
  //     o.isActive = true;
  //     return o;
  //   });
  // // console.log("result", payerResult);
  // const payerList = (isActive) =>
  //   payerResult &&
  //   payerResult.map(
  //     (el) =>
  //       el.isActive == true && {
  //         value: el.Auth_No,
  //         label: el.Auth_No.concat("---").concat(el.CompanyName),
  //       }
  //   );
  // let payerSelectList = payerList(true);
  // console.log("PayerSelectList", payerSelectList);

  // const handleOnchangePayer = (val) => {
  //   setSelectedPayer(val);
  // };

  // var facilitychange = (e) => {
  //   let CompanyName = e.map((el) => el.value);
  //   this.setState({ facilitylicence });
  // };
  const facilityResult =
    faciltyGroup &&
    faciltyGroup.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });
  // console.log("result", payerResult);
  const facilityList = (isActive) =>
    facilityResult &&
    facilityResult.map(
      (el) =>
        el.isActive == true && {
          value: el.facilitylicence,
          label: el.facility,
        }
    );
  let facilitySelectList = facilityList(true);
  //console.log("PayerSelectList", payerSelectList);

  const handleOnchangeFacility = (val) => {
    setFacilitySelect(val);
  };
  const FacilityGroups = async () => {
    try {
      const FacilityGroups = await axios.post(
        API_URL + "/filterData/dropDwonFacilityGroups",

        {
          user_id: localStorage.getItem("user_id"),
          //user_id: "1",
          dataType: value,
        }
      );
      console.log("data", FacilityGroups.data.UserFacilityGroups);
      setFacilityGroupName(FacilityGroups.data.UserFacilityGroups);
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };

  const FacilityDropDown = async () => {
    const menu2 = await axios.post(API_URL + "/filterData/dropDwonFacility", {
      //user_id: "1",
      user_id: localStorage.getItem("user_id"),
      dateType: value,
      facilityGroups: facilityGroupData,
    });

    console.log("Menu2", menu2.data.UserFacility);
    setFacilityGroup(menu2.data.UserFacility);
  };

  // const Payer = async () => {
  //   try {
  //     const Payer = await axios.post(
  //       API_URL + "/filterData/dropDwonPayer",

  //       {
  //         payerId: "",
  //         dateType: value,
  //         healthauth: "meta_dhpo",
  //       }
  //     );
  //     // console.log("Payer", Payer.data.Payer);
  //     setPayer(Payer.data.Payer);
  //   } catch (e) {
  //     console.log(e);
  //     console.log("Payer Error");
  //   }
  // };
  const Receiver = async () => {
    const Receiver = await axios.post(
      API_URL + "/filterData/dropDwonReceiver",
      {
        receiverId: "",
        dateType: value,
        healthauth: "",
      }
    );
    console.log("Receiver", Receiver);
    setReceiver(Receiver.data.Receiver);
  };

  //Filter Api Ends Here

  const TableFilter = async () => {
    try {
      const data = await axios.post(
        API_URL + "/report/ReportClinicianRevenue",
        {
          startDate: startDate + " " + "00:00",
          endDate: endDate + " " + "23:59",
          dateType: value,
          user_id: localStorage.getItem("user_id"),
          facilityGroups: facilityGroupData,
          facilityIdList: String(facilitySelect).split(","),
          receiverIdList: String(selectedReceiver).split(","),
          payerIdList: String(selectedPayer).split(","),
        }
      );
      console.log("TableFilter", data.data);
      setFirst(data.data.data);
      setStartFacilityDate(data.data.Start_date);
      setEndFacilityDate(data.data.End_date);
      message.success("Filter Applied Successfully");
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };
  
  // Dashboard Api Ends Here

  useEffect(() => {
    FacilityGroups();
    // Payer();
    Receiver();
    //ChartFilter();
  }, []);

  // Filter Section Content Starts Here
  const content = (
    <Space direction="vertical">
      <div className="card-header p-0">
        <div className="row">
          <div className="col-md-6">
            <div class="form-group mb-8 ">
              <label class="form-label">Facility Group</label>

              <select
                value={facilityGroupData}
                multiple=""
                class="form-select form-select-sm"
                onChange={handleChangeFacility}
                onClick={FacilityDropDown}
              >
                <option></option>
                {facilityGroupName &&
                  facilityGroupName.map((el, i) => (
                    <option key={i} value={el.facilitygroupsname}>
                      {el.facilitygroupsname}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group mb-2 ">
              <label class="form-label">Facility</label>
              {/* <select
                value={facilitySelect}
                multiple=""
                class="form-select form-select-sm"
                onChange={(e) => setFacilitySelect(e.target.value)}
              >
                <option></option>
                {faciltyGroup &&
                  faciltyGroup.map((el, i) => (
                    <option key={i} value={el.facilitylicence}>
                      {el.facilitylicence}
                    </option>
                  ))}
              </select> */}
              <MultiSelect
                onChange={handleOnchangeFacility}
                options={facilitySelectList}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">Start Date</label>
              <Space direction="vertical">
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={startDate}
                  onChange={onChangeStartDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2">
              <label className="form-label">End Date</label>
              <Space direction="vertical" size={3}>
                <DatePicker
                  format="DD/MM/YYYY"
                  selected={endDate}
                  onChange={onChangeEndDate}
                />
              </Space>
            </div>
          </div>

          <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label col-lg-12">Date Type</label>

              <Radio.Group onChange={onChangeRadio} value={value}>
                <Radio value={"S"}>Service</Radio>
                <Radio value={"T"}>Transcation</Radio>
              {/*   <Radio value={"R"}>RA</Radio>
                <Radio value={"P"}>Settlement</Radio> */}
              </Radio.Group>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label">Select Payer</label>
              <MultiSelect
                onChange={handleOnchangePayer}
                options={payerSelectList}
                class="form-select form-select-sm"
              />
            </div>
          </div> */}

          <div className="">
            <div class="form-group mb-2">
              <label class="form-label">Select Receiver</label>
              <MultiSelect
                onChange={handleOnchangeReceiver}
                options={receiverSelectList}
              />
            </div>
          </div>
         {/*  <div className="col-md-12">
            <div class="form-group mb-2">
              <label class="form-label">Traffic Type</label>
              <select multiple="" class="form-select" id="exampleSelect2">
                <option>OP</option>
                <option>IP</option>
                <option>DC</option>
              </select>
            </div>
          </div> */}
        </div>
      </div>

      <div className="clearfix"></div>
      <div className="col-md-12">
        <div className="float-end">
          <Button
            //onClick={data}
            onClick={() => {
              //data();
              hide();
            }}
            style={{
              marginRight: "10px",
            }}
            type="dashed"
            danger
          >
            Cancel Filter
          </Button>

          <Button
            onClick={() => {
              TableFilter();
              hide();
              DashboardKPIFilter();
            }}
            type="primary"
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </Space>
  );

  // Filter Section Content Ends Here

  return (
    <>
     
      <div className="home-section">
        <Header />
        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
            <section className="dashboard">
              {/* Kpis Sections Starts */}
              <div className="row  kpi  font-size-6">
                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-exclamation-circle"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          <NumberFormat
                            //value={archartData.DashboardKPI[0].Traffic}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"Avg/Month "}
                          />
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Traffic}
                          displayType={"text"}
                          prefix={"# "}
                          thousandSeparator={true}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Revenue
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-usd"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Revenue}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Approved
                      <span className="float-end  text-success">
                        <span className="h6 fa fa-check-square-o"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Paid}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Processed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Rejected
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-ban"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Rejection}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Rejected}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Unsettled
                      <span className="float-end  text-warning">
                        <span className="h6 fa fa-balance-scale"></span>
                        <span className=" mb-6 margin-left-3 pt-2 font-weight-5">
                          % :{kpiData && kpiData[0].Per_Unprocessed}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].Unprocessed}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Traffic Type
                      <span className="float-end text-danger">
                        <span className="h6 fa fa-warning"></span>
                        <span className="mb-6 margin-left-3 pt-2 font-weight-5">
                          DC#:{kpiData && kpiData[0].Traffic_DAYCARE}
                        </span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        OP# : {kpiData && kpiData[0].Traffic_OP}
                        <span className="padding-left-5">
                          IP# : {kpiData && kpiData[0].Traffic_IP}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Avg Sub TAT
                      <span className="float-end text-success">
                        <span className="h6 fa fa-line-chart"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].AVG_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"# "}
                        />
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-2 col-md-3 col-sm-6">
                  <div class="card mb-3">
                    <div class="card-header p-1 font-weight-5">
                      Max Sub TAT
                      <span className="float-end  text-primary">
                        <span className="h6 fa fa-tachometer"></span>
                      </span>
                    </div>
                    <div class="card-body p-1">
                      <h5 class="card-title">
                        <NumberFormat
                          value={kpiData && kpiData[0].MAX_SUB_TAT}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"#"}
                        />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Kpis Sections Ends */}
              {/* Facility Card Board */}
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card mb-3">
                    <div className="card-body p-0 font-size-6">
                      <div className="status lh-1">
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Start Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b> {startFacilityDate}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>End Date:</b>
                          </label>
                          <label class="form-check-label">
                            <b>{endFacilityDate}</b>
                          </label>
                        </div>
                        <div class="form-check form-check-inline padding-left-5">
                          <label class="col-form-label margin-right-5">
                            <b>Facility Group:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b>{facilityGroupData}</b>
                            {/* {facilityData &&
                        facilityData.FacilitiesGroup[1].facilitygroupsname} */}
                          </label>
                        </div>
                        <div className="form-check form-check-inline padding-left-5">
                          <a class="btn btn btn-sm btn-white margin-right-10">
                            <span class="blink text-warning">
                              <b>Reports: **Excludes ReSubmission Variances</b>
                            </span>
                          </a>
                        </div>

                        <div class="form-check form-check-inline padding-left-5 float-end m-0 py-1 px-1">
                          {/* Filter Section Starts Here */}

                          <>
                            <div className="ant-filter">
                              <div
                                style={{
                                  clear: "both",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div className="col-lg-3">
                                  <Tooltip placement="bottom" title="Refresh">
                                    <a
                                      href="/dashboard"
                                      className="btn btn btn-sm btn-white border-0"
                                    >
                                      <i
                                        class="fa fa-refresh fa-spin"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </Tooltip>
                                  <Popover
                                    placement="bottom"
                                    overlayClassName="col-lg-4 shadow-sm"
                                    title={text}
                                    content={content}
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                  >
                                    <Tooltip placement="bottom" title={text}>
                                      <Button
                                        className="border-0"
                                        icon={<FilterTwoTone />}
                                      ></Button>
                                    </Tooltip>
                                  </Popover>
                                </div>
                              </div>
                            </div>
                          </>
                          {/* Filter Section Ends Here */}
                        </div>
                        <div class="form-check form-check-inline padding-left-5 float-end">
                          <label class="col-form-label margin-right-5">
                            <b>Facility:</b>
                          </label>
                          <label class="form-check-label margin-right-5">
                            <b> {facilitySelect}</b>
                          </label>
                          <label class="form-check-label"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Facility Card board Ends */}

              <div className="col-lg-12">
                <section className="dashboard AuthorityApimaster">
                  <div className="card border-light mb-4">
                    <div className="card-header d-flex justify-content-between p-2">
                      <h4 className="card-title mx-2 mt-3 text-info font-font-size-10">
                        Clinician Revenue Report
                      </h4>
                      {/* <Button type="primary" onClick={info}>
                        Add to my Favourites
                      </Button> */}
                      <div class="float-end"></div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <MaterialReactTable
                          columns={columns}

                          muiTableProps={{
                            sx: {
                              tableLayout: 'auto',
                            },
                          }}
                          state={{ isLoading: true }}
                          data={first ?? []} //fallback to array if data is undefined
                          muiLinearProgressProps={({ isTopToolbar }) => ({
                            color: 'secondary',
                            variant: 'determinate', //if you want to show exact progress value
                            value: progress, //value between 0 and 100
                            sx: {
                              display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
                            },
                          })}
                          enableGrouping
                          enablePinning
                          enableColumnOrdering
                          enableClickToCopy
                          positionToolbarAlertBanner="bottom"
                          renderTopToolbarCustomActions={({ table }) => (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "2rem",
                                p: "0.5rem",
                                flexWrap: "wrap",
                              }}
                            >
                              <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export All Data
                              </Button>
                             {/*  <Button
                                disabled={
                                  table.getPrePaginationRowModel().rows
                                    .length === 0
                                }
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleExportRows(
                                    table.getPrePaginationRowModel().rows
                                  )
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export All Rows
                              </Button>
                              <Button
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() =>
                                  handleExportRows(table.getRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Page Rows
                              </Button>
                              <Button
                                disabled={
                                  !table.getIsSomeRowsSelected() &&
                                  !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() =>
                                  handleExportRows(
                                    table.getSelectedRowModel().rows
                                  )
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Selected Rows
                               </Button>
                              */}
                            </Box>
                          )}
                          state={{ showProgressBars: true }}
                          initialState={{
                            density: "compact",
                            //expanded: true, //expand all groups by default
                            grouping: [
                             
                             ,
                              
                            ], //an array of columns to group by by default (can be multiple)
                            pagination: { pageIndex: 0, pageSize: 20 },
                            columnPinning: {
                              left: [
                                "clinicianID",
                              ],
                              //right: ["revenue"],
                            },
                            sorting: [{ id: "revenue", desc: true }], //sort by state by default

                            columnVisibility: {
                              treatmentDate: false,
                              transactionDate: false,
                              receiverID: false,
                              activityDesc: false,
                              receiverDesc: false,
                              typeDesc: false,
                              receiverID: false,
                            },
                          }}
                          muiToolbarAlertBannerChipProps={{ color: "primary" }}
                          muiTableContainerProps={{ sx: { maxHeight: 700 } }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default ReportClinicianRevenue;
