import React, { useState } from "react";
import { Input, Select, Form, Button } from "antd";
const FormThree = () => {
  const { Option } = Select;
  return (
    <div className="row m-2">
      <fieldset className="form-group border mb-0 m-2">
        <legend className="w-auto px-2">Diagnosis</legend>
        <div className="row">
          <div className="col-md-4">
            <Form.Item
              name="Type"
              label="Type"
              className="mb-0 gender"
              rules={[
                { required: true, message: "Please Select Your Option " },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
              >
                <Option value=""></Option>
                <Option value=""></Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item
              name="Code"
              label="Code"
              className="mb-0 gender"
              rules={[
                { required: true, message: "Please Select Your Option " },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
              >
                <Option value=""></Option>
                <Option value=""></Option>
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-4">
            <Form.Item
              name="DX Information Code"
              label="DX Information Code"
              className="mb-0 gender"
              rules={[
                { required: true, message: "Please Select Your Option " },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                // defaultOption='Select'
                placeholder="Select"
              >
                <Option value="Yes">
                  Yes-Present at the time of inPatient Admission
                </Option>
                <Option value="No">
                  No-Not Present at the time of inPatient Admission
                </Option>
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-4">
            <Form.Item
              name="DX Information Type"
              label="DX Information Type"
              className="mb-0 gender"
              rules={[
                { required: true, message: "Please Select Your Option " },
              ]}
            >
              <Input
                type="text"
                disabled
                className="form-control form-control-sm mb-2"
              />
            </Form.Item>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <div className="float-end">
            <Button
              type="primary"
              style={{
                marginBottom: "10px",
              }}
            >
              ADD
            </Button>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default FormThree;
