import { INIT, LOADING, SUCCESS, ERROR } from "../utils/asyncStatusHelper";
import {
  SESSION_USER_SESSION_REQUEST,
  SESSION_USER_SESSION_SUCCESS,
  SESSION_USER_SESSION_ERROR,
  SESSION_LOGIN_USER_REQUEST,
  SESSION_LOGIN_USER_SUCCESS,
  SESSION_LOGIN_USER_ERROR,
  SESSION_LOGOUT_USER_SUCCESS,
  SESSION_SIGNUP_USER_REQUEST,
  SESSION_SIGNUP_USER_SUCCESS,
  SESSION_SIGNUP_USER_ERROR,
} from "../actions/sessionAction";

const initialState = {
  sessionAsyncStatus: INIT,
  loginAsyncStatus: INIT,
  loggedIn: false,
  userSession: null,
  alert: {
    color: SUCCESS,
    show: true,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SESSION_LOGIN_USER_REQUEST:
      return {
        ...state,
        loginAsyncStatus: LOADING,
      };
    case SESSION_LOGIN_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userSession: payload,
        sessionAsyncStatus: SUCCESS,
        loginAsyncStatus: SUCCESS,
      };
    case SESSION_LOGIN_USER_ERROR:
      return {
        ...state,
        loggedIn: false,
        userSession: null,
        loginAsyncStatus: ERROR,
      };
    case SESSION_USER_SESSION_REQUEST:
      return {
        ...state,
        sessionAsyncStatus: LOADING,
      };
    case SESSION_USER_SESSION_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userSession: payload,
        sessionAsyncStatus: SUCCESS,
      };
    case SESSION_USER_SESSION_ERROR:
      return {
        ...state,
        loggedIn: false,
        userSession: null,
        sessionAsyncStatus: ERROR,
      };
    case SESSION_LOGOUT_USER_SUCCESS:
      return {
        ...initialState,
      };

    case SESSION_SIGNUP_USER_REQUEST:
      return {
        ...state,
      };
    case SESSION_SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userSession: payload,
        sessionAsyncStatus: SUCCESS,
        loginAsyncStatus: SUCCESS,
      };
    case SESSION_SIGNUP_USER_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};
