import React, { useMemo,useState ,useEffect} from "react";
import {
  Input,
  DatePicker,
  Select,
  InputNumber,
  Button,
  message,
  Upload,
  Form,
  SelectProps,
  Space,
} from "antd";
import dayjs from "dayjs";
import type { RangePickerProps } from 'antd/es/date-picker';
import { UploadOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import API_URL from "../../../tools/api";
import axios from "axios";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const FormOne = () => {
  
  const [selectedReceiver, setSelectedReceiver] = useState();
  const [receiver, setReceiver] = useState([]);
  const [value, setValue] = useState("S");
  const [date, setDate] = useState();
  const [dob, SetDob] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [invoiceDate, setInvoiceDate] = useState();
  const [claimIDUser, setClaimID] = useState();
  const [orderingClinicianID, setorderingClinicianID] = useState();
  const [firstName, setFirstName] = useState();
  const [middleName, setMiddleName] = useState();
  const [lastName, setLastName] = useState();
  const [patientID, setPatientID] = useState();
  const [eHealthID, setEHealthID] = useState();
  const [nationality, setNationality] = useState();
  const [residentType, setResidentType] = useState();
  const [documentType, setDocumentType] = useState();
  const [documentID, setDocumentID] = useState();
  const [contactNo, setContactNo] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [gender, setGender] = useState();
  const [bloodGroup, setBloodGroup] = useState();
  const [preferedLanguage, setPreferedLanguage] = useState();
  const [ePhoneNumber, setEPhoneNumber] = useState();
  const [email, SetEmail] = useState();
  const [values, setvalues] = useState();
  const [showhide, setShowhide] = useState("");
  const [insurancePlan, setInsurancePlan] = useState();
  const [memberCardID, setMemberCardID] = useState();
  const [notes, setNotes] = useState();
  const [memberID, setMemberID] = useState();
  const [receiverID, setReceiverID] = useState([]);
  const [planName, setPlanName] = useState();
  const [payer, setPayer] = useState();
  const [payerID, setPayerID] = useState();
  const [facilityDetails, setFacilityDetails] = useState();
  const [plan, setPlan] = useState();
  const [createdClaimId, setCreatedClaimID] = useState(null);
  
  
  
  const { RangePicker } = DatePicker;


  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  function getTimestampInSeconds () {
    return Math.floor(Date.now() / 1000)
  }
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };
 
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const { Option } = Select;
  const onChangeDOR = (value, dateString) => {
    setDate(dateString);
  };
  console.log("DOR", date);

  const onChangeInvoiceDate = (value, dateString) => {
    setInvoiceDate(dateString);
  };
  console.log("invoicedate", invoiceDate);

  

  const onChangeDOB = (value, dateString) => {
    SetDob(dateString);
  };
  console.log("DOB", dob);

  const onChangeExpiry = (value, dateString) => {
  setExpiryDate(dateString);
  //localStorage.removeItem("User_ClaimID");
  localStorage.setItem("User_ClaimID",("Sub".concat("_",localStorage.getItem("User_Faciliity")).concat("_",receiverID)).concat("_",planName).concat("_",uniqueNumber))
  setCreatedClaimID(localStorage.getItem("User_ClaimID"));
  //localStorage.setItem("User_ClaimID",("Sub".concat("_",localStorage.getItem("User_Faciliity")).concat("_",receiverID)).concat("_",planName).concat("_",uniqueNumber))
  //setCreatedClaimID(localStorage.getItem("User_ClaimID"));
    //setClaimID(("Sub".concat("_",localStorage.getItem("User_Faciliity")).concat("_",receiverID[0])).concat("_",planName).concat("_",uniqueNumber))

      
  };
  

  
  const handleshowhide = (value) => {
    const getuser = value;
    setShowhide(getuser);
  };
  const onFinish = (values) => {
    console.log(values);
  };

var mauthority ="";
  console.log("MemberID",memberID);
  console.log("Receiver ID", receiverID);
  console.log("Payer ID", payerID);
  if (localStorage.getItem("User_Authority") == 'tmb')
  {
    mauthority = "riayathi";
  }
  console.log(mauthority,"Authority");
  console.log("PlanName",planName);
  console.log("ExpiryDate",expiryDate);
  console.log("InvoiceDate",invoiceDate);

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
 
 
  var receiverchange = (e) => {
    let CompanyName = e.map((el) => el.value);
    this.setState([CompanyName]);
  };
  const receiverResult =
    receiver &&
    receiver.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });

    const receiverList = (isActive) =>
    receiverResult &&
    receiverResult.map(
      (el) =>
        el.isActive == true && {
          value: el.Auth_No,
          label: el.Auth_No.concat("---").concat(el.CompanyName),
        }
    );
  let receiverSelectList = receiverList(true);
  
  const Receiver = async () => {
    const Receiver = await axios.post(
      API_URL + "/filterData/dropDwonReceiver",
      {
        receiverId: "",
        dateType: value,
        healthauth: "",
      }
    );
    console.log("Receiver", Receiver);
    setReceiver(Receiver.data.Receiver);
  };


  const payerResult =
     payer &&
     payer.map(function (el) {
       var o = Object.assign({}, el);
       o.isActive = true;
       return o;
     });

 const Payer = async () => {
     
       const Payer = await axios.post(
        API_URL + "/filterData/dropDwonPayer",
       {
           payerId: "",
           dateType: value,
           healthauth: "",
         }
       );
   console.log("Payer", Payer.data.Payer);
      setPayer(Payer.data.Payer);
     
  };

const PlanDetails = async () => {
  const PlanDetails = await axios.post(API_URL + "/claimMgt/cyclemContractPlan", {
      facilityId :localStorage.getItem("User_Faciliity"),
      receiverIdList: String(receiverID).split(","),
      authority:mauthority,
      dateType:"S",
    
  });
  setPlan(PlanDetails.data);
};

const planResult =
     plan &&
     plan.map(function (el) {
       var o = Object.assign({}, el);
       o.isActive = true;
       return o;
     });

  const FacilityDetails = async () => {
    const Data5 = await axios.post(API_URL + "/claimMgt/cyclemUserFacilityDetails", {
        facilityId :localStorage.getItem("User_Faciliity"),
      
    });
    setFacilityDetails(Data5.data);
    };

  const facilityDetailsResult =
  facilityDetails &&
  facilityDetails.map(function (el) {
      var o = Object.assign({}, el);
      o.isActive = true;
      return o;
    });

    console.log("xxxxxxxxxx",claimIDUser);
    

   /*  {claimIDUser.map(item => (
      console.log("xxxx",{item})
   ))}; */
    
const myheader= (facilityDetailsResult && facilityDetailsResult[0].facilitySelection);

  useEffect(() => {
   Payer();
    Receiver();
    FacilityDetails();
    
  }, []);

  //
  var uniqueNumber = new Date().getTime();
 console.log(localStorage.getItem("User_Faciliity"),"User_Faciliity");
 console.log(receiverID,"receiverID");
 console.log("PlanName",planName);
 

  
  
  
  console.log("Taml",localStorage.getItem("User_ClaimID"));
  
 
  
  return (
    <div className="row m-2">
    <Form layout='inline'>
      <fieldset className ="form-group border mb-0 p-2">
      <legend className="w-auto px-1 mb-0">Insurance Details</legend>
      
          <div className="row">
      <div className="col-md-2">
              <Form.Item
                name="Member ID"
                label="Member ID"
                className="mb-0 gender"
               
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setMemberID(e.target.value);
                  }}
                  placeholder="Member ID"
                 // required
                />
              </Form.Item>
            </div>   
            <div className="col-md-5">
              <Form.Item
                name="Receiver ID"
                label="Receiver ID"
                className="mb-0 gender"
               
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                  <Select
                          style={{
                            width: "100%",
                          }}
                          showSearch
                         // mode="multiple"
                          placeholder="Select"
                          onChange={(value) => {
                            setReceiverID(value);
                            
                          }
                         }
                        >
                          <Option></Option>
                          {receiver &&
                            receiver.map((el, i) => (
                              <option key={i} value={el.Auth_No}>
                                {el.Auth_No.concat("---").concat(el.CompanyName)}
                              </option>
                            ))}
                             
                        </Select>
              </Form.Item>
            </div>
            <div className="col-md-5">
              <Form.Item
                name="Payer"
                label="Payer"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                 // mode="multiple"
                  placeholder="Select"
                  onChange={(value) => {
                    setPayerID(value);
                    PlanDetails();
                  }}
                >
                  <Option></Option>
                  {payer &&
                            payer.map((el, i) => (
                              <option key={i} value={el.Auth_No}>
                                {el.Auth_No.concat("---").concat(el.CompanyName)}
                              </option>
                            ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-2">
              <Form.Item
                name="Plan Name"
                label="Plan Name"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  placeholder="Select"
                  onChange={(value) => {
                    setPlanName(value);
                   
                  }}
                >
                    <Option></Option>
                    {plan &&
                            plan.map((el, i) => (
                              <option key={i} value={el.Plan}>
                                {el.Plan}
                              </option>
                            ))}
                </Select>
              </Form.Item>
            </div>
            
            <div className="col-md-2">
              <label className="col-form-label">Expiry Date</label>
              <DatePicker
                style={{
                  width: "100%",
                }}
                showTime={{ format: 'HH:mm' }}
                 
                format="YYYY-MM-DD HH:mm"
                 className="form-control"
                onChange={onChangeExpiry}
                

              />
            </div>
           

            <div className ="form-group  mb- p-2"> 
          
            <legend className="w-auto px-1 mb-0">Patient Details</legend>
           
           
       
          <div className="row mb-0">
            <div className="col-md-2">
              <Form.Item
                name="Invoice Date"
                label="Invoice Date"
                className="form-control"
                className="mb-0 gender"
                rules={[
                  { required: true, },
                ]}
              >
                <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                  style={{
                    width: "100%",
                  }}
                  showTime={{ format: 'HH:mm' }}
                  required
                  onChange={onChangeInvoiceDate}
                />
              </Form.Item>
            </div>
         
            <div className="col-md-2">
              <Form.Item
                name="ClaimID"
                label="ClaimID"
                className="mb-0 gender"
               
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <Input     
                  //  defaultValue={localStorage.getItem("User_ClaimID").toString}
                    onChange={(e) => {
                      setClaimID(e.target.value);
                    

                   }}  
                 />
                
              </Form.Item>
            </div>
            
            <div className="col-md-3 pr-5">
              <Form.Item
                name="Ordering Clinician ID"
                label="Ordering Clinician ID"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <Input
                  placeholder="Ordering Clinician ID"
                  onChange={(e) => {
                    setorderingClinicianID(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div className="col-md-2">
              <Form.Item
                name="Submmission Type"
                label="Submission Type"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please input your Option" },
                ]}
              >
                <Select
                  onChange={(e) => handleshowhide(e)}
                  onSelect={(value) => setvalues(value)}
                  defaultValue="Submission"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Submmission Type"
                
                >

                <Option value="Submission">Submission</Option>
                <Option value="ReSubmission">ReSubmission</Option>
                <Option value="Prescription">Prescription</Option>

                </Select>
                </Form.Item>
            </div>
            <div className="col-md-3">
              <Form.Item
                name="Dispensed ID"
                label="Dispensed ID"
                className="mb-0 gender"
              
              >
                <Input
                  placeholder="Dispensed ID"
                  /* onChange={(e) => {
                    setClaimID(e.target.value);
                  }} */
                />
              </Form.Item>
            </div>
            </div>
          </div>
          
       
       
        
          <div className="row">
            <Form.Item
              name="Patient Name"
              label="Patient Name"
              className="mb-0 gender"
              rules={[
                { required: true, message: "Please Select Your Option " },
              ]}
            ></Form.Item>
            <div className="col-md-4">
              <Input
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="col-md-4">
              <Input
                placeholder="Middle Name"
                onChange={(e) => {
                  setMiddleName(e.target.value);
                }}
              />
            </div>

            <div className="col-md-4">
              <Input
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <label className="col-form-label">Registration Date</label>
              <DatePicker
                style={{
                  width: "100%",
                }}
                showTime={{ format: 'HH:mm' }}
                 
                format="YYYY-MM-DD HH:mm"
                 className="form-control"
                onChange={onChangeDOR}
               
              />
            </div>
            <div className="col-md-3">
              <Form.Item
                name="Patient ID"
                label="Patient ID"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <Input
                  placeholder="Patient ID"
                  onChange={(e) => {
                    setPatientID(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div className="col-md-2">
              <Form.Item
                name="Date of Birth"
                label="Date of Birth"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  showTime={{ format: 'HH:mm' }}
                 
                  format="YYYY-MM-DD HH:mm"
                   className="form-control"
                  onChange={onChangeDOB}
                  
                />
              </Form.Item>
            </div>
            <div className="col-md-2">
              <Form.Item
                name="Age-Month"
                label="Age-Month"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
               <InputNumber
                  style={{
                    width: "100%",
                  }}
                  min={0.00}
                  max={100.00}
                  //onChange={onChangeAge}
                  //format="00.00"
                />
              </Form.Item>
            </div>
            <div className="col-md-3">
              <label className="col-form-label">E-Health Id</label>
              <Input
                placeholder="E-Health Id"
                onChange={(e) => {
                  setEHealthID(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <label className="col-form-label">Nationality</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                onChange={(value) => {
                  setNationality(value);
                }}
              >
                <Option value="Emirati Nationality">Emirati Nationality</Option>
                <Option value="Others">Others</Option>
              </Select>
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Residency Type</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                onChange={(value) => {
                  setResidentType(value);
                }}
              >
                <Option value="Eligibility">Eligibility</Option>
                <Option value="Cancel">Cancel</Option>
              </Select>
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Docment Type</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                onChange={(value) => {
                  setDocumentType(value);
                }}
              >
                <Option value="Eligibility">Eligibility</Option>
                <Option value="Cancel">Cancel</Option>
              </Select>
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Document ID</label>
              <Input
                placeholder="Document ID"
                onChange={(e) => {
                  setDocumentID(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <label className="col-form-label">Contact Number</label>
              <Input
                onChange={(e) => {
                  setContactNo(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Marital Status</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                onChange={(value) => {
                  setMaritalStatus(value);
                }}
              >
                <Option value="Married">Married</Option>
                <Option value="UnMarried">UnMarried</Option>
              </Select>
            </div>
            <div className="col-md-3">
              <Form.Item
                name="Gender"
                label="Gender"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  onChange={(value) => {
                    setGender(value);
                  }}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">FeMale</Option>
                  <Option value="Others">Others</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Blood Group</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                onChange={(value) => {
                  setBloodGroup(value);
                }}
              >
                <Option value="A+">A RhD positive (A+)</Option>
                <Option value="A-">A RhD negative (A-)</Option>
                <Option value="B+">B RhD positive (B+)</Option>
                <Option value="B-">B RhD negative (B-)</Option>
                <Option value="O+">O RhD positive (O+)</Option>
                <Option value="O-">O RhD negative (O-)</Option>
                <Option value="AB+">AB RhD positive (AB+)</Option>
                <Option value="AB-">AB RhD negative (AB-)</Option>
              </Select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <label className="col-form-label">Preferred Language</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                onChange={(value) => {
                  setPreferedLanguage(value);
                }}
              >
                <Option value="English">English</Option>
                <Option value="Arabic">Arabic</Option>
              </Select>
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Emergency Phone Number</label>
              <Input
                onChange={(e) => {
                  setEPhoneNumber(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className="col-md-3">
              <label className="col-form-label">Email</label>
              <Input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                onKeyPress="emailVerify(this.id)"
                onChange={(e) => {
                  SetEmail(e.target.value);
                }}
              ></Input>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Form.Item
                name="Weight"
                label="Weight"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please Select Your Option " },
                ]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  min={15}
                  max={150}
                  //onChange={onChangeWeight}
                />
              </Form.Item>
            </div>

             <div className="col-md-3">
              <Form.Item
                name="Emirates ID"
                label="Emirates ID"
                className="mb-0 gender"
                rules={[
                  { required: true, message: "Please input your Option" },
                ]}
              >
                <Select
                  onChange={(e) => handleshowhide(e)}
                  onSelect={(value) => setvalues(value)}
                  defaultValue="Citizen"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Emirates ID"
                >
                  <Option
                    value="000-0000-0000000-0"
                  >
                    A citizen without a card
                  </Option>
                  <Option
                    value="111-1111-1111111-1"
                  >
                    Expatriate resident without a card
                  </Option>
                  <Option
                    value="222-2222-2222222-2"
                  >
                    Non-national, non-expat resident without a card
                  </Option>
                  <Option
                    value="999-9999-9999999-9"
                  >
                    Unknown status, without a card
                  </Option>
                  <Option>Citizen</Option>
                </Select>
              </Form.Item>
            </div> 
            {/*showhide === "" && (*/}
              <div className="col-md-3">
                <Form.Item
                  name="Enter the Emirates No"
                  label="Enter the Emirates No"
                  className="mb-0 gender"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Emirates No!",
                    },
                  ]}
                  >
                  <NumberFormat
                    format="###-####-#######-#"
                    className="form-control"
                    
                    onChange={(e) => {
                      setvalues(e.target.value);
                    }}
                    />
                  
                
                  
                    
                  
                </Form.Item>
              </div>
              
            {/*})*/} 
          </div>
          
          
          
            <div className="col-md-12">
                <label className="col-form-label pt-0">Notes If Any</label>
                <Input.TextArea
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              
            </div>     
            </div>
            <Form.Item wrapperCol={{ offset: 32, span: 16 }}>
        <Button type="primary" className="my-3" htmlType="submit">
          Submit
        </Button>
      </Form.Item> 
       {/*  <fieldset className="form-group border mb-2 p-2">
          <legend className="w-auto px-2 mb-0">Address DETAILS</legend>
           <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label pt-0">Attachment File</label>
                <div className="input-group">
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="well"></div>
            </div>
          </div> 

          <div className="row">
            <div className="col-md-4">
              <label className="col-form-label">Insurance Plan</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                onChange={(value) => {
                  setInsurancePlan(value);
                }}
              >
                <Option value="001">001</Option>
                <Option value="001">002</Option>
              </Select>
            </div>
            <div className="col-md-4">
              <label className="col-form-label">Expiry Date</label>
              <DatePicker
                style={{
                  width: "100%",
                }}
                onChange={onChangeExpiry}
                format="DD/MM/YYYY"
              />
            </div>
            <div className="col-md-4">
              <label className="col-form-label">Member Card ID</label>
              <Input
                onChange={(e) => {
                  setMemberCardID(e.target.value);
                }}
                placeholder="Member Card ID"
              />
            </div>
          </div>
          
        </fieldset> */}

        
         
          
          
         
<div className='card-body p-0'>
       
        <div class='table-responsive'>
         <table class='table border mb-2'>
          <thead>
           <tr>
            <th>
             <input type='checkbox' />
            </th>
            <th>Invoice Date</th>
            <th>Claim Id</th>
            <th>Ordering Clinician Id</th>
            <th>Submmission Type</th>
            <th>Patient ID</th>
            <th>Date of Birth</th>
			      <th>Gender</th>
			      <th>Email</th>
			      <th>Weight</th>
			      <th>Emirates ID</th>
			      <th>Enter the Emirates No</th>
			      <th>Member ID</th>
			      <th>Receiver ID</th>
			      <th>Payer</th>
			      <th>Plan Name</th>
			      <th>Expiry Date</th>
            <th>Action</th>
           </tr>
          </thead>
          <tbody>
           <tr>
            <td>
             <input type='checkbox' />
            </td>
            <td>Invoice Date</td>
					<td>Claim Id</td>
					<td>Ordering Clinician Id</td>
					<td>Submmission Type</td>
					<td>Patient ID</td>
					<td>Date of Birtd</td>
			      <td>Gender</td>
			      <td>Email</td>
			      <td>Weight</td>
			      <td>Emirates ID</td>
			      <td>Enter tde Emirates No</td>
			      <td>Member ID</td>
			      <td>Receiver ID</td>
			      <td>Payer</td>
			      <td>Plan Name</td>
			      <td>Expiry Date</td>
            <td>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-eye fa-lg ml text-primary mx-1' title='View'></i>
             </a>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-edit fa-lg ml text-info mx-1' title='Edit'></i>
             </a>{' '}
             <a href='#'>
              <i class='fa fa-trash-o fa-lg text-danger' title='Delete'></i>
             </a>
            </td>
           </tr>
           <tr>
            <td>
             <input type='checkbox' />
            </td>
            <td>Invoice Date</td>
					<td>Claim Id</td>
					<td>Ordering Clinician Id</td>
					<td>Submmission Type</td>
					<td>Patient ID</td>
					<td>Date of Birtd</td>
			      <td>Gender</td>
			      <td>Email</td>
			      <td>Weight</td>
			      <td>Emirates ID</td>
			      <td>Enter tde Emirates No</td>
			      <td>Member ID</td>
			      <td>Receiver ID</td>
			      <td>Payer</td>
			      <td>Plan Name</td>
			      <td>Expiry Date</td>
            <td>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-eye fa-lg ml text-primary mx-1' title='View'></i>
             </a>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-edit fa-lg ml text-info mx-1' title='Edit'></i>
             </a>{' '}
             <a href='#'>
              <i class='fa fa-trash-o fa-lg text-danger' title='Delete'></i>
             </a>
            </td>
           </tr>
           <tr>
            <td>
             <input type='checkbox' />
            </td>

            <td>Invoice Date</td>
					<td>Claim Id</td>
					<td>Ordering Clinician Id</td>
					<td>Submmission Type</td>
					<td>Patient ID</td>
					<td>Date of Birtd</td>
			      <td>Gender</td>
			      <td>Email</td>
			      <td>Weight</td>
			      <td>Emirates ID</td>
			      <td>Enter tde Emirates No</td>
			      <td>Member ID</td>
			      <td>Receiver ID</td>
			      <td>Payer</td>
			      <td>Plan Name</td>
			      <td>Expiry Date</td>
            <td>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-eye fa-lg ml text-primary mx-1' title='View'></i>
             </a>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-edit fa-lg ml text-info mx-1' title='Edit'></i>
             </a>{' '}
             <a href='#'>
              <i class='fa fa-trash-o fa-lg text-danger' title='Delete'></i>
             </a>
            </td>
           </tr>
           <tr>
            <td>
             <input type='checkbox' />
            </td>
            <td>Invoice Date</td>
					<td>Claim Id</td>
					<td>Ordering Clinician Id</td>
					<td>Submmission Type</td>
					<td>Patient ID</td>
					<td>Date of Birtd</td>
			      <td>Gender</td>
			      <td>Email</td>
			      <td>Weight</td>
			      <td>Emirates ID</td>
			      <td>Enter tde Emirates No</td>
			      <td>Member ID</td>
			      <td>Receiver ID</td>
			      <td>Payer</td>
			      <td>Plan Name</td>
			      <td>Expiry Date</td>
            <td>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-eye fa-lg ml text-primary mx-1' title='View'></i>
             </a>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-edit fa-lg ml text-info mx-1' title='Edit'></i>
             </a>{' '}
             <a href='#'>
              <i class='fa fa-trash-o fa-lg text-danger' title='Delete'></i>
             </a>
            </td>
           </tr>
           <tr>
            <td>
             <input type='checkbox' />
            </td>
            <td>Invoice Date</td>
					<td>Claim Id</td>
					<td>Ordering Clinician Id</td>
					<td>Submmission Type</td>
					<td>Patient ID</td>
					<td>Date of Birtd</td>
			      <td>Gender</td>
			      <td>Email</td>
			      <td>Weight</td>
			      <td>Emirates ID</td>
			      <td>Enter tde Emirates No</td>
			      <td>Member ID</td>
			      <td>Receiver ID</td>
			      <td>Payer</td>
			      <td>Plan Name</td>
			      <td>Expiry Date</td>
            <td>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-eye fa-lg ml text-primary mx-1' title='View'></i>
             </a>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-edit fa-lg ml text-info mx-1' title='Edit'></i>
             </a>{' '}
             <a href='#'>
              <i class='fa fa-trash-o fa-lg text-danger' title='Delete'></i>
             </a>
            </td>
           </tr>
           <tr>
            <td>
             <input type='checkbox' />
            </td>
            <td>Invoice Date</td>
					<td>Claim Id</td>
					<td>Ordering Clinician Id</td>
					<td>Submmission Type</td>
					<td>Patient ID</td>
					<td>Date of Birtd</td>
			      <td>Gender</td>
			      <td>Email</td>
			      <td>Weight</td>
			      <td>Emirates ID</td>
			      <td>Enter tde Emirates No</td>
			      <td>Member ID</td>
			      <td>Receiver ID</td>
			      <td>Payer</td>
			      <td>Plan Name</td>
			      <td>Expiry Date</td>
            <td>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-eye fa-lg ml text-primary mx-1' title='View'></i>
             </a>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-edit fa-lg ml text-info mx-1' title='Edit'></i>
             </a>{' '}
             <a href='#'>
              <i class='fa fa-trash-o fa-lg text-danger' title='Delete'></i>
             </a>
            </td>
           </tr>
           <tr>
            <td>
             <input type='checkbox' />
            </td>
            <td>Invoice Date</td>
					<td>Claim Id</td>
					<td>Ordering Clinician Id</td>
					<td>Submmission Type</td>
					<td>Patient ID</td>
					<td>Date of Birtd</td>
			      <td>Gender</td>
			      <td>Email</td>
			      <td>Weight</td>
			      <td>Emirates ID</td>
			      <td>Enter tde Emirates No</td>
			      <td>Member ID</td>
			      <td>Receiver ID</td>
			      <td>Payer</td>
			      <td>Plan Name</td>
			      <td>Expiry Date</td>
            <td>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-eye fa-lg ml text-primary mx-1' title='View'></i>
             </a>
             <a
              href='#offcanvasRight'
              data-bs-toggle='offcanvas'
              aria-controls='offcanvasRight'
             >
              <i class='fa fa-edit fa-lg ml text-info mx-1' title='Edit'></i>
             </a>{' '}
             <a href='#'>
              <i class='fa fa-trash-o fa-lg text-danger' title='Delete'></i>
             </a>
            </td>
           </tr>
          </tbody>
         </table>
         <div class='col-lg-12'>
          <div class='btn-group float-end'>
           <ul class='pagination pagination-sm'>
            <li class='page-item disabled'>
             <a class='page-link' href='#'>
              &laquo;
             </a>
            </li>
            <li class='page-item active'>
             <a class='page-link' href='#'>
              1
             </a>
            </li>
            <li class='page-item'>
             <a class='page-link' href='#'>
              2
             </a>
            </li>
            <li class='page-item'>
             <a class='page-link' href='#'>
              3
             </a>
            </li>
            <li class='page-item'>
             <a class='page-link' href='#'>
              4
             </a>
            </li>
            <li class='page-item'>
             <a class='page-link' href='#'>
              5
             </a>
            </li>
            <li class='page-item'>
             <a class='page-link' href='#'>
              &raquo;
             </a>
            </li>
           </ul>
          </div>
         </div>
        </div>
       </div>
        <h1>{invoiceDate}</h1>
      </fieldset>
      
    
    
    </Form>
    </div>
  );
};

export default FormOne;
