import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isLoading } from "../utils/asyncStatusHelper";
import { login } from "../actions/sessionAction";

const NewLogin = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const { sessionReducer } = useSelector(({ sessionReducer }) => ({
    sessionReducer,
  }));

  const history = useHistory("");
  const dispatch = useDispatch();
  /*useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);
  */
  const handleSubmit = (e) => {
    e.preventDefault();

    let loginAccess = dispatch(
      login({
        username: username,
        password: password,
      })
    );

    console.log(username, password);
    loginAccess.then(
      (Result) => {
        console.log("result", Result);
        if (Result === "1") {
          history.push("/selectfacility");
        } else if (Result === "0") {
          history.push("/login");
        }
      },
      function (error) {
        history.push("/login");
      }
    );
    //window.location.reload();
    //window.location.href = "/dashboard";
  };

  const { loginAsyncStatus } = sessionReducer;
  return (
    <div className="container">
      <div className="row  justify-content-center">
        <div className="col-lg-6">
          <div className="sign  border-top border-primary border-3 ">
            <div className="row justify-content-center">
              {/* Slider  */}
             
              <div className="col-12 col-lg-5 col-md-4 col-sm-6 px-4">
                <h1 className="text-center mb-3  mt-3">
                  <img
                    src="../assets/img/big_logo.png"
                    alt=""
                    className="img-fluid w-75"
                  />
                </h1>

                <form
                  className=" margin-top-10"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <div className="form-group">
                    <input
                      type="name"
                      className="form-control form-control-sm"
                      placeholder="User Name"
                      value={username}
                      required
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-2">
                    <div className="input-group input-group-merge">
                      <input
                        className="form-control form-control-sm"
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-1">
                    <small className="text-muted">
                      <div className="form-check d-inline-block me-n3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="emailCheckboxTwo"
                          defaultChecked=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="emailCheckboxTwo"
                        ></label>
                      </div>
                      <a href="" data-bs-toggle="modal" data-bs-target="">
                        I accept Terms and Conditions
                      </a>
                      .
                    </small>
                    <br></br>
                  </div>

                  <button
                    className="btn btn-lg w-100 btn-primary mb-3"
                    loading={isLoading(loginAsyncStatus)}
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    Sign in
                  </button>
                </form>
              </div>
              <div className="col-lg-12">
                <div className="text-center bg-light ">
                  <a
                    className="font-size-6"
                    data-bs-toggle="modal"
                    data-bs-target=""
                  >
                    Powered By Traks Solutions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLogin;
