import React from "react";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm container-fluid fix-sticky">
        <div className="container-fluid">
          <a className="navbar-brand" href="/Riayathi">
            <img src="/assets/img/vvery_samll_logo.png" className="img-fluid" />
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-content"
          >
            <div className="menu-toggle">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
          </button>

          <div className="collapse navbar-collapse" id="navbar-content">
            <ul className="navbar-nav ms-md-auto">
              <li class="nav-item">
                <a class="nav-link" href="">
                  <Link to="/riayathi"> Dashboard </Link>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <Link to="/reports"> Reports </Link>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                >
                  Business Insights
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-gears" aria-hidden="true"></i>
                    <Link to="/financial"> Financial </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                    <Link to="/operational"> Operational </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-paperclip" aria-hidden="true"></i>
                    <Link to="/rejections"> Rejections </Link>
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <div className="collapse navbar-collapse" id="navbar-content">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                >
                  Application Configuration
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/authorityapi"> Authority API </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/authoritydata"> Authority Data </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/authoritymaster"> Authority Master</Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/booleantable"> Boolean</Link>
                  </a>
                  {/* <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                    Contract Management
                    <Link to="/booleantable"> Contract Management</Link>
                  </a> */}
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/facilityactivemaster">
                      {" "}
                      Facility Active Master
                    </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/facilitycategorymaster">
                      {" "}
                      Facility Category Master
                    </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/facilitycountrymaster">
                      {" "}
                      Facility Country Master
                    </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/facilitymastermaster"> Facility Master</Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                    Facility Region
                    <Link to="/facilityregionregion"> Facility Region</Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/facilitytypemastermaster">
                      {" "}
                      Facility Type Master
                    </Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/rolemaster"> Role Master</Link>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fa fa-pencil" aria-hidden="true"></i>

                    <Link to="/servicemastermaster"> Service Master</Link>
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="#">
                  Submission
                </a>
              </li>
            </ul>
          </div>
          <ul className="navbar-nav ms-md-auto">
            <li class="nav-item">
              <a class="nav-link" href="#">
                <Link to="/login"> Sign-out</Link>
              </a>
            </li>
          </ul>
        </div>

        <span className="border-bottom-3"></span>
      </nav>
    </>
  );
};

export default Header;
