import React, { useState } from "react";
import { Button, Modal, Select, Form } from "antd";

function Observation() {
  const { Option } = Select;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button
        type="primary"
        style={{
          marginTop: "20%",
          marginLeft: "45%",
        }}
        onClick={showModal}
      >
        Button
      </Button>
      <Modal
        title="OBSERVATION FOR ACTIVITY"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="card-header p-0">
          <div className="row">
            <div className="col-md-4">
              <Form.Item name="Type" label="Type" className="mb-0 gender">
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                >
                  <Option value="1">Check</Option>
                  <Option value="2">Check</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-4">
              <Form.Item name="Code" label="Code" className="mb-0 gender">
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                >
                  <Option value=""></Option>
                  <Option value=""></Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-4">
              <Form.Item name="Value" label="Value" className="mb-0 gender">
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                >
                  <Option value=""></Option>
                  <Option value=""></Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-4">
              <Form.Item
                name="ValueType"
                label="ValueType"
                className="mb-0 gender"
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                >
                  <Option value=""></Option>
                  <Option value=""></Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Observation;
