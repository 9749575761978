import {
 MenuFoldOutlined,
 MenuUnfoldOutlined,
 PieChartOutlined,
 FileMarkdownOutlined,
 SettingOutlined,
 EnterOutlined,
 FileSyncOutlined,
 ApiOutlined,
 AuditOutlined,
 ScheduleOutlined,
 CheckCircleOutlined,
 PartitionOutlined,
 SubnodeOutlined,
 HeatMapOutlined,
 ToolOutlined,
 AppstoreAddOutlined,
 RedoOutlined,
 ExportOutlined,
 TrademarkOutlined,
 GlobalOutlined,
 DownloadOutlined,
 DollarOutlined,
 FontSizeOutlined,
 DeploymentUnitOutlined,
 BankOutlined,
 ReconciliationOutlined,
 DeleteRowOutlined,
 UploadOutlined,
 FileSearchOutlined,
 ApartmentOutlined,
 StarOutlined,
} from '@ant-design/icons';
import { Button, Menu, Popover } from 'antd';
import { Link, MemoryRouter } from 'react-router-dom';
import React, { useState } from 'react';

function getItem(label, key, icon, children, type) {
 return {
  key,
  icon,
  children,
  label,
  type,
 };
}

const BusinessInsightsContent = (
 <>
  <a href='/financial'>Financial</a>
  <br />
  <a href='/operational'>Operational</a>
  <br />
  <a href='/rejections'>Rejections</a>
  <br />
 </>
);
const NEWSIDEBAR = () => {
 const [collapsed, setCollapsed] = useState(true);

 const toggleCollapsed = () => {
  setCollapsed(!collapsed);
 };

 return (
  <div className='new-sidbar'>
   <Button
    type='primary'
    onClick={toggleCollapsed}
    style={{
     marginBottom: 16,
    }}
   >
    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
   </Button>
   <Menu
    className='overflow-auto'
    defaultSelectedKeys={['1']}
    defaultOpenKeys={['sub1']}
    mode='inline'
    theme='dark'
    inlineCollapsed={collapsed}
   >
    <Menu.Item>
     <PieChartOutlined />
     <span>
      <Link to='/Riayathi'>Dashboard</Link>
     </span>
    </Menu.Item>
    <Menu.SubMenu
     title={
      <>
       <ApartmentOutlined />
       <span>Business Insights</span>
      </>
     }
    >
     <Menu.Item>
      <BankOutlined />
      <span>
       <Link to='/financial'>Finanical</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <ReconciliationOutlined />
      <span>
       <Link to='/operational'>Operational</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <DeleteRowOutlined />
      <span>
       <Link to='/rejections'>Rejections</Link>
      </span>
     </Menu.Item>
    </Menu.SubMenu>
    <Menu.Item>
     <TrademarkOutlined />
     <span>
      <Link to='/reports'>Reports</Link>
     </span>
    </Menu.Item>
    {/*
    <Menu.SubMenu
     title={
      <>
       <DeploymentUnitOutlined />
       <span>Administrator</span>
      </>
     }
    >
     <Menu.Item>
      <BankOutlined />
      <span>Supervisor Approval</span>
     </Menu.Item>
     <Menu.Item>
      <UploadOutlined />
      <span>Production Upload</span>
     </Menu.Item>
     <Menu.Item>
      <FileSearchOutlined />
      <span>Search Transaction</span>
     </Menu.Item>
     <Menu.Item>
      <DeleteRowOutlined />
      <span>Invoice</span>
     </Menu.Item>
    </Menu.SubMenu>
    
    <Menu.SubMenu
     title={
      <>
       <RedoOutlined />
       <span>Re-Submissions</span>
      </>
     }
    >
     <Menu.Item>
      <FileMarkdownOutlined />
      <span>Re-Submission Management</span>
     </Menu.Item>
     <Menu.Item>
      <FileSyncOutlined />
      <span>Re-submission Processor</span>
     </Menu.Item>
    </Menu.SubMenu>
    */}

{/*

    <Menu.Item>
     <EnterOutlined />
     <span>
      <Link to='/selectFacilitiesSubmission'>Submissions</Link>
     </span>
    </Menu.Item>
    {/*}
    <Menu.Item>
     <DownloadOutlined />
     <span>Downloader-PO-DHPO</span>
    </Menu.Item>

    <Menu.SubMenu
     title={
      <>
       <ExportOutlined />
       <span>Manual Reconciliation</span>
      </>
     }
    >
     <Menu.Item>
      <span>DHPO</span>
     </Menu.Item>
     <Menu.Item>
      <span>Riayati</span>
     </Menu.Item>
     <Menu.Item>
      <span>HAAD</span>
     </Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu
     title={
      <>
       <AppstoreAddOutlined />
       <span>Rule Engine</span>
      </>
     }
    ></Menu.SubMenu>
    <Menu.SubMenu
     title={
      <>
       <StarOutlined />
       <span>My Favourites</span>
      </>
     }
    >
     <Menu.Item>
      <ApiOutlined />
      <span>
       <Link to='/fav/reportclinicianrevenue'>Clinician Revenue</Link>
      </span>
     </Menu.Item>
    </Menu.SubMenu>
    */}
    <Menu.SubMenu
     title={
      <>
       <AppstoreAddOutlined />
       <span>Application Configuration</span>
      </>
     }
    >
     <Menu.Item>
      <ApiOutlined />
      <span>
       <Link to='/authorityapi'>Authority Api</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <AuditOutlined />
      <span>
       <Link to='/authoritydata'>Authority Data</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <ScheduleOutlined />
      <span>
       <Link to='/authoritymaster'>Authority Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <SubnodeOutlined />
      <span>
       <Link to='/booleantable'>Boolean</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <ApiOutlined />
      <span>Contract Management</span>
     </Menu.Item>
     <Menu.Item>
      <CheckCircleOutlined />
      <span>
       <Link to='/facilityactivemaster'>Facility Active Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <PartitionOutlined />
      <span>
       <Link to='/facilitycategorymaster'>Facility Category Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <GlobalOutlined />
      <span>
       <Link to='/facilitycountrymaster'>Facility Country Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <DollarOutlined />
      <span>
       <Link to='/facilitycurrency'>Facility Currency Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <DollarOutlined />
      <span>
       <Link to='/facilitymastermaster'>Facility Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <HeatMapOutlined />
      <span>
       <Link to='/facilityregionregion'>Facility Region</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <FontSizeOutlined />
      <span>
       <Link to='/facilitytypemastermaster'>Facility Type Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <ToolOutlined />
      <span>
       <Link to='/rolemaster'>Role Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <SettingOutlined />
      <span>
       <Link to='/servicemastermaster'>Service Master</Link>
      </span>
     </Menu.Item>
     <Menu.Item>
      <></>
     </Menu.Item>{' '}
     <Menu.Item>
      <></>
     </Menu.Item>
    </Menu.SubMenu>
   </Menu>
  </div>
 );
};

export default NEWSIDEBAR;
