import React, { useEffect, useState, Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import API_URL from "../tools/api";
import { Button } from "antd";
import { Select } from "antd";

const SelectFacility = () => {
  // const [facilityData, setFacilityData] = useState();
  // const [dropSelected, setDropSelected] = useState();
  const [facilityLicence, setFacilityLicence] = useState();
  const [facilityLicenceSelected, setFacilityLicenceSelected] = useState();
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);
  const { Option } = Select;
  // const Data = async () => {D:\1NewDev\UI\16-12-2022-20221216T110655Z-001\16-12-2022\build
  //   try {
  //     const Data1 = await axios.post(API_URL + "/claimMgt/userFacility", {
  //       facilityIdList: JSON.parse(localStorage.getItem("facilities")),
  //     });

  //     setFacilityData(Data1.data);
  //     console.log(Data1.data, "data1");
  //   } catch (e) {
  //     console.log(e);
  //     console.log("Error");
  //   }
  // };
  useEffect(() => {
    // Data();
    Data2();
  }, []);

  const Data2 = async () => {
    try {
      const Data3 = await axios.post(API_URL + "/claimMgt/userFacility", {
        facilityIdList: JSON.parse(localStorage.getItem("facilities")),
      });

      setFacilityLicence(Data3.data);
      console.log(Data3.data, "data3");
    } catch (e) {
      console.log(e);
      console.log("Error");
    }
  };

  return (
    <>
      <section className="sign-page">
        <div className="align-items-center bg-auth ">
          <div className="container">
            <div className="row  justify-content-center">
              <div className="col-lg-4">
                <div className="sign  border-top border-primary border-4 margin-bottom-100">
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <form className=" margin-top-10  px-4">
                        <h1 className="text-center mb-2  mt-2">
                          <img
                            src="assets/img/big_logo.png"
                            className="img-fluid logo-sign"
                          />
                        </h1>
                        <h1 className="text-center">
                          {" "}
                          Welcome{" "}
                          <span className="ml-2">
                            {" "}
                            {localStorage.getItem("user_name")}{" "}
                          </span>
                        </h1>

                        {/* <div className="form-group mb-2">
                          <label for="exampleSelect2" className="form-label">
                            Facility
                          </label>
                          <select
                            className="form-select font-size-6"
                            id="exampleSelect1"
                            placeholder="facility"
                            required
                            onChange={(e) => {
                              const SelectedDrop = e.target.value;
                              setDropSelected(SelectedDrop);
                            }}
                          >
                            <option>Select</option>
                            {facilityData &&
                              facilityData.map((el, i) => (
                                <option key={i} value={el.facilityname}>
                                  {el.facilityname}
                                </option>
                              ))}
                          </select>
                        </div>

                        <Link
                          // to="/newclaim"
                          to={`/${dropSelected}`}
                          className="btn btn-lg w-100 btn-primary mb-3 mt-3"
                        >
                          <option>Click to Conform</option>
                          <h6>{dropSelected}</h6>
                        </Link> */}
                      </form>
                      <form className=" margin-top-10  px-4">
                        <div className="form-group mb-2">
                          <label for="exampleSelect2" className="form-label">
                            <h3> FacilityLicence</h3>
                          </label>
                          <Select
                            className="w-100"
                            defaultValue="Select"
                            required
                            onChange={(value) => {
                              const SelectedDrop1 = value;
                              setFacilityLicenceSelected(SelectedDrop1);
                            }}
                          >
                            {facilityLicence &&
                              facilityLicence.map((el, i) => (
                                <Option
                                  key={i}
                                  value={el.facilityauthrotrityname}
                                >
                                  {el.facilityname}
                                </Option>
                              ))}
                          </Select>
                        </div>
                        <div className="site-button-ghost-wrapper d-grid gap-2 my-4">
                          <Button type="primary">
                            <Link to={`/${facilityLicenceSelected}`}>
                              Continue
                            </Link>
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectFacility;
