import React, { useState } from "react";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import FormOne from "./forms/primammb/formOne";
import FormTwo from "./forms/primammb/formTwo";
import FormThree from "./forms/primammb/formTwo";
import FormFour from "./forms/primammb/formFour";
import { Button, message, Steps } from "antd";
import Footer from "../layout/footer";
import { ArrowRightOutlined } from "@ant-design/icons";
const { Step } = Steps;
const steps = [
  {
    title: "Patient Demographics",
    content: <FormOne />,
  },
  {
    title: "Encounter",
    content: <FormTwo />,
  },
  {
    title: "Diagnosis",
    content: <FormThree />,
  },
  {
    title: "Activity",
    content: <FormFour />,
  },
];
const Primammb = () => {
  const [current, setCurrent] = useState(0);
  
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Sidebar />
      <div className="home-section">
        <Header />

        <div class="p-3 mb-2 bg-primary text-white"></div>
        <h2 className="card-title m-15 text-center my-3">
          PRIMACARE SPECIALITY CLINICS MMB-dhpo
        </h2>

        <section className="all-pages container-fluid new-claim">
          <div className="col-lg-12">
            <div className="chart card border-light mb-4">
              <div className="card-header d-flex justify-content-between p-3">
                <div className="float-end">
                  <div className="form-group margin-right-5">
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        aria-label="Upload"
                      />
                      <a className="btn btn-primary" href="#">
                        Submit
                      </a>
                      <button className="btn btn-danger" type="button">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className="float-end">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Amount (to the nearest dollar)"
                    />
                    <span className="input-group-text bg-primary margin-right-2">
                      <a
                        data-bs-toggle="offcanvas"
                        href="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        className="text-white text-decoration-none"
                      >
                        <i
                          className="fa fa-plus-square-o fa-lg"
                          aria-hidden="true"
                        ></i>{" "}
                        Create New Claim
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div class="table-responsive">
                  <table class="table border mb-2">
                    <thead>
                      <tr>
                        <th>
                          <input type="checkbox" />
                        </th>
                        <th>Invoice Date</th>
                        <th>Claim Id</th>
                        <th>IDPayer</th>
                        <th>Ordering Clinician Id</th>
                        <th>Name</th>
                        <th>Create Date</th>
                        <th>Weight(kilograms)</th>
                        <th>Gender</th>
                        <th>Emirate Id</th>
                        <th>Patient Id</th>
                        <th>Member Id</th>
                        <th>Receiver</th>
                        <th>Plan Name</th>
                        <th>Payer</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>05-03-2022</td>
                        <td>Encounter</td>
                        <td>003</td>
                        <td>Ahlan Pharmacy</td>
                        <td>05-03-2022</td>
                        <td>200</td>
                        <td>Male</td>
                        <td>09876</td>
                        <td>12345</td>
                        <td>0011</td>
                        <td>Recive</td>
                        <td>Plan</td>
                        <td>Payer</td>
                        <td>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-eye fa-lg ml text-primary mx-1"
                              title="View"
                            ></i>
                          </a>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-edit fa-lg ml text-info mx-1"
                              title="Edit"
                            ></i>
                          </a>{" "}
                          <a href="#">
                            <i
                              class="fa fa-trash-o fa-lg text-danger"
                              title="Delete"
                            ></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>05-03-2022</td>
                        <td>Encounter</td>
                        <td>003</td>
                        <td>Ahlan Pharmacy</td>
                        <td>05-03-2022</td>
                        <td>200</td>
                        <td>Male</td>
                        <td>09876</td>
                        <td>12345</td>
                        <td>0011</td>
                        <td>Recive</td>
                        <td>Plan</td>
                        <td>Payer</td>
                        <td>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-eye fa-lg ml text-primary mx-1"
                              title="View"
                            ></i>
                          </a>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-edit fa-lg ml text-info mx-1"
                              title="Edit"
                            ></i>
                          </a>{" "}
                          <a href="#">
                            <i
                              class="fa fa-trash-o fa-lg text-danger"
                              title="Delete"
                            ></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>

                        <td>05-03-2022</td>
                        <td>Encounter</td>
                        <td>003</td>
                        <td>Ahlan Pharmacy</td>
                        <td>05-03-2022</td>
                        <td>200</td>
                        <td>Male</td>
                        <td>09876</td>
                        <td>12345</td>
                        <td>0011</td>
                        <td>Recive</td>
                        <td>Plan</td>
                        <td>Payer</td>
                        <td>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-eye fa-lg ml text-primary mx-1"
                              title="View"
                            ></i>
                          </a>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-edit fa-lg ml text-info mx-1"
                              title="Edit"
                            ></i>
                          </a>{" "}
                          <a href="#">
                            <i
                              class="fa fa-trash-o fa-lg text-danger"
                              title="Delete"
                            ></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>05-03-2022</td>
                        <td>Encounter</td>
                        <td>003</td>
                        <td>Ahlan Pharmacy</td>
                        <td>05-03-2022</td>
                        <td>200</td>
                        <td>Male</td>
                        <td>09876</td>
                        <td>12345</td>
                        <td>0011</td>
                        <td>Recive</td>
                        <td>Plan</td>
                        <td>Payer</td>
                        <td>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-eye fa-lg ml text-primary mx-1"
                              title="View"
                            ></i>
                          </a>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-edit fa-lg ml text-info mx-1"
                              title="Edit"
                            ></i>
                          </a>{" "}
                          <a href="#">
                            <i
                              class="fa fa-trash-o fa-lg text-danger"
                              title="Delete"
                            ></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>05-03-2022</td>
                        <td>Encounter</td>
                        <td>003</td>
                        <td>Ahlan Pharmacy</td>
                        <td>05-03-2022</td>
                        <td>200</td>
                        <td>Male</td>
                        <td>09876</td>
                        <td>12345</td>
                        <td>0011</td>
                        <td>Recive</td>
                        <td>Plan</td>
                        <td>Payer</td>
                        <td>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-eye fa-lg ml text-primary mx-1"
                              title="View"
                            ></i>
                          </a>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-edit fa-lg ml text-info mx-1"
                              title="Edit"
                            ></i>
                          </a>{" "}
                          <a href="#">
                            <i
                              class="fa fa-trash-o fa-lg text-danger"
                              title="Delete"
                            ></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>05-03-2022</td>
                        <td>Encounter</td>
                        <td>003</td>
                        <td>Ahlan Pharmacy</td>
                        <td>05-03-2022</td>
                        <td>200</td>
                        <td>Male</td>
                        <td>09876</td>
                        <td>12345</td>
                        <td>0011</td>
                        <td>Recive</td>
                        <td>Plan</td>
                        <td>Payer</td>
                        <td>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-eye fa-lg ml text-primary mx-1"
                              title="View"
                            ></i>
                          </a>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-edit fa-lg ml text-info mx-1"
                              title="Edit"
                            ></i>
                          </a>{" "}
                          <a href="#">
                            <i
                              class="fa fa-trash-o fa-lg text-danger"
                              title="Delete"
                            ></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>05-03-2022</td>
                        <td>Encounter</td>
                        <td>003</td>
                        <td>Ahlan Pharmacy</td>
                        <td>05-03-2022</td>
                        <td>200</td>
                        <td>Male</td>
                        <td>09876</td>
                        <td>12345</td>
                        <td>0011</td>
                        <td>Recive</td>
                        <td>Plan</td>
                        <td>Payer</td>
                        <td>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-eye fa-lg ml text-primary mx-1"
                              title="View"
                            ></i>
                          </a>
                          <a
                            href="#offcanvasRight"
                            data-bs-toggle="offcanvas"
                            aria-controls="offcanvasRight"
                          >
                            <i
                              class="fa fa-edit fa-lg ml text-info mx-1"
                              title="Edit"
                            ></i>
                          </a>{" "}
                          <a href="#">
                            <i
                              class="fa fa-trash-o fa-lg text-danger"
                              title="Delete"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="col-lg-12">
                    <div class="btn-group float-end">
                      <ul class="pagination pagination-sm">
                        <li class="page-item disabled">
                          <a class="page-link" href="#">
                            &laquo;
                          </a>
                        </li>
                        <li class="page-item active">
                          <a class="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            4
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            5
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            &raquo;
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Launch demo modal
          </button>

          <div
            className="offcanvas offcanvas-end form-wizard"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">
                CREATE NEW CLAIM
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <>
                <Steps current={current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                  {current < steps.length - 1 && (
                    <Button
                      type="primary"
                      style={{
                        float: "right",
                        marginRight: "20px",
                      }}
                      onClick={() => next()}
                    >
                      Next
                      <ArrowRightOutlined />
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      style={{
                        float: "right",
                        marginRight: "20px",
                      }}
                      type="primary"
                      onClick={() => message.success("Processing complete!")}
                    >
                      Done
                    </Button>
                  )}
                  {current > 0 && (
                    <Button
                      style={{
                        margin: "0 8px",
                        float: "right",
                      }}
                      onClick={() => prev()}
                    >
                      Previous
                    </Button>
                  )}
                </div>
              </>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Primammb;
