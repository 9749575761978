import React, { useState, useEffect } from "react";
import axios from "axios";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Pagination, Checkbox } from "antd";
import * as XLSX from "xlsx";
import API_URL from "../../tools/api";
import { Card, Space } from "antd";
import FlitersAll from "../../tools/filters";
import Header from "../../layout/header";
import NEWSIDEBAR from "../../layout/newSidebar";

const FacilityCurrencyDownloader = () => {
  const [currency, setCurrency] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState([]);
  const [boolean, setBoolean] = useState([]);

  const data1 = async () => {
    try {
      const data = await axios.get(API_URL + "/p2p/riayati_search");
      console.log("data", data);
      setCurrency(data);
    } catch (e) {
      console.log(e);
      console.log("data1");
    }
  };

  useEffect(() => {
    data1();
  }, []);

  const data = async () => {
    try {
      const data = await axios.post(API_URL + "/riayati_search", {
        username: "7226",
        password: "78b96fb0-2296-4d94-8a44-c2f9bfd92703",
        license: "",
        direction: "1",
        downloaded: "2",
        fromDate: "2022-04-01",
        toDate: "2022-07-10",
        facilityID: "7226",
        download: "Y",
        directory: "/home/tpp2p/downloads",
        isBulk: "N",
      });
      console.log("data", data.data.SearchTransaction);
      setBoolean(data.data.SearchTransaction);
      setTotal(data.data.SearchTransaction.length);
    } catch (e) {
      console.log(e);
      console.log("data1");
    }
  };

  useEffect(() => {
    data();
  }, []);

  const setItem = (selectedItem) => {
    console.log(selectedItem);
    setSelectedItem(selectedItem);
  };

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(boolean);
    console.log("Datadownload", boolean);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet");

    XLSX.writeFile(wb, "MyExcel.xlsx");
  };

  const handleOnExportSelect = () => {
    var wbs = XLSX.utils.book_new(),
      wss = XLSX.utils.json_to_sheet([selectedItem]);
    console.log("Selected Data", selectedItem);
    XLSX.utils.book_append_sheet(wbs, wss, "Sheet");

    XLSX.writeFile(wbs, "MyExcelSelected.xlsx");
  };

  const indexOfLastPage = page + dataPerPage;
  const indexOfFirstPage = indexOfLastPage - dataPerPage;
  const currentPost = boolean.slice(indexOfFirstPage, indexOfLastPage);
  const onShowSizeChange = (current, pageSize) => {
    setDataPerPage(pageSize);
  };
  return (
    <>
    <NEWSIDEBAR/>
    <div className="home-section">
        <Header />

        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
          <FlitersAll/>
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: "flex",
        }}
      >
        <Card title="Transaction Master" size="medium">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>ReceiverID</th>
                  <th>CreationDate</th>
                  <th>Downloaded</th>
                  <th>DownloadedDate</th>
                  <th>DateGeneratedString</th>
                  <th>SenderID</th>
                  <th>TransactionDate</th>
                  <th>RecordCount</th>
                </tr>
              </thead>
              <tbody>
                {currentPost.map((item) => {
                  return (
                    <tr>
                      <td>{item.ID}</td>
                      <td>{item.ReceiverID}</td>
                      <td>{item.CreationDate}</td>
                      <td>{item.Downloaded}</td>
                      <td>{item.DownloadedDate}</td>
                      <td>{item.DownloadedDateGeneratedString}</td>
                      <td>{item.SenderID}</td>
                      <td>{item.TransactionDate}</td>
                      <td>{item.RecordCount}</td>

                      <td>
                        <Checkbox.Group
                          onChange={() => setItem(item)}
                          options={item}
                        ></Checkbox.Group>
                        <input type="checkbox" onChange={() => setItem(item)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <li className="list-inline-item"></li>
        </Card>
        <Card>
          <Button
            style={{
              marginTop: "10px",
            }}
            type="primary"
            className="btn btn-primary margin-top-min-14 margin-right-5"
            icon={<DownloadOutlined />}
            onClick={handleOnExport}
          >
            Download
          </Button>
          <Button
            style={{
              marginLeft: "1135px",
              marginTop: "10px",
            }}
            className="btn btn-primary margin-top-min-14"
            icon={<DownloadOutlined />}
            type="primary"
            shape="round"
            onClick={handleOnExportSelect}
          >
            Download Selected
          </Button>

          <Pagination
            style={{
              marginLeft: "630px",
            }}
            onChange={(page) => setPage(page)}
            pageSize={dataPerPage}
            total={total}
            current={page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={onShowSizeChange}
          />
        </Card>
      </Space>
          </div>
        </section>
      </div>
    
    </>
  );
};

export default FacilityCurrencyDownloader;
