import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import axios from "axios";
import API_URL from "../tools/api";
import Footer from "../layout/footer";
import Header from "../layout/header";
import { message, Button, Tooltip, Popconfirm } from "antd";
import { EditTwoTone, DeleteOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import NEWSIDEBAR from "../layout/newSidebar";

const RoleMaster = () => {
  const [name, setName] = useState("");
  const [roleMaster, setRoleMaster] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [ ])
  const text = "Are you sure to delete this item?";

  console.log(name);

  const data = async () => {
    try {
      const data = await axios.post(API_URL + "/seedData/CurdRole", {
        curdoperation: "R",
      });
      console.log("data", data.data.listOfRole);
      setRoleMaster(data.data.listOfRole);
    } catch (e) {
      console.log(e);
      console.log("data1");
    }
  };

  useEffect(() => {
    data();
  }, []);

  const sendDataToApi = () => {
    axios
      .post(API_URL + "/seedData/CurdRole", {
        curdoperation: "C",
        id: "0",
        name,
        createdby: localStorage.getItem("user_name"),
        createddatetime: "",
        updatedby: "",
        updateddatetime: "",
      })
      .then((res) => {
        data();
        console.log("resppppp", res.data.error);
        if (res.data.error) {
          message.error(res.data.error);
        } else {
          message.success("Item Added Successfully");
        }
      });
    console.log(name, "api");
  };

  const setID = (id, name) => {
    console.log(id, name, "api ID");
    localStorage.setItem("ID", id);
    localStorage.setItem("BooleanName", name);
  };

  const onDelete = (id) => {
    axios
      .post(API_URL + "/seedData/CurdRole", {
        curdoperation: "D",
        id,
      })
      .then(() => {
        data();
        message.warn("Item Deleted Succesfully");
      });
  };

  function updateUser(id) {
    let item = { name };
    console.warn("item", item);
    axios
      .post(API_URL + "/seedData/CurdRole", {
        curdoperation: "U",
        name,
        id,
        updatedby: localStorage.getItem("user_name"),
      })
      .then(() => {
        data();
        message.info("Item Updated Succesfully");
      });
  }

  const indexOfLastPage = page + dataPerPage;
  const indexOfFirstPage = indexOfLastPage - dataPerPage;
  const currentPost = roleMaster.slice(indexOfFirstPage, indexOfLastPage);
  const onShowSizeChange = (current, pageSize) => {
    setDataPerPage(pageSize);
  };

  return (
    <>
  
    <div className="home-section">
        <Header />

        <section className="all-pages container-fluid AutherityDataclassName">
          <div className="col-lg-12">
          <section className="dashboard   roleMaster">
            <Col lg={12}>
              <div className="card   border-light mb-4">
                <div className="card-header d-flex justify-content-between p-2">
                  <h4 className="card-title mt-1">Role Master</h4>
                  <div class="float-end">
                    <div class="input-group input-group-sm">
                      <input
                        type="text"
                        placeholder="Search Names"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        class="form-control"
                        aria-label="Amount (to the nearest dollar)"
                      />
                      <span class="input-group-text bg-primary margin-right-2">
                        <a href="#" class="text-white text-decoration-none">
                          <i class="fa fa-search"></i>
                        </a>
                      </span>

                      <button
                        class="btn btn-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#roleMaster"
                        aria-controls="offcanvasRight"
                      >
                        {" "}
                        <i
                          class="fa fa-plus-square-o fa-lg"
                          aria-hidden="true"
                        ></i>{" "}
                        Add New{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Unique Identifier</th>
                          <th>Role Name</th>
                          <th>Created By</th>
                          <th>Created DateTime</th>
                          <th>Updated By</th>
                          <th>Updated Date Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPost
                          .filter((item) => {
                            if (search == "") {
                              return item;
                            } else if (
                              item.name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((item) => {
                            return (
                              <tr>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.createdby}</td>
                                <td>{item.createddatetime}</td>
                                <td>{item.updatedby}</td>
                                <td>{item.updateddatetime}</td>
                                <td>
                                  <a
                                    href="#"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#roleMasterUpdate"
                                    aria-controls="offcanvasRight"
                                  >
                                    <Tooltip
                                      placement="top"
                                      title="Edit"
                                      color="blue"
                                    >
                                      <Button
                                        type="text"
                                        onClick={() =>
                                          setID(item.id, item.name)
                                        }
                                        icon={<EditTwoTone />}
                                      ></Button>
                                    </Tooltip>
                                  </a>{" "}
                                  <a href="#">
                                    <Popconfirm
                                      placement="top"
                                      title={text}
                                      onConfirm={() => onDelete(item.id)}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Button
                                        type="text"
                                        danger
                                        ghost
                                        icon={
                                          <DeleteOutlined twoToneColor="#FF0000" />
                                        }
                                      ></Button>
                                    </Popconfirm>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12"></div>
                </div>
              </div>
              <Pagination
                onChange={(page) => setPage(page)}
                pageSize={dataPerPage}
                total={total}
                current={page}
                showSizeChanger
                showQuickJumper
                onShowSizeChange={onShowSizeChange}
              />
            </Col>

            <div
              className="offcanvas offcanvas-end"
              tabindex="-1"
              id="roleMaster"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">
                  Role Master
                </h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <form className="user-profile-login">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Unique Identifier
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">Role Name</label>
                        <input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">Created By</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Created DateTime
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">Updated By</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Updated Date Time
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div> */}

                    <div class="col-md-12 mt-2">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm margin-right-5"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={sendDataToApi}
                      >
                        Save
                      </button>
                      <button type="button" class="btn btn-danger btn-sm">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="offcanvas offcanvas-end"
              tabindex="-1"
              id="roleMasterUpdate"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">
                  Role Master Update
                </h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <form className="user-profile-login">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="mb-2">
                        <label className="col-form-label">
                          Unique Identifier
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div> */}
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">Role Name</label>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          className="form-control form-control-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">Created By</label>
                        <input
                          type="text"
                          placeholder={localStorage.getItem("user_name")}
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Created DateTime
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">Updated By</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-2">
                        <label className="col-form-label">
                          Updated Date Time
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          disabled
                        />
                      </div>
                    </div> */}

                    <div class="col-md-12 mt-2">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm margin-right-5"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => updateUser(localStorage.getItem("ID"))}
                      >
                        Update
                      </button>
                      <button type="button" class="btn btn-danger btn-sm">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          </div>
        </section>
    </div>

        
          
       
      
      <Footer />
    </>
  );
};

export default RoleMaster;
