/**
 * Respond with highchart data required input properties.
 *
 * @param {*} denialByClinicianData
 */
export function getFinancialClinicianPerformance(financialClinicianPerformance) {
  let series = [],
    categories = [];
  console.log("Tamil", financialClinicianPerformance);
  if (
    financialClinicianPerformance &&
    financialClinicianPerformance["Clinician1_Name_Stack"] &&
    financialClinicianPerformance["Clinician_Year_Details_Stack"]
  ) {
    let rejectionCodes = financialClinicianPerformance["Clinician_Year_Stack"],
      rejectionDetails =
        financialClinicianPerformance["Clinician_Year_Details_Stack"];

    categories = rejectionDetails.map((detail) => detail["Clinician_Name"]);

    for (
      let codeItr = 0, len = rejectionCodes.length;
      codeItr < len;
      ++codeItr
    ) {
      let rejectionData = [];
      for (
        let detailItr = 0, detailLen = rejectionDetails.length;
        detailItr < detailLen;
        ++detailItr
      ) {
        if (rejectionCodes[codeItr] in rejectionDetails[detailItr]) {
          rejectionData.push(
            rejectionDetails[detailItr][rejectionCodes[codeItr]]
          );
        } else {
          rejectionData.push(0);
        }
      }
      series.push({
        name: rejectionCodes[codeItr],
        data: rejectionData,
        type: "bar",
        stack: "total",
      });
    }
    console.log("Series", series);
  }

  return {
    series,
    categories,
  };
}
