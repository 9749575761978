import React from "react";
import Footer from "../layout/footer";
import Header1 from "../layout/header1";
import NEWSIDEBAR from "../layout/newSidebar";

export const MainDashboard = () => {
  return (
    <>
      <NEWSIDEBAR />
      <div className="home-section">
        <Header1 />
        <div className="container-fluid">
          <div className="kpi mt-3">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body p-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="text-uppercase mb-2">
                          Eligibility Check
                        </h5>
                      </div>
                      <div className="col-auto">
                        <span className="h4 fa fa-check-square-o text-muted mb-0"></span>
                      </div>
                      <div className="col-12">
                        <div className="float-end">
                          <span className="h6 me-2 mb-0">1000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body p-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="text-uppercase mb-2">Authorization</h5>
                      </div>
                      <div className="col-auto">
                        <span className="h4 fa fa-user-plus text-muted mb-0"></span>
                      </div>
                      <div className="col-12">
                        <div className="float-end">
                          <span className="h6 me-2 mb-0">1000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body p-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="text-uppercase mb-2">
                          Claim Submission
                        </h5>
                      </div>
                      <div className="col-auto">
                        <span className="h4 fa fa-file-text-o text-muted mb-0"></span>
                      </div>
                      <div className="col-12">
                        <div className="float-end">
                          <span className="h6 me-2 mb-0">2000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body p-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="text-uppercase mb-2">
                          Claim ReSubmission
                        </h5>
                      </div>
                      <div className="col-auto">
                        <span className="h4 fa fa-file-text text-muted mb-0"></span>
                      </div>
                      <div className="col-12">
                        <div className="float-end">
                          <span className="h6 me-2 mb-0">2000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body p-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="text-uppercase mb-2">eRx Submission</h5>
                      </div>
                      <div className="col-auto">
                        <span className="h4 fa fa-barcode text-muted mb-0"></span>
                      </div>
                      <div className="col-12">
                        <div className="float-end">
                          <span className="h6 me-2 mb-0">2000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body p-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="text-uppercase mb-2">Pending Items</h5>
                      </div>
                      <div className="col-auto">
                        <span className="h4 fa fa-hourglass-end text-muted mb-0"></span>
                      </div>
                      <div className="col-12">
                        <div className="float-end">
                          <span className="h6 me-2 mb-0">2000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainDashboard;
