import axios from "axios";
import { message } from "antd";
import { push } from "connected-react-router";
import LocalStorageService from "../tools/LocalStorageService";
import "antd/dist/antd.min.css";
import router from "../App";
import API_URL from "../tools/api";


const localStorageService = LocalStorageService.getService();

 export const SESSION_USER_SESSION_REQUEST = "SESSION/USER_SESSION_REQUEST";
 export const SESSION_USER_SESSION_SUCCESS = "SESSION/USER_SESSION_SUCCESS";
 export const SESSION_USER_SESSION_ERROR = "SESSION/USER_SESSION_ERROR";

export const SESSION_LOGIN_USER_REQUEST = "SESSION/LOGIN_USER_REQUEST";
export const SESSION_LOGIN_USER_SUCCESS = "SESSION/LOGIN_USER_SUCCESS";
export const SESSION_LOGIN_USER_ERROR = "SESSION/LOGIN_USER_ERROR";

export const SESSION_LOGOUT_USER_REQUEST = "SESSION/LOGOUT_USER_REQUEST";
export const SESSION_LOGOUT_USER_SUCCESS = "SESSION/LOGOUT_USER_SUCCESS";
export const SESSION_LOGOUT_USER_ERROR = "SESSION/LOGIN_USER_ERROR";

export const SESSION_SIGNUP_USER_REQUEST = "SESSION/SIGNUP_USER_REQUEST";
export const SESSION_SIGNUP_USER_SUCCESS = "SESSION/SIGNUP_USER_SUCCESS";
export const SESSION_SIGNUP_USER_ERROR = "SESSION/SIGNUP_USER_ERROR";

export const login = (payload) => async (dispatch) => {
  //const history = useHistory;
  let loginFlag = "";
  dispatch({
    type: SESSION_LOGIN_USER_REQUEST,
  });
  try {
    const res = await axios.post(API_URL + "/authentication/signin", payload);
    console.log("login", res.data);
    localStorageService.setToken(res.data);

    localStorage.setItem("user_id", res.data.id);
    localStorage.setItem("user_name", res.data.username);
    
    localStorage.setItem("facilities", JSON.stringify(res.data.facilities));

    dispatch({
      type: SESSION_LOGIN_USER_SUCCESS,
      payload: res,
    });
    console.log("payload", payload);
    //history.push("/dashboard");
    message.success("Login Success");
    loginFlag = "1";
    // dispatch(push("/dashboard"));
    //router.push("/dashboard");
  } catch (e) {
    console.log("eee", e);
    if (e.response.status === 401) {
      dispatch({
        type: SESSION_LOGIN_USER_ERROR,
      });
    }
    message.error("Login Failed");
    loginFlag = "0";
  }
  console.log("payloadoneeeeeeeeeeeeee", payload);
  return loginFlag;
};

export const getSession = () => async (dispatch) => {
  dispatch({
    type: SESSION_USER_SESSION_REQUEST,
  });
  try {
    const {
      data: { userSession },
    } = await axios.post(API_URL + "/authentication/refreshtoken");
    dispatch({
      type: SESSION_USER_SESSION_SUCCESS,
      payload: userSession,
    });
  } catch (e) {
    if (e.response.status === 401) {
      dispatch({
        type: SESSION_USER_SESSION_ERROR,
      });
    }
  }
};

export const logout = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: SESSION_LOGIN_USER_REQUEST,
    });
    await axios
      .post(API_URL + "/authentication/logout", { userId: user_id })
      .then(
        (response) => {
          console.log("response", response);
          // dispatch(push("/login"));
          localStorage.clear();
          window.location.href = "/login";
        },
        (error) => {
          console.log(error);
          message.error("Logout Failed");
        }
      );
    dispatch({
      type: SESSION_LOGOUT_USER_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
};

export const signup = (payload) => async (dispatch) => {
  dispatch({
    type: SESSION_SIGNUP_USER_REQUEST,
  });
  try {
    const data = await axios.post(API_URL + "/authentication/signup", payload);
    localStorageService.setToken(data.data);
    dispatch({
      type: SESSION_SIGNUP_USER_SUCCESS,
      payload: data.data,
    });
    dispatch(push("/new"));
    console.log("signup", data.data);
    message.success("User Registered Successfully");
  } catch (e) {
    if (e.response.status === 401) {
      dispatch({
        type: SESSION_SIGNUP_USER_ERROR,
      });
    }

    message.error("Signup Failed");
  }
};
