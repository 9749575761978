import axios from "axios";
import LocalStorageService from "./LocalStorageService";
import router from "../App";

//Production
//const API_URL = "http://103.186.221.219:2024/ai/v1";
//export default API_URL;

//Production
const API_URL = "https://bk.traks-uae.com/ai/v1";
 export default API_URL;

 //Home 
//const API_URL = "http://192.168.1.142:2024/ai/v1";
//export default API_URL;

//Oriayan
 //const API_URL = "http://172.16.5.68:6608/ai/v1";
 // export default API_URL;

//const API_URL = "https://bac.dbx-apps.com/1oi/v1";
//export default API_URL;

const localStorageService = LocalStorageService.getService();

axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    console.log("tokennnn", token);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === API_URL + "/authentication/refreshtoken"
    ) {
      router.push("/login");
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorageService.getRefreshToken();
      return axios
        .post(API_URL + "/authentication/refreshtoken", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 201) {
            localStorageService.setToken(res.data);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorageService.getAccessToken();
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);
